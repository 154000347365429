import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function MsgProva() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  let teste = 1;
  if (teste === 1) {
    useState.condicional = false;
  } else {
    useState.condicional = true;
  }

  return (

    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Aviso!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Após ínicio não feche a tela até responder todas questões!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Iniciar
        </Button>
      </DialogActions>
    </Dialog>



  );
}
