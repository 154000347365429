import React, { useState } from "react";

import deleteMapping from "../../../repositories/deleteMapping";
import "../../../components/css/styleguide.scss";
import { CardMenu } from "../../../components/MenuCardOptions";
import { i18n } from "../../../translate/i18n";
import FormAula from "./Dialog/formAula";
import { toast } from "react-toastify";

let videoObj = {
  id: 0,
  titulo: "",
  descricao: "",
  url: "",
  tipo: "",
  conteudo: "",
};

function ListaAula(props) {
  const [dialog, setDialog] = useState(false);
  const [video, setVideo] = useState(videoObj);
  const [edita, setEdita] = useState(false);
  const [opcoes, setOpcoes] = useState(null);

  const handleClose = () => {
    setVideo(videoObj);
    props.listar();
    setDialog(false);
  };
  const handleEdita = () => {
    setEdita(true);
    setDialog(true);
  };
  const handleOpen = () => {
    setEdita(false);
    setDialog(true);
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th>{i18n.t("tabela.codigo")}</th>
          <th>{i18n.t("formulario.titulo")}</th>
          <th>Tipo</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    return props.aulas.length > 0 ? (
      <tbody>
        {props.aulas.map((item, index) => (
          <tr key={index}>
            <td data-title="ID">
              {[item.id].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>
            <td data-title="Título">
              {[item.titulo].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>
            <td data-title="Tipo">
              {[item.tipo].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>
            <td data-title="Ações" className="text-center">
              <div>
                <a style={{ cursor: "pointer" }}>
                  <i
                    style={{ fontSize: "1.8em" }}
                    className="mdi mdi-chevron-down"
                    onClick={() => setOpcoes(index)}
                  />
                </a>
                <CardMenu open={opcoes === index} width="150px">
                  <a
                    onClick={() => {
                      setVideo(item);
                      handleEdita();
                    }}
                  >
                    <i className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"></i>
                    Editar
                  </a>
                  <a
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                  >
                    <i className="mdi mdi-trash-can-outline icon-size delete-table"></i>
                    Excluir
                  </a>
                </CardMenu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td
            data-title="Empty Table"
            colSpan="5"
            className="text-left"
            style={{ backgroundColor: "#eff1f9" }}
          >
            {i18n.t("tabela.zeroRegistros")}
          </td>
        </tr>
      </tbody>
    );
  };

  const handleDelete = (id) => {
    deleteMapping
      .deletar(`/videos/${id}`)
      .then(() => {
        props.listar();
        toast.success(i18n.t("toastSuccess.aulaDel"));
      })
      .catch((e) => toast.error(e.message));
  };

  return (
    <div
      style={{ paddingLeft: 10, paddingRight: 10 }}
      onClick={() => {
        if (opcoes !== null) {
          setOpcoes(null);
        }
      }}
    >
      <FormAula
        video={video}
        onClose={handleClose}
        open={dialog}
        moduloId={props.moduloId}
        edita={edita}
        cursotitulo={props.cursotitulo}
        modulotitulo={props.modulotitulo}
      />

      <div className="tr-row">
        <div className="tr-col-12 tr-form-group tr-mt-3 tr-display-flex tr-flex-end">
          <button onClick={handleOpen}>
            <i className="mdi mdi-file icon-mr-12" />
            {i18n.t("button.novo")}
          </button>
        </div>
      </div>
      <div className="tr-row">
        <div className="tr-col-12">
          <table>
            <TableHead />
            <TableBody />
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListaAula;
