import getMapping from "../repositories/getMapping"

 function DownlaodArquivos(api) {
    getMapping.get(`${api}`)
        .then((data) => {
           const linkSource = data.arquivo;
           const downloadLink = document.createElement("a");
           downloadLink.href = linkSource;
           downloadLink.download = data.nome;
           downloadLink.click();
        });
}

export default { DownlaodArquivos }