import React, { useState, useEffect } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./index.css";
import Toolbar from "@material-ui/core/Toolbar";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TelaNota from "../Prova/TelaNota";
import { i18n } from "../../translate/i18n";
import postMapping from "../../repositories/postMapping";
import getMapping from "../../repositories/getMapping";
import DoneIcon from "@material-ui/icons/Done";
import Loading from "../Loading";
import { useParams } from "react-router-dom";

export default function ListaQuestoes(props) {
  const [provas, setProvas] = useState([]);
  const { moduloid } = useParams();
  const ordenar = props.collection;
  const [counter, setCounter] = useState(0);
  const [provaId, setProvaId] = useState("");
  const [usuarioProva, setUsuarioProva] = useState(null);
  const [resultado, setResultado] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function listar() {
      await getMapping
        .get(`/provapergunta/modulos/${moduloid}`)
        .then((data) => {
          if (data) {
            setProvas(data);
            setProvaId(data[0].provaId);
          } else {
            toast.warning(i18n.t("toastWarning.provaIndisp"));
            props.onClose();
          }
        })
        .catch((error) => {
          toast.warning(i18n.t("toastWarning.provaIndisp"));
          props.onClose();
        });
    }
    listar();
  }, []);

  async function handleClick() {
    const total = provas.length;
    let x = total + total;
    let i;

    if (localStorage.length >= x) {
      setLoading(true);
      let objSalvar = [];
      for (i = 1; i <= total; i++) {
        const perguntaId = localStorage.getItem(i + "pergunta");
        const respondido = localStorage.getItem(i + "resposta");

        if (perguntaId !== null) {
          let obj = {
            provaUsuarioId: usuarioProva,
            provaPerguntaId: perguntaId,
            respondido: respondido,
          };
          objSalvar.push(obj);
        }
      }
      await postMapping
        .post(objSalvar, "/respostausuario")
        .then((data) => {
          setResultado(data);
          setOpen(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          return;
        });
    } else {
      toast.error(i18n.t("toastError.semResp"));
    }
  }

  async function cadProvaUser() {
    await postMapping
      .post(
        {
          provaId: provaId,
        },
        "/provausuario"
      )
      .then((data) => {
        setUsuarioProva(data.id);
        return;
      })
      .catch();
  }

  return provas.length > 0 ? (
    <div className="list">
      <div className="listaPerguntas">
        {loading && <Loading title="container-loading-login" />}
        {provas.map((item, index) => (
          <Toolbar key={index}>
            <FormControl>
              <RadioGroup>
                <div
                  key={item.id}
                  className={counter === index}
                  onClick={() => {
                    setCounter(index);

                    if (usuarioProva === null) {
                      cadProvaUser();
                    }
                  }}
                >
                  <div className="perguntas">
                    <List>
                      {[item.pergunta].map((texto, e) => (
                        <ListItem key={e}>
                          <ListItemText
                            key={index}
                            primary={index + 1 + ". " + texto}
                            id="resposta"
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  <div className="alternativas">
                    <List>
                      <div className={ordenar[0]}>
                        {[item.alt1].map((texto, e) => (
                          <ListItem key={e}>
                            <FormControlLabel
                              key={index}
                              onClick={() => {
                                localStorage.setItem(
                                  index + 1 + "pergunta",
                                  item.id
                                );
                                localStorage.setItem(index + 1 + "resposta", 1);
                              }}
                              value={item.alt1}
                              control={<Radio />}
                            />
                            <ListItemText secondary={texto} id="resposta" />
                          </ListItem>
                        ))}
                      </div>
                      <div className={ordenar[1]}>
                        {[item.alt2].map((texto, e) => (
                          <ListItem key={e}>
                            <FormControlLabel
                              key={index}
                              onClick={() => {
                                localStorage.setItem(
                                  index + 1 + "pergunta",
                                  item.id
                                );
                                localStorage.setItem(index + 1 + "resposta", 2);
                              }}
                              value={item.alt2}
                              control={<Radio />}
                            />
                            <ListItemText secondary={texto} id="resposta" />
                          </ListItem>
                        ))}
                      </div>
                      <div className={ordenar[2]}>
                        {[item.alt3].map((texto, e) => (
                          <ListItem key={e}>
                            <FormControlLabel
                              key={index}
                              onClick={() => {
                                localStorage.setItem(
                                  index + 1 + "pergunta",
                                  item.id
                                );
                                localStorage.setItem(index + 1 + "resposta", 3);
                              }}
                              value={item.alt3}
                              control={<Radio />}
                            />
                            <ListItemText secondary={texto} id="resposta" />
                          </ListItem>
                        ))}
                      </div>
                      <div className={ordenar[3]}>
                        {[item.alt4].map((texto, e) => (
                          <ListItem key={e}>
                            <FormControlLabel
                              key={index}
                              onClick={() => {
                                localStorage.setItem(
                                  index + 1 + "pergunta",
                                  item.id
                                );
                                localStorage.setItem(index + 1 + "resposta", 4);
                              }}
                              value={item.alt4}
                              control={<Radio />}
                            />
                            <ListItemText secondary={texto} id="resposta" />
                          </ListItem>
                        ))}
                      </div>
                    </List>
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </Toolbar>
        ))}
      </div>
      <div className="enviar-resposta">
        <Button color="primary" onClick={handleClick}>
          <DoneIcon /> Enviar Respostas
        </Button>
        <TelaNota
          open={open}
          onClose={() => props.onClose()}
          resultado={resultado}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
