import styled from "styled-components";

export const ContainerCertificado = styled.div`
  width: 80%;
  flex: 1;
  background-color: #ccd3d9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    width: 65%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 15%;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    height: 10%;
  }
`;

export const Certificado = styled.div`
  width: 877px;
  height: 620px;
  background-color: #fff;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 100%;

    .certificado-topo {
      height: 15%;
    }

    .certificado-content {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .containerAssignatures {
        display: flex;
        width: "100%";
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
    .certificado-footer {
      height: 15%;
    }
  }

  .certificado-verso {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 40px;
    .url {
      font-size: 10px;
      text-align: right;
    }
  }

  @media only screen and (max-width: 1200px) {
    transform: scale(0.7);
    min-width: 877px;
  }

  @media only screen and (max-width: 856px) {
    transform: scale(0.5);
    min-width: 877px;
  }

  @media only screen and (max-width: 600px) {
    transform: scale(0.5);
    min-width: 877px;
  }

  @media only screen and (max-width: 400px) {
    transform: scale(0.3);
    min-width: 877px;
  }
`;

export const BoxOptions = styled.div`
  min-width: 341px;
  width: 20%;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;

  .session {
    padding: 0 20px;
    display: block;

    .autocomplete-items {
      position: absolute;
      border: 1px solid #fff;
      margin: -20px 20px 0px 20px;
      border-radius: 4px;
      max-height: 210px;
      overflow-y: auto;
      z-index: 99;
      top: 1;
      left: 0;
      right: 0;
    }

    .autocomplete-items div {
      padding: 8px;
      cursor: pointer;
      color: #696969;
      background-color: #ccd3d9;
      border-bottom: 1px solid #fff;
      &:last-child {
        border-bottom: 0;
      }
    }

    .autocomplete-items div:hover {
      background-color: #e9e9e9;
    }

    .autocomplete-active {
      background-color: DodgerBlue !important;
      color: #004a6e !important;
    }
    .font-select {
      display: table;

      label {
        display: table-header-group;
      }

      .MuiSlider-root {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .tipImgSize {
      font-size: 12px;
      text-align: center;
      padding: 10px;
    }

    .box-img {
      width: 100%;
      height: 60px;
      text-align: center;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      display: flex;
      position: relative;

      i {
        z-index: 1;
        cursor: pointer;
        position: absolute;
        font-size: 22px;
        top: -14px;
        right: -11px;
      }

      img {
        height: 60px;
        max-width: 100%;
        margin: auto;
      }
    }

    .assinaturaIcon {
      justify-content: center;
      i {
        color: #004a6e;
        cursor: pointer;
      }
    }

    .box-label {
      position: relative;

      i {
        position: absolute;
        font-size: 21px;
        right: -8px;
        top: -12px;
      }
    }

    .input-img {
      position: absolute;
      top: 0;
      opacity: 0;
      height: 75px;
      width: 60%;
      cursor: pointer;
    }
  }

  .box-btns-end {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  textarea {
    resize: none;
    height: 70px;
  }

  a {
    text-decoration: none;
  }

  @media only screen and (max-width: 992px) {
    width: 30%;
    min-width: 0;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

export const Button = styled.button`
  background-color: #1566a0;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  font-size: 12px;
  border-radius: 4px;

  &:hover {
    background-color: #008288;
  }
`;
