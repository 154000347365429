import React from "react";
import { version } from "../../../package.json";
import { i18n } from "../../translate/i18n";
import { FooterBase } from "./styles";
import "./index.css";

function Footer(props) {
  return (
    <FooterBase {...props}>
      <p>
        © 2022 4Learn {version} - Soften Sistemas | {i18n.t("footer.direitos")}
      </p>
    </FooterBase>
  );
}

export default Footer;
