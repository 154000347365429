export const certificate = {
  header: {
    headerType: "Logo",
    logo: "",
    background: "",
    headerStyle: {
      justifyContent: "flex-start",
      backgroundColor: "#ffffff",
    },
  },
  body: {
    bodyTitle: "Certificado",
    bodyTitleStyle: {
      fontSize: 40,
      fontFamily: "Nunito",
      fontWeight: "Bold",
      textAlign: "center",
      color: "#657176",
    },
    bodyDescription: "",
    bodyAddress: "",
    bodyColor: "#657176",
    bodyDescriptionStyle: {
      fontSize: 17,
      textAlign: "justify",
      fontFamily: "Nunito",
    },
    bodyAddressStyle: {
      fontSize: 17,
      textAlign: "justify",
      fontFamily: "Nunito",
    },
    bodyCodigo: "",
    bodyCodigoStyle: {
      fontSize: 12,
      color: "#657176",
      textAlign: "center",
      fontFamily: "Nunito",
    },
  },
  footer: {
    footerType: "Texto",
    footerText: "...",
    footerBackground: "",
    footerBackgroudColor: "#ffffff",
    footerTextStyle: {
      fontSize: 15,
      color: "#657176",
      textAlign: "center",
      fontFamily: "Nunito",
    },
  },
  verse: {
    verseType: "Simples",
    verseImg: "",
    verseTitle: "",
    verseDetails: "",
    verseTitleStyle: {
      textAlign: "left",
      fontSize: 25,
      fontWeight: "bold",
      fontFamily: "Nunito",
      color: "#000000",
    },
    verseDetailsStyle: {
      textAlign: "justify",
      fontSize: 20,
      color: "#657176",
      fontFamily: "Nunito",
    },
  },
  signatures: [],
};
