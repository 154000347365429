import React from "react";
import AnimacaoLoading from "../AnimationLoading";

export default function Loading(props) {

    let name = `container-loading ${props.title}`

    return (

        <div className={name}>
            <AnimacaoLoading />
        </div>

    )
}