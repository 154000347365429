import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import LogoGa from "../../components/img/logo/not.png";
import InputSearch from "../../components/SearchInput";
import PageDefault from "../../template/DefaultPage";
import Loading from "../../components/Loading";
import { i18n } from "../../translate/i18n";
import { formatPrice } from "../../js";
import { toast } from "react-toastify";
import services from "../../services";

export default function MaisCursos() {
  const history = useHistory();
  const { tenant, pesquisaCurso } = useParams();
  const [cursosPublicados, setCursosPublicados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cursoTitulo, setCursoTitulo] = useState(
    pesquisaCurso !== "buscar" ? pesquisaCurso : ""
  );

  async function listar() {
    const url =
      cursoTitulo.trim() !== ""
        ? `/cursos/publicados/${cursoTitulo}`
        : "/cursos/publicados";

    setLoading(true);
    await services
      .get(url)
      .then((data) => {
        setCursosPublicados(data);
      })
      .catch((e) => {
        toast.error(e.message);
      });
    setLoading(false);
  }
  useEffect(() => {
    listar();
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      listar();
    }
  };

  const handleClick = (id, titulo) => {
    return history.push(`/${tenant}/curso/${id}/${titulo}`);
  };

  return (
    <PageDefault>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <hr />
          <span className="barra">/</span>
          <span style={{ minWidth: "max-content" }}>Buscar Cursos</span>
          <span className="barra">/</span>
          <hr />
        </h5>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-4">
            <InputSearch
              name="tituloCurso"
              value={cursoTitulo}
              keyPress={handleKeyPress}
              placeholder={i18n.t("consulta.tituloCurso")}
              onChange={(e) => setCursoTitulo(e.target.value)}
              icon="mdi mdi-magnify"
              onClick={() => listar()}
            />
          </div>
        </div>

        {loading && <Loading title="container-loading-login" />}

        <span>
          <ul id="render-cursos" className="container-curso-mais-cursos">
            {cursosPublicados.length > 0 ? (
              <>
                {cursosPublicados.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleClick(item.id, item.titulo)}
                  >
                    <div className="container-sistemas-card">
                      <div className="card-body">
                        <div className="card-body-icon">
                          <span
                            className="card-body-after"
                            style={{ backgroundColor: item.cor || "#008288" }}
                          ></span>
                        </div>
                        <div className="card-body-value">
                          {item.status === "GRATUITO" ? (
                            <span>Gratuito</span>
                          ) : (
                            <span>{formatPrice(item.valor)}</span>
                          )}
                        </div>
                        <img
                          alt="logo"
                          title="logo"
                          width="50px"
                          src={item.imagem || LogoGa}
                        />
                        <div className="card-body-descricao">
                          {[item.titulo].map((titulo, index) => (
                            <span
                              key={index}
                              title={titulo}
                              alt={titulo}
                              className="titulo-curso"
                            >
                              {titulo}
                            </span>
                          ))}
                          {[item.descricao].map((descricao, index) => (
                            <span
                              key={index}
                              title={descricao}
                              alt={descricao}
                              className="descricao-curso"
                            >
                              {descricao}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <label>{i18n.t("tabela.zeroRegistros")}</label>
            )}
          </ul>
        </span>
      </div>
    </PageDefault>
  );
}
