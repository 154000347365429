import styled from "styled-components";

export const StyledCursoHeader = styled.div`
    background-color: ${props => props.color};

    .MuiLinearProgress-barColorPrimary {
        background-color: #00c86f;
    }
    .header-info-topo {
        width: clamp(250px, 1024px, 90%);
        margin: 0 auto;
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        display: flex;
        align-items: center;
        color: white;

        .header-info-curso {
            display: flex;
            width: clamp(450px,650px,60%);

            .box-img {
                display: table;

                div {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            img {
                max-width: 250px;
                max-height: 100px;
                margin-right: 10px;
            }

            .curso-desc {
                width: 400px;
                text-shadow: 1px 1px 3px #888;
                    
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }

        }

        .header-info-user {
            background-color: white;
            box-shadow: 1px 1px 8px #484848ad;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 250px;
            height: 180px;
            color: #484d52;
            position: relative;
            
            label {
                font-weight: bold;
            }

            .iconprog {
                background-color: ${props => props.color};
                width: 40px;
                height: 40px;
                font-size: 17px;
                border-radius: 100%;
                position: absolute;
                top: -10px;
                right: -10px;
                box-shadow: 1px 1px 8px #484848ad;
                color: white;

                div {
                    display: table;
                    height: 100%;
                    width: 100%;

                    i {
                        display: table-cell;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
            }

            .progress-box {
                width: 100%;
                margin: 0 auto;
                align-items: center;
                height: 50px;
                font-weight: bold;

                label {
                    height: 20px;
                }

                .progressbar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .progressbar-box {
                        width: 85%;
                    }
                }
            }

            .workload-box {
                width: 48%;

                label {
                    font-size: 1.4em;
                }
                
                span {
                    padding-left: 8px;
                    font-size: 0.8em;
                }
            }

            .countSub-box {
                width: 48%;

                label {
                    font-size: 1.4em;
                }
                
                span {
                    padding-left: 8px;
                    font-size: 0.8em;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .header-info-topo {
            flex-wrap: wrap;
            justify-content: center;

            .header-info-curso {
                display: block;
                text-align: center;
                margin-bottom: 40px;

                img {
                    width: 40%;
                    margin: 0;
                }

                .curso-desc {
                    width: clamp(250px,400px, 80%);
                    margin: 0 auto;
                }
            }

            .header-info-user {
                width: clamp(250px, 400px, 80%);
            }
        }
    }
`;

export const StyledCursolistButtons = styled.div`
    display: flex;
    background-color: #ededed;
    padding: 20px 0;

    ul {
        width: clamp(250px, 1024px, 90%);
        margin: 0 auto;
        padding: 0;
        display: flex;

        li {
            list-style: none;
            margin-right: 15px;
            cursor: pointer;
            
            a {
                display: flex;
                font-size: 12px;
                font-weight: bold;
                text-decoration: none;
                transition: all 0.35s;
                border-radius: 2px;
                padding: 10px 15px;
                align-items: center;
                justify-content: center;
                i{
                    margin-right: 2px;
                }
            }
        }

        
        .list-btn-primary a {
            color: #004473;
            background-color: #004473;
            border: 1px solid #004473;
            color: white;
            
            &:hover {
                background-color: #1566a0;
                border-color: #1566a0;
                color: white;
            }
        }

        .list-btn-secondary a {
            color: #484d52;
            border: 1px solid #484d52;
        
            &:hover {
                background-color: #004473;
                border-color: #004473;
                color: white;
            }
        }
    }
`;

export const StyledContentModulo = styled.div`
    width: clamp(250px, 1024px, 90%);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .main-content {
        padding: 0;
        width: 100%;

        .li-modulo {
            list-style: none;
            opacity: 0.8;
            transition: all 0.35s;
            cursor: pointer;

            
                display: flex;
                text-decoration: none;
                width: 100%;
                margin-bottom: 15px;
                /* border: 1px solid #858585; */
                border: 1px solid #eae7e7;
                color: #484d52;
                padding: 10px;

                .modulo-icon {
                    width: 6%;
                    font-size: 45px;
                    display: table;

                    i {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }

                .modulo-text {
                    width: 94%;
                }

                .modulo-box-top {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 5px;

                    .modulo-title {
                        display: flex;
                        font-size: 22px;
                        font-weight: bold;
                    }
        
                    .modulo-percent {
                        font-size: 12px;
    
                        span {
                            margin-left: 10px;
                        }
                    }                
                }

                .modulo-box-desc {
                    font-size: 17px;
                }

            

            &:hover {
                opacity: 1;
            }
        }
    }
`