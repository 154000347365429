import styled from 'styled-components'

export const StyledBoxButton = styled.div`
    position: relative;

    &:first-child {
        position: absolute;
        margin: 0;
        bottom: 0;
    }
`