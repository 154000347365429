import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { MODULO_ARQUIVO } from "../../config/endpoint";
import getMapping from "../../repositories/getMapping";
import "./index.css";
import DialogContent from "@material-ui/core/DialogContent";
import downloadArquivos from "../../js/downloadArquivos";
import { i18n } from "../../translate/i18n";
import { useParams } from "react-router-dom";

export default function ArquivosTreinamento() {
  const { moduloid } = useParams();

  const [open, setOpen] = React.useState(false);
  const [arquivos, setArquivos] = useState([]);

  const handleClickOpen = () => {
    getMapping.get(`${MODULO_ARQUIVO}/modulos/${moduloid}`).then((data) => {
      setArquivos(data);
    });
    setOpen(true);
  };

  return (
    <>
      <Button onClick={() => handleClickOpen()}>
        <FileCopyIcon />
        <label>{i18n.t("treinamentoPainel.arquivosAnexo")}</label>
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-nota"
      >
        <div className="header-dialog">
          <div className="header-dialog-title">
            {i18n.t("treinamentoPainel.arquivosAnexo")}
          </div>
          <div className="header-dialog-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
        </div>
        <div>
          {arquivos.length > 0 ? (
            <DialogContent>
              <div style={{ textAlign: "center" }}>
                {arquivos.map((item, index) => (
                  <div key={index} className="tr-row">
                    {[item.nome].map((texto) => (
                      <span
                        key={item.nome}
                        style={{
                          fontSize: "15px",
                          cursos: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        <i
                          className="mdi mdi-file-multiple icon-size"
                          onClick={() =>
                            downloadArquivos.DownlaodArquivos(
                              `${MODULO_ARQUIVO}/download/${item.id}`
                            )
                          }
                        ></i>
                        {texto}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </DialogContent>
          ) : (
            <div
              style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}
            >
              <label>{i18n.t("tabela.zeroRegistros")}</label>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
