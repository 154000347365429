import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../components/css/styleguide.scss";
import FormField from "../../../../components/FormField";
import useForm from "../../../../hooks/useForm";
import { i18n } from "../../../../translate/i18n";
import postMapping from "../../../../repositories/postMapping";
import putMapping from "../../../../repositories/putMapping";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import { convertBase64 } from "../../../../js/uploadImage";

import { TextSize } from "../../../../js/index";

let placeholderIframe = `Exemplo: <iframe src="https://docs.google.com/presentation/d/e/xxxx/embed" frameborder="0" width="800" height="600"></iframe>`;

export default function formAula(props) {
  const [file, setFile] = useState(null);
  const [fileOld, setFileOld] = useState(null);
  const [markdown, setMarkdown] = useState("Escreva seu texto");
  const [renderMarkdown, setRenderMarkdown] = useState("Escreva seu texto");
  const [tipo, setTipo] = useState("VIDEO");

  const initialData = {
    id: "",
    titulo: props.video.titulo,
    tipo: "",
    url: "",
    descricao: props.video.descricao,
    conteudo: "",

    slide: "",
    arquivo: "",
  };

  const { handleChange, values, clearForm } = useForm(initialData);

  const save = async function* (data) {
    // Promise that waits for "time" milliseconds
    const wait = function (time) {
      return new Promise((a, r) => {
        setTimeout(() => a(), time);
      });
    };

    // Upload "data" to your server
    // Use XMLHttpRequest.send to send a FormData object containing
    // "data"
    // Check this question: https://stackoverflow.com/questions/18055422/how-to-receive-php-image-data-over-copy-n-paste-javascript-with-xmlhttprequest

    await wait(2000);
    // yields the URL that should be inserted in the markdown
    yield "https://picsum.photos/300";
    await wait(2000);

    // returns true meaning that the save was successful
    return true;
  };

  const uploadImage = (e) => {
    if (e.target.files[0].size <= 10485760) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Aviso: Upload com limite de 10MB!");
    }
  };

  const atualiza = () => {
    if (props.edita) {
      setTipo(props.video.tipo);

      values.id = props.video.id;
      values.titulo = props.video.titulo;
      values.descricao = props.video.descricao;
      values.tipo = props.video.tipo;
      values.conteudo = props.video.conteudo;
      values.url = props.video.url;

      setTimeout(() => {
        document.querySelector("#id_titulo").value = values.titulo;
        document.querySelector("#id_descricao").value = values.descricao;

        switch (props.video.tipo) {
          case "VIDEO":
            document.querySelector("#id_url").value = values.url;
            break;

          case "SLIDE":
            values.slide = values.conteudo;
            document.querySelector("#id_slide").value = values.conteudo;
            break;

          case "PDF":
            setFileOld(values.conteudo);
            break;

          case "MARKDOWN":
            setMarkdown(values.conteudo);
            setRenderMarkdown(values.conteudo);
            break;
        }
      }, 300);
    } else {
      setTipo("VIDEO");
      setFile(null);
      setFileOld(null);
      setRenderMarkdown("Escreva seu texto");
      setMarkdown("Escreva seu texto");
    }
  };

  const limpar = () => {
    clearForm();
    setIserror(false);
  };

  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  async function handleSubmit() {
    let errorList = [];

    switch (tipo) {
      case "VIDEO":
        if (values.url === "") {
          errorList.push("Insira um url válido!");
          setErrorMessages(errorList);
          setIserror(true);
        } else {
          if (!values.url.includes("vimeo.com")) {
            let regExp =
              /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            let match = await values.url.match(regExp);
            if (!match) {
              errorList.push("Url inválida!");
              setErrorMessages(errorList);
              setIserror(true);
            } else {
              values.url = `https://www.youtube.com/watch?v=${match[7]}`;
            }
          }
        }

        break;

      case "PDF":
        if (file === null) {
          values.conteudo = fileOld;
        } else {
          let base64 = await convertBase64(file);

          if (base64.length < 1) {
            errorList.push("Erro ao enviar pdf selecionado!");
            setErrorMessages(errorList);
            setIserror(true);
          } else {
            values.conteudo = base64;
          }
        }
        break;
      case "SLIDE":
        if (values.silde === "") {
          errorList.push("Insira um link ou iframe a ser incorporado válido!");
          setErrorMessages(errorList);
          setIserror(true);
        } else {
          try {
            let s = values.slide;
            var htmlObject = document.createElement("div");
            htmlObject.innerHTML = s;
            values.conteudo = htmlObject
              .querySelector("iframe")
              .getAttribute("src");
          } catch (error) {
            values.conteudo = values.slide;
          }
        }
        break;

      case "MARKDOWN":
        if (markdown === "") {
          errorList.push("Insira uma descrição válida em seu documento!");
          setErrorMessages(errorList);
          setIserror(true);
        } else {
          values.conteudo = markdown;
        }
        break;

      default:
        break;
    }
    if (values.titulo === "") {
      errorList.push("Insira um título válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.descricao === "") {
      errorList.push("Insira uma descrição válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (tipo === "") {
      errorList.push("Insira um tipo válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      if (values.id === "") {
        await postMapping
          .post(
            {
              titulo: values.titulo,
              url: values.url,
              descricao: values.descricao,
              tipo: tipo,
              conteudo: values.conteudo,
              moduloId: props.moduloId,
            },
            "/videos"
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.aulaCad"));
            clearForm();
            limpar();
            props.onClose();
            setIserror(false);
          })
          .catch((e) => toast.error(e.message), clearForm());
      } else {
        await putMapping
          .put(
            {
              id: values.id,
              titulo: values.titulo,
              url: values.url,
              descricao: values.descricao,
              tipo: tipo,
              conteudo: values.conteudo,
              moduloId: props.moduloId,
            },
            `/videos/${values.id}`
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.aulaAtt"));
            clearForm();
            limpar();
            props.onClose();
            setIserror(false);
          })
          .catch((e) => toast.error(e.message), clearForm());
      }
    }
  }

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  function loadSuggestions(text) {
    return new Promise((accept, reject) => {
      const suggestions = [
        {
          preview: "Aula",
          value: values.titulo || "",
        },
        {
          preview: "Módulo",
          value: props.modulotitulo || "",
        },
        {
          preview: "Curso",
          value: props.cursotitulo || "",
        },
      ].filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
      accept(suggestions);
    }, 250);
  }

  async function handleMarkdown(e) {
    setMarkdown(e);
    let cuscus = setTimeout(() => {
      setRenderMarkdown(e);
    }, 500);
    clearTimeout(cuscus - 1);
  }

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        onClose={() => props.onClose()}
        onExit={() => limpar()}
        onEnter={() => atualiza()}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">Cadastro de aula</div>
          <div className="header-dialog-close" onClick={() => props.onClose()}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-dialog">
          <div className="form-cadastros">
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <div className="form-body">
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-6">
                  <FormField
                    label="Título da aula"
                    name="titulo"
                    maxLength="200"
                    value={values.titulo}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(values.titulo)}/200
                  </p>
                </div>
                <div className="tr-form-group tr-col-sm-6">
                  <label>Tipo</label>
                  <select
                    id="options"
                    className="select-input-option-hiden"
                    onChange={() =>
                      setTipo(document.getElementById("options").value)
                    }
                  >
                    <option value={tipo}>{tipo}</option>
                    <option value="VIDEO">VIDEO</option>
                    <option value="PDF">PDF</option>
                    <option value="SLIDE">SLIDE</option>
                    <option value="MARKDOWN">MARKDOWN</option>
                  </select>
                </div>
              </div>
              <div className="tr-row">
                {tipo === "VIDEO" && (
                  <div className="tr-form-group tr-col-sm-12">
                    <FormField
                      label={
                        i18n.t("formulario.urlVideo") + " (Youtube / Vimeo)"
                      }
                      name="url"
                      maxLength="200"
                      value={values.url}
                      onChange={handleChange}
                    />
                  </div>
                )}
                {tipo === "PDF" && (
                  <>
                    <div className="tr-form-group file-content tr-col-sm-6">
                      <FormField
                        label={i18n.t("formulario.arquivos") + " (.pdf) 10MB"}
                        accept=".pdf"
                        type="file"
                        name="arquivo"
                        value={values.arquivo}
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                      <div className="label-files pointer-cursor">
                        <label
                          className="primary-button-file"
                          htmlFor="id_arquivo"
                        >
                          {i18n.t("formulario.selecionarArq")}
                        </label>
                        <label>
                          {file ? file.name : i18n.t("formulario.zeroArq")}
                        </label>
                      </div>
                    </div>

                    {tipo === "PDF" && (file !== null || fileOld !== null) && (
                      <div className="tr-form-group file-content tr-col-sm-6">
                        <label>Arquivo</label>
                        <span
                          style={{
                            fontSize: "15px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          <i
                            className="mdi mdi-file-multiple icon-size"
                            style={{ marginRight: 10 }}
                            onClick={() => console.log("...")}
                          ></i>
                          Arquivo
                          <i
                            className="mdi mdi-trash-can-outline icon-size delete-table"
                            style={{ marginLeft: 10 }}
                            onClick={() => {
                              setFile(null);
                              setFileOld(null);
                            }}
                          ></i>
                        </span>
                      </div>
                    )}
                  </>
                )}

                {tipo === "SLIDE" && (
                  <div className="tr-form-group tr-col-sm-12">
                    <FormField
                      label="Iframe a ser incorporado (Google Slide / PowerPoint Web)"
                      placeholder={placeholderIframe}
                      name="slide"
                      maxLength="2000"
                      type="textarea"
                      value={values.slide}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                )}
                {tipo === "MARKDOWN" && (
                  <>
                    <div className="tr-form-group tr-col-sm-6">
                      <div>
                        <ReactMde
                          value={markdown}
                          onChange={handleMarkdown}
                          loadSuggestions={loadSuggestions}
                          childProps={{
                            writeButton: {
                              tabIndex: -1,
                            },
                          }}
                          paste={{
                            saveImage: save,
                          }}
                        />
                      </div>
                    </div>

                    <div className="tr-form-group tr-col-sm-6">
                      <>
                        <iframe
                          className="mde-preview-content"
                          style={{ height: "100%" }}
                          srcdoc={converter.makeHtml(renderMarkdown)}
                          width="100%"
                          height="100%"
                          allowfullscreen="true"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        ></iframe>
                      </>
                    </div>
                  </>
                )}
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <FormField
                    label={i18n.t("formulario.descricao")}
                    name="descricao"
                    maxLength="500"
                    type="textarea"
                    value={values.descricao}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(values.descricao)}/500
                  </p>
                </div>
              </div>
              <div className="tr-mb-4">
                <button onClick={handleSubmit}>
                  {i18n.t("button.salvar")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
