import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { i18n } from "../../../../translate/i18n";
import getMapping from "../../../../repositories/getMapping";
import Divider from "@material-ui/core/Divider";
import Loading from "../../../../components/Loading";

export default function DialogListaUser(props) {
  const [concluidos, setConcluidos] = useState([]);
  const [ver, setVer] = useState(false);
  const [nome, setNome] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalPag, setTotalPag] = useState(0);
  const [page, setPage] = useState(0);
  const [proximo, setProximo] = useState("proximoOff");
  const [anterior, setAnterior] = useState("anteriorOff");
  const [teste, setTeste] = useState([]);

  const desativeLoading = () => {
    setTimeout(function () {
      setLoading(false);
    }, 300);
  };

  const pesquisa = (e) => {
    setConcluidos([]);
    if ((e > 0 && e < totalPag) || e === 0) {
      setPage(e);
      async function listar() {
        await getMapping
          .get(`/concluidos/modulo/${props.initialData.id}?page=${e}`)
          .then((data) => {
            setConcluidos(data.content);
            setTotalPag(data.totalPages);
            desativeLoading();
            if (data.content.length > 0) {
              setProximo("");
              setAnterior("");
            } else {
              setProximo("proximoOff");
              setAnterior("anteriorOff");
            }
            if (e === 0) {
              setAnterior("anteriorOff");
            }
            if (e === data.totalPages - 1) {
              setProximo("proximoOff");
            }
          })
          .catch((err) => {
            return;
          });
      }
      listar();
    }
  };

  if (concluidos.length < 1) {
    useState.condicional = false;
  } else {
    useState.condicional = true;
  }

  function verMais(e, y) {
    setNome(y);
    setVer(true);
    setTeste(e);
  }

  const Detalhes = () => {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={ver}
        fullWidth={false}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">{nome}</div>
          <div className="header-dialog-close" onClick={() => setVer(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-dialog">
          {teste.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  backgroundColor: item.aprovado
                    ? "rgb(216 247 218)"
                    : "rgb(236 198 192)",
                }}
                className="notas"
              >
                {[item.nota].map((texto) => (
                  <p>
                    {i18n.t("treinamentoPainel.nota")} : {texto}
                  </p>
                ))}
                {[item.acertos].map((texto, index) => (
                  <p>
                    {i18n.t("treinamentoPainel.acertos")} :{" "}
                    {texto + "/" + teste[index].perguntas}
                  </p>
                ))}
                {[item.provaUsuarioId.data].map((texto) => (
                  <p>
                    {i18n.t("tabela.data")} : {texto}
                  </p>
                ))}
              </div>
              <Divider />
            </div>
          ))}
        </div>
      </Dialog>
    );
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th>{i18n.t("relatorio.usuario")}</th>
          <th>{i18n.t("tabela.notaAprovação")}</th>
          <th>{i18n.t("treinamentoPainel.acertos")}</th>
          <th>{i18n.t("tabela.tentativas")}</th>
          <th className="text-center">{i18n.t("tabela.data")}</th>
          <th></th>
        </tr>
      </thead>
    );
  };
  const TableBody = () => {
    return useState.condicional ? (
      <>
        <tbody>
          {concluidos.map((item, index) => (
            <>
              <tr key={index}>
                <td style={{ flex: "1" }} data-title="Nome : Email">
                  {[item.usuarioNome].map((texto, index) => (
                    <span key={index}>
                      {texto} : {item.usuarioEmail}
                    </span>
                  ))}
                </td>
                {[item.notaAprovacao].map((texto, index) => (
                  <td data-title="Nota" className="text-center">
                    <span key={index}>
                      {item.notaAprovacao ? texto : "---"}
                    </span>
                  </td>
                ))}
                {[item.acertos].map((texto, index) => (
                  <td data-title="Nota" className="text-center">
                    <span key={index}>
                      {item.notaAprovacao
                        ? texto + "/" + concluidos[0].perguntas
                        : "---"}
                    </span>
                  </td>
                ))}
                {[item.tentativas].map((texto, index) => (
                  <td data-title="Nota" className="text-center">
                    <span key={index}>
                      {item.notaAprovacao ? texto : "---"}
                    </span>
                  </td>
                ))}
                <td data-title="Data" className="text-center">
                  {[item.data].map((texto, index) => (
                    <span key={index}>{texto}</span>
                  ))}
                </td>
                <td>
                  <i
                    onClick={() =>
                      verMais(item.notasDetalhes, item.usuarioNome)
                    }
                    className="mdi mdi-eye-settings icon-size icon-green"
                  ></i>
                </td>
              </tr>
              <br />
            </>
          ))}
        </tbody>
        <tr>
          <td
            colSpan="6"
            className="text-center"
            data-title="Options"
            style={{ fontSize: "1.6em" }}
          >
            <i
              className={anterior}
              onClick={() => pesquisa(page - 1)}
              className="mdi mdi-arrow-left-circle icon-size icon-green"
            ></i>
            <span>
              {page + 1}/{totalPag}
            </span>
            <i
              className={proximo}
              onClick={() => pesquisa(page + 1)}
              className="mdi mdi-arrow-right-circle icon-size icon-green"
            ></i>
          </td>
        </tr>
      </>
    ) : (
      <>
        <tbody>
          <tr>
            <td
              data-title="Empty Table"
              colSpan="8"
              className="text-left"
              style={{ backgroundColor: "#eff1f9" }}
            >
              {i18n.t("tabela.zeroRegistros")}
            </td>
          </tr>
        </tbody>
      </>
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth={true}
      onClose={() => props.onClose()}
      onEntering={() => pesquisa(0)}
    >
      <div className="header-dialog">
        <div className="header-dialog-title">
          {props.initialData.subTitulo +
            " - '" +
            props.initialData.titulo +
            "'"}
        </div>
        <div className="header-dialog-close" onClick={() => props.onClose()}>
          <CloseIcon />
        </div>
      </div>
      <div className="container-dialog">
        <div
          className="header-dialog-close"
          onClick={() => props.onClose()}
        ></div>
        <table>
          <Detalhes />
          {loading && <Loading title="container-loading-login" />}
          <TableHead />
          <TableBody />
        </table>
      </div>
    </Dialog>
  );
}
