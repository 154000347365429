import React, { useContext } from "react";
import "./styles";
import MenuDefault from "../components/Menu/MenuDefault";
import MenuLateral from "../components/Menu/MenuLateral";
import { AuthContext } from "../contexts/auth.context";
import Footer from "../components/Footer";

export default function DefaultPage({ children, menuLateral }) {
  const { checkPermission } = useContext(AuthContext);
  return checkPermission && menuLateral ? (
    <>
      <div className="background-interno template-video ">
        <MenuLateral children={children} />
        <Footer className="footer-video" />
      </div>
    </>
  ) : (
    <>
      <div className="background-interno">
        <MenuDefault />
        {children}
        <Footer />
      </div>
    </>
  );
}
