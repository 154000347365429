import React, { useState } from "react";
import "../../components/FormField";
import DefaultLogin from "../../template/DefaultLogin";
import FormField from "../../components/FormField";
import Form from "../../components/Form/FormLogin";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button/";
import useForm from "../../hooks/useForm";
import empresaRepository from "../../repositories/Empresa";
import { toast } from "react-toastify";
import "../Login/login.scss";
import Alert from "@material-ui/lab/Alert";
import Loading from "../../components/Loading";

export default function Cadastro() {
  const initialData = {
    tenantClientId: "",
    dbName: "",
    email: "",
    nome: "",
    senha: "",
    senhaConfirma: "",
    nomeEmpresa: "",
    senhaADM: ""

  };
  const { handleChange, values } = useForm(initialData);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);


  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    let errorList = [];
    if (values.tenantClientId === "") {
      errorList.push("Campo Dominio em branco!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);
    }
    if (values.dbName === "") {
      errorList.push("Campo CNPJ  em branco!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);

    }
    if (values.email === "") {
      errorList.push("email em branco!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);

    }
    if (values.nome === "") {
      errorList.push("Nome do responsavel em branco!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);
    }
    if (values.nomeempresa === "") {
      errorList.push("Nome da empresa em branco!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);
    }
    if (values.senha.length < 5 || values.senha.length > 30) {
      errorList.push("Necessário senha de 5 a 30 caracteres!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);

    }

    if (values.senha !== values.senhaConfirma) {
      errorList.push("Senha não coincide com a confirmação!");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);

    }
    if (values.senhaADM === "") {
      errorList.push("Senha adm inválida");
      setErrorMessages(errorList);
      setLoading(false);
      setIserror(true);

    }
    if (errorList.length < 1) {
      setLoading(true);

      empresaRepository
        .cadastrarEmpresa({
          tenantClientId: values.tenantClientId,
          dbName: values.dbName,
          nome: values.nome,
          email: values.email,
          senha: values.senha,
          nomeEmpresa: values.nomeEmpresa,
          senhaADM: values.senhaADM
        })
        .then(() => {
          setLoading(false)
          history.push(`${values.tenantClientId}/login`);
          toast.success("Cadastrado empresa com sucesso.")
        })
        .catch((e) => {
          toast.error(e.message);
          setLoading(false);
        });

    }
  }

  return (
    <DefaultLogin>
      {loading &&
        <Loading title="container-loading-login" />
      }

      <Form
        className="form-login"
        onSubmit={handleSubmit}
        style={{ width: "600px", maxWidth: "600px" }}
      >
        <div>
          <label className="logo-ga" style={{ fontSize: 12, fontWeight: "bold" }}>Cadastro empresa</label>
        </div>
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="form-body">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <FormField
                label="Senha ADM da plataforma"
                type="password"
                maxLength="255"
                name="senhaADM"
                value={values.senhaADM}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <FormField
                label="Nome Empresa"
                placeholder="Ex: Empresa Modelo"
                type="text"
                maxLength="255"
                name="nomeEmpresa"
                value={values.nomeEmpresa}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="CPF/CNPJ"
                placeholder="Ex: 15353299000"
                type="text"
                maxLength="14"
                name="dbName"
                value={values.dbName}
                onChange={handleChange}
              />
            </div>
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="Pathname de acesso do sistema!"
                type="text"
                placeholder="Ex: /empresateste"
                maxLength="255"
                name="tenantClientId"
                value={values.tenantClientId}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="E-mail"
                type="text"
                name="email"
                maxLength="90"
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="Nome do responsável"
                type="text"
                name="nome"
                maxLength="30"
                value={values.nome}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="Senha"
                type="password"
                maxLength="30"
                name="senha"
                value={values.senha}
                onChange={handleChange}
              />
            </div>
            <div className="tr-form-group tr-col-sm-6">
              <FormField
                label="Confirmar senha"
                type="password"
                name="senhaConfirma"
                maxLength="20"
                value={values.senhaConfirma}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <Button className="botao-login" type="submit">
          Cadastrar
        </Button>
      </Form>


    </DefaultLogin>
  );
}
