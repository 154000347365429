import React, { useState, useEffect } from "react";
import {
  BoxOptions,
  Certificado,
  ContainerCertificado,
  Button,
} from "./styles";

import iconImg from "../../components/img/file.png";

import { HeaderBackground } from "../../components/CertificadoLayout/HeaderBackground";
import { FooterBackground } from "../../components/CertificadoLayout/FooterBackground";
import { ContainerCodigo } from "../../components/CertificadoLayout/ContainerCodigo";
import { DetailsContent } from "../../components/CertificadoLayout/DetailsContent";
import { SignatureCard } from "../../components/CertificadoLayout/SignatureCard";
import { ContentCard } from "../../components/CertificadoLayout/ContentCard";
import { FooterText } from "../../components/CertificadoLayout/FooterText";
import { HeaderLogo } from "../../components/CertificadoLayout/HeaderLogo";
import { CardTitle } from "../../components/CertificadoLayout/CardTitle";

import { uploadImg } from "../../js/uploadImage";
import { certificate } from "../../js/object";
import { useHistory, useParams } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import { Slider } from "@material-ui/core";
import { toast } from "react-toastify";
import services from "../../services";
import { TextSize } from "../../js";
import MenuDefault from "../../components/Menu/MenuDefault";

export default function CadastroCertificado() {
  const { tenant } = useParams();
  const [cert, setCert] = useState({
    id: null,
    nome: "",
  });
  const [header, setHeader] = useState(certificate.header);
  const [footer, setFooter] = useState(certificate.footer);
  const [body, setBody] = useState(certificate.body);
  const [signatures, setSignatures] = useState(certificate.signatures);
  const [verse, setVerse] = useState(certificate.verse);
  const [verso, setVerso] = useState(false);
  const [lengthVerse, setLengthVerse] = useState(20);
  const [lengthBodyDescription, setLengthBodyDescription] = useState(20);
  const [lengthVerseTitle, setLengthVerseTitle] = useState(40);
  const history = useHistory();

  const { certificadoid } = useParams();

  useEffect(() => {
    if (certificadoid !== "novo") {
      services
        .get(`/certificadolayout/${certificadoid}`)
        .then((data) => {
          setCert({
            id: data.id,
            nome: data.nome,
          });
          const { headerType, logo, background, headerStyle } = data.header;
          setHeader({
            headerType,
            logo,
            background,
            headerStyle: JSON.parse(headerStyle),
          });

          const {
            bodyTitle,
            bodyTitleStyle,
            bodyDescription,
            bodyAddress,
            bodyColor,
            bodyDescriptionStyle,
            bodyAddressStyle,
            bodyCodigo,
            bodyCodigoStyle,
          } = data.body;
          setBody({
            bodyTitle,
            bodyTitleStyle: JSON.parse(bodyTitleStyle),
            bodyDescription,
            bodyAddress,
            bodyColor,
            bodyDescriptionStyle: JSON.parse(bodyDescriptionStyle),
            bodyAddressStyle: JSON.parse(bodyAddressStyle),
            bodyCodigo: bodyCodigo ? bodyCodigo : "",
            bodyCodigoStyle: JSON.parse(bodyCodigoStyle),
          });

          const {
            footerType,
            footerText,
            footerBackground,
            footerBackgroudColor,
            footerTextStyle,
          } = data.footer;
          setFooter({
            footerType,
            footerText,
            footerBackground,
            footerBackgroudColor,
            footerTextStyle: JSON.parse(footerTextStyle),
          });

          const {
            verseType,
            verseImg,
            verseTitle,
            verseDetails,
            verseTitleStyle,
            verseDetailsStyle,
          } = data.verse;
          setVerse({
            verseType,
            verseImg,
            verseTitle,
            verseDetails,
            verseTitleStyle: JSON.parse(verseTitleStyle),
            verseDetailsStyle: JSON.parse(verseDetailsStyle),
          });
          setSignatures(data.signatures);
        })
        .catch((e) => toast.error(e.message));
    }
  }, []);

  async function saveCertificado() {
    if (!cert.nome.trim()) {
      return toast.error("Atenção: Campo nome inválido");
    }

    let objSalvar = {
      id: cert.id,
      nome: cert.nome,
      header: {
        headerType: header.headerType,
        logo: header.logo,
        background: header.background,
        headerStyle: JSON.stringify(header.headerStyle),
      },
      body: {
        bodyTitle: body.bodyTitle,
        bodyTitleStyle: JSON.stringify(body.bodyTitleStyle),
        bodyDescription: body.bodyDescription,
        bodyAddress: body.bodyAddress,
        bodyColor: body.bodyColor,
        bodyDescriptionStyle: JSON.stringify(body.bodyDescriptionStyle),
        bodyAddressStyle: JSON.stringify(body.bodyAddressStyle),
        bodyCodigo: body.bodyCodigo,
        bodyCodigoStyle: JSON.stringify(body.bodyCodigoStyle),
      },
      footer: {
        footerType: footer.footerType,
        footerText: footer.footerText,
        footerBackground: footer.footerBackground,
        footerBackgroudColor: footer.footerBackgroudColor,
        footerTextStyle: JSON.stringify(footer.footerTextStyle),
      },
      verse: {
        verseType: verse.verseType,
        verseImg: verse.verseImg,
        verseTitle: verse.verseTitle,
        verseDetails: verse.verseDetails,
        verseTitleStyle: JSON.stringify(verse.verseTitleStyle),
        verseDetailsStyle: JSON.stringify(verse.verseDetailsStyle),
      },
      signatures: signatures,
    };

    services
      .post("/certificadolayout", objSalvar)
      .then((data) => {
        setCert((prevState) => ({
          ...prevState,
          id: data.id,
        }));
        toast.success(i18n.t("toastSuccess.certificadoCad"));
      })
      .catch((e) => toast.error(e.message));
  }

  function selectTypeHeader() {
    const typeHeader = document.getElementById("typeHeader").value;
    setHeader((prevState) => ({
      ...prevState,
      headerType: typeHeader,
    }));
  }

  function selectVerseType() {
    const verseType = document.getElementById("verseType").value;
    setVerse((prevState) => ({
      ...prevState,
      verseType: verseType,
    }));
  }

  function selectHeaderJustify() {
    const headerJustify = document.getElementById("headerJustify").value;
    setHeader((prevState) => ({
      ...prevState,
      headerStyle: {
        ...prevState.headerStyle,
        justifyContent: headerJustify,
      },
    }));
  }

  function selectTitleJustify() {
    const bodyTitleJustify = document.getElementById("bodyTitleJustify").value;
    setBody((prevState) => ({
      ...prevState,
      bodyTitleStyle: {
        ...prevState.bodyTitleStyle,
        textAlign: bodyTitleJustify,
      },
    }));
  }

  function selectContentFooterJustify() {
    const contentFooterJustify = document.getElementById(
      "contentFooterJustify"
    ).value;

    setFooter((prevState) => ({
      ...prevState,
      footerTextStyle: {
        ...prevState.footerTextStyle,
        textAlign: contentFooterJustify,
      },
    }));
  }

  function selectTypeFooter() {
    const typeFooter = document.getElementById("typeFooter").value;
    setFooter((prevState) => ({
      ...prevState,
      footerType: typeFooter,
    }));
  }

  function selectContentVersoJustify() {
    const verseTitleJustify =
      document.getElementById("verseTitleJustify").value;
    setVerse((prevState) => ({
      ...prevState,
      verseTitleStyle: {
        ...prevState.verseTitleStyle,
        textAlign: verseTitleJustify,
      },
    }));
  }

  function selectBodyAddressJustify() {
    const bodyAddressJustify =
      document.getElementById("bodyAddressJustify").value;
    setBody((prevState) => ({
      ...prevState,
      bodyAddressStyle: {
        ...prevState.bodyAddressStyle,
        textAlign: bodyAddressJustify,
      },
    }));
  }

  function selectBodyCodigoStyleJustify() {
    const bodyStyleJustify = document.getElementById("bodyCodigoStyle").value;
    setBody((prevState) => ({
      ...prevState,
      bodyCodigoStyle: {
        ...prevState.bodyCodigoStyle,
        textAlign: bodyStyleJustify,
      },
    }));
  }

  function selectFontTitle() {
    const selectFontTitle = document.getElementById("selectFontTitle").value;
    setBody((prevState) => ({
      ...prevState,
      bodyTitleStyle: {
        ...prevState.bodyTitleStyle,
        fontFamily: selectFontTitle,
      },
    }));
  }

  function selectFontBodyDescription() {
    const selectFontBodyDescription = document.getElementById(
      "selectFontBodyDescription"
    ).value;
    setBody((prevState) => ({
      ...prevState,
      bodyDescriptionStyle: {
        ...prevState.bodyDescriptionStyle,
        fontFamily: selectFontBodyDescription,
      },
    }));
    setBody((prevState) => ({
      ...prevState,
      bodyAddressStyle: {
        ...prevState.bodyAddressStyle,
        fontFamily: selectFontBodyDescription,
      },
    }));
  }

  function selectFontFooter() {
    const selectFontFooter = document.getElementById("selectFontFooter").value;
    setFooter((prevState) => ({
      ...prevState,
      footerTextStyle: {
        ...prevState.footerTextStyle,
        fontFamily: selectFontFooter,
      },
    }));
  }

  function selectFontTitleVerse() {
    const selectFontTitleVerse = document.getElementById(
      "selectFontTitleVerse"
    ).value;
    setVerse((prevState) => ({
      ...prevState,
      verseTitleStyle: {
        ...prevState.verseTitleStyle,
        fontFamily: selectFontTitleVerse,
      },
    }));
  }

  function selectFontDetailsVerse() {
    const selectFontDetailsVerse = document.getElementById(
      "selectFontDetailsVerse"
    ).value;
    setVerse((prevState) => ({
      ...prevState,
      verseDetailsStyle: {
        ...prevState.verseDetailsStyle,
        fontFamily: selectFontDetailsVerse,
      },
    }));
  }

  function addSignature() {
    if (signatures.length <= 4) {
      setSignatures((prevState) => [
        ...prevState,
        {
          image: "",
          label: i18n.t("certificado.assinatura"),
        },
      ]);
    } else {
      toast.warning(i18n.t("toastWarning.limiteAssinaturas"));
    }
  }

  function removeSignature(indice) {
    let array = [...signatures];
    array.splice(indice, 1);
    setSignatures(array);
  }

  function returnJustify(e) {
    if (e === "flex-start" || e === "left") {
      return i18n.t("certificado.esquerda");
    } else if (e === "flex-end" || e === "right") {
      return i18n.t("certificado.direita");
    } else {
      return i18n.t("certificado.centro");
    }
  }

  const variaveis = [
    { nome: "Aluno" },
    { nome: "Curso" },
    { nome: "Data_Inscrição" },
    { nome: "Data_Conclusão" },
  ];

  function autoTeste(e) {
    let position = 0;
    e.target.addEventListener("keydown", function (e) {
      position = e.target.selectionStart;
      if (e.which === 50) {
        autocompleteDOIS(e, e.target, variaveis, position);
      } else {
        autocompleteDOIS(e, e.target, null);
      }
    });

    setBody((prevState) => ({
      ...prevState,
      bodyDescription: e.target.value,
    }));
  }

  function autocompleteDOIS(targetE, inp, arr, position) {
    let a,
      i = replaceSpecialChars(inp.value);
    if (targetE.keyCode !== 40 && targetE.keyCode !== 38) {
      closeAllLists();
    }
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items");

    if (inp.value && arr) {
      const val = replaceSpecialChars(inp.value);
      inp.parentNode.appendChild(a);
      for (i = 0; i < arr.length; i++) {
        const lowercase = replaceSpecialChars(arr[i].nome.toLowerCase());
        if (lowercase) {
          const bd = document.createElement("div");

          const description =
            val.substr(0, position) +
            " @" +
            lowercase.toUpperCase().slice(0) +
            " " +
            val.substr(position, val.length);
          const nomeX = description;

          const template = document.createElement("template");
          template.innerHTML = `<label class="sf-text-capitalize sf-cursor-pointer">${lowercase}</label>
                                    <input type='hidden' value='${nomeX}' />`;
          bd.append(template.content);
          bd.addEventListener("click", function (e) {
            inp.value = this.getElementsByTagName("input")[0].value;
            setBody((prevState) => ({
              ...prevState,
              bodyDescription: this.getElementsByTagName("input")[0].value,
            }));
            closeAllLists();
            inp.focus();
          });
          a.appendChild(bd);
        }
      }
    } else {
      a.remove(a);
    }
    function closeAllLists(elmnt) {
      const x = document.getElementsByClassName("autocomplete-items");
      for (let i = 0; i < x.length; i++) {
        if (elmnt !== x[i] && elmnt !== inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
  }

  function handleLimitSizeVerseTitle() {
    if (verse.verseTitle.length < 30) {
      setLengthVerseTitle(40);
    }
    if (verse.verseTitle.length > 30) {
      if (verse.verseTitleStyle.fontSize > 30) {
        setVerse((prevState) => ({
          ...prevState,
          verseTitleStyle: {
            ...prevState.verseTitleStyle,
            fontSize: 30,
          },
        }));
      }
      setLengthVerseTitle(30);
    }
  }

  function handleLimitSizeBodyDescription() {
    if (body.bodyDescription.length < 500) {
      setLengthBodyDescription(20);
    }
    if (
      body.bodyDescription.length > 500 &&
      body.bodyDescription.length <= 600
    ) {
      if (body.bodyDescriptionStyle.fontSize > 18) {
        setBody((prevState) => ({
          ...prevState,
          bodyDescriptionStyle: {
            ...prevState.bodyDescriptionStyle,
            fontSize: 18,
          },
        }));
      }
      setLengthBodyDescription(18);
    }

    if (
      body.bodyDescription.length > 500 &&
      body.bodyDescription.length <= 600
    ) {
      if (body.bodyDescriptionStyle.fontSize > 17) {
        setBody((prevState) => ({
          ...prevState,
          bodyDescriptionStyle: {
            ...prevState.bodyDescriptionStyle,
            fontSize: 17,
          },
        }));
      }
      setLengthBodyDescription(17);
    }

    if (
      body.bodyDescription.length > 700 &&
      body.bodyDescription.length <= 800
    ) {
      if (body.bodyDescriptionStyle.fontSize > 16) {
        setBody((prevState) => ({
          ...prevState,
          bodyDescriptionStyle: {
            ...prevState.bodyDescriptionStyle,
            fontSize: 16,
          },
        }));
      }
      setLengthBodyDescription(16);
    }

    if (
      body.bodyDescription.length > 800 &&
      body.bodyDescription.length <= 900
    ) {
      if (body.bodyDescriptionStyle.fontSize > 15) {
        setBody((prevState) => ({
          ...prevState,
          bodyDescriptionStyle: {
            ...prevState.bodyDescriptionStyle,
            fontSize: 15,
          },
        }));
      }
      setLengthBodyDescription(15);
    }

    if (
      body.bodyDescription.length > 900 &&
      body.bodyDescription.length <= 1000
    ) {
      if (body.bodyDescriptionStyle.fontSize > 14) {
        setBody((prevState) => ({
          ...prevState,
          bodyDescriptionStyle: {
            ...prevState.bodyDescriptionStyle,
            fontSize: 14,
          },
        }));
      }
      setLengthBodyDescription(14);
    }
  }

  function handleLimitSizeVerse() {
    if (verse.verseDetails.length < 1000) {
      setLengthVerse(20);
    }
    if (verse.verseDetails.length > 1000 && verse.verseDetails.length <= 1500) {
      if (verse.verseDetailsStyle.fontSize > 18) {
        setVerse((prevState) => ({
          ...prevState,
          verseDetailsStyle: {
            ...prevState.verseDetailsStyle,
            fontSize: 18,
          },
        }));
      }
      setLengthVerse(18);
    }

    if (verse.verseDetails.length > 1500 && verse.verseDetails.length <= 2000) {
      if (verse.verseDetailsStyle.fontSize > 15) {
        setVerse((prevState) => ({
          ...prevState,
          verseDetailsStyle: {
            ...prevState.verseDetailsStyle,
            fontSize: 15,
          },
        }));
      }
      setLengthVerse(15);
    }

    if (verse.verseDetails.length > 2100 && verse.verseDetails.length <= 3000) {
      if (verse.verseDetailsStyle.fontSize > 13) {
        setVerse((prevState) => ({
          ...prevState,
          verseDetailsStyle: {
            ...prevState.verseDetailsStyle,
            fontSize: 13,
          },
        }));
      }
      setLengthVerse(13);
    }

    if (verse.verseDetails.length > 3000 && verse.verseDetails.length <= 5000) {
      if (verse.verseDetailsStyle.fontSize > 10) {
        setVerse((prevState) => ({
          ...prevState,
          verseDetailsStyle: {
            ...prevState.verseDetailsStyle,
            fontSize: 10,
          },
        }));
      }
      setLengthVerse(10);
    }
  }

  useEffect(() => {
    handleLimitSizeVerseTitle();
  }, [verse.verseTitle.length]);

  useEffect(() => {
    handleLimitSizeBodyDescription();
  }, [body.bodyDescription.length]);

  useEffect(() => {
    handleLimitSizeVerse();
  }, [verse.verseDetails.length]);

  function replaceSpecialChars(str) {
    return str
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  return (
    <>
      <MenuDefault menuLateral />
      <h5 className="titulo-pages tr-mb-5">
        <hr />
        <span className="barra">/</span>
        <span style={{ minWidth: "max-content" }}>
          {i18n.t("certificado.editor")}
        </span>
        <span className="barra">/</span>
        <hr />
      </h5>
      <div>
        <div>
          <div className="tr-form-group tr-col-sm-12">
            <div className="sequence-page">
              <a onClick={() => history.goBack()}>Cadastro de certificado</a>
              <i className="mdi mdi-chevron-right" />
              <span>Editor de Certificado</span>
            </div>
          </div>
        </div>
      </div>

      <div className="tr-row">
        <ContainerCertificado>
          <Certificado>
            {!verso ? (
              <>
                <ul>
                  <li className="certificado-topo">
                    {header.headerType === "Background" ? (
                      <HeaderBackground background={header.background} />
                    ) : (
                      <HeaderLogo
                        headerLogo={{
                          logo: header.logo,
                          style: header.headerStyle,
                        }}
                      />
                    )}
                  </li>
                  <li className="certificado-content">
                    <div>
                      <CardTitle
                        contentTitle={{
                          title: body.bodyTitle,
                          titleStyle: body.bodyTitleStyle,
                        }}
                      />
                      <ContentCard
                        contentDescription={{
                          description: body.bodyDescription,
                          descriptionStyle: body.bodyDescriptionStyle,
                          address: body.bodyAddress,
                          addressStyle: body.bodyAddressStyle,
                          color: body.bodyColor,
                        }}
                      />
                    </div>
                    <div className="containerAssignatures">
                      {signatures.map((index, indice) => (
                        <SignatureCard signature={index} key={indice} />
                      ))}
                    </div>
                    <div>
                      <ContainerCodigo
                        bodyCodigo={body.bodyCodigo}
                        bodyCodigoStyle={body.bodyCodigoStyle}
                      />
                    </div>
                  </li>
                  <li className="certificado-footer">
                    {footer.footerType === "Background" ? (
                      <FooterBackground background={footer.footerBackground} />
                    ) : (
                      <FooterText
                        footerText={{
                          text: footer.footerText,
                          backgroudColor: footer.footerBackgroudColor,
                          textStyle: footer.footerTextStyle,
                        }}
                      />
                    )}
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul>
                  <li className="certificado-verso">
                    <DetailsContent verse={verse} />
                    <a className="url">{`https://4learn.softensistemas.com.br/${tenant}/certificado/xxxxxxxxxxxxxxxxxxxxxx`}</a>
                  </li>
                </ul>
              </>
            )}
          </Certificado>
        </ContainerCertificado>
        <BoxOptions>
          <div className="session">
            <h1>{i18n.t("certificado.edicao")}</h1>
            <div className="tr-row">
              <div className="tr-form-group tr-col-sm-5 tr-col-4">
                <Button
                  style={{ filter: verso ? "brightness(0.5)" : "" }}
                  onClick={() => setVerso(false)}
                >
                  {i18n.t("certificado.frente")}
                </Button>
              </div>
              <div className="tr-form-group tr-col-sm-5 tr-col-4">
                <Button
                  style={{ filter: !verso ? "brightness(0.5)" : "" }}
                  onClick={() => setVerso(true)}
                >
                  {i18n.t("certificado.verso")}
                </Button>
              </div>
            </div>

            <div className="tr-row">
              <div className="tr-form-group tr-col-sm-12">
                <label>{i18n.t("formulario.nome")}</label>
                <input
                  id="nome"
                  placeholder={i18n.t("certificado.digiteNome")}
                  type="text"
                  maxLength={40}
                  value={cert.nome}
                  className="input"
                  onChange={(e) =>
                    setCert((prevState) => ({
                      ...prevState,
                      nome: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            {!verso ? (
              <>
                <h4>{i18n.t("certificado.header")}</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.tipo")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="typeHeader"
                      onChange={() => selectTypeHeader()}
                    >
                      <option value={header.headerType}>
                        {header.headerType}
                      </option>
                      <option value="Logo">Logo</option>
                      <option value="Background">Background</option>
                    </select>
                  </div>
                </div>

                {header.headerType === "Background" ? (
                  <div className="tr-row">
                    <div className="tr-form-group tr-col-sm-12">
                      <div className="box-img">
                        <img
                          src={header.background || iconImg}
                          alt="background"
                        />
                        {header.background && (
                          <i
                            title={i18n.t("iconsTitle.excluir")}
                            className="mdi mdi-close-octagon delete-table"
                            onClick={() =>
                              setHeader((prevState) => ({
                                ...prevState,
                                background: "",
                              }))
                            }
                          ></i>
                        )}
                      </div>
                      <input
                        id="headerBackground"
                        className="input-img"
                        type="file"
                        name="headerBackground"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => {
                          uploadImg(e).then((data) =>
                            setHeader((prevState) => ({
                              ...prevState,
                              background: data,
                            }))
                          );
                        }}
                      />
                      <p className="tipImgSize">
                        {i18n.t("formulario.tamanhoBgCertificado")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <div className="box-img">
                          <img src={header.logo || iconImg} alt="logo" />
                          {header.logo && (
                            <i
                              title={i18n.t("iconsTitle.excluir")}
                              className="mdi mdi-close-octagon delete-table"
                              onClick={() =>
                                setHeader((prevState) => ({
                                  ...prevState,
                                  logo: "",
                                }))
                              }
                            ></i>
                          )}
                        </div>
                        <input
                          id="headerLogo"
                          className="input-img"
                          type="file"
                          value={""}
                          name="headerLogo"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => {
                            uploadImg(e).then((data) => {
                              setHeader((prevState) => ({
                                ...prevState,
                                logo: data,
                              }));
                            });
                          }}
                        />
                        <p className="tipImgSize">
                          {i18n.t("formulario.tamanhoImg300")}
                        </p>
                      </div>
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.backgroundColor")}</label>
                        <input
                          id="headerColor"
                          className="input-color"
                          type="color"
                          name="headerColor"
                          value={header.headerStyle.backgroundColor}
                          onChange={(e) =>
                            setHeader((prevState) => ({
                              ...prevState,
                              headerStyle: {
                                ...prevState.headerStyle,
                                backgroundColor: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                    </div>

                    <div className="tr-row">
                      <div className="tr-form-group tr-col-sm-12">
                        <label>{i18n.t("certificado.alinharIcon")} </label>
                        <select
                          className="select-input-option-hiden"
                          id="headerJustify"
                          onChange={() => selectHeaderJustify()}
                        >
                          <option value={header.headerStyle.justifyContent}>
                            {returnJustify(header.headerStyle.justifyContent)}
                          </option>
                          <option value="flex-start">
                            {i18n.t("certificado.esquerda")}
                          </option>
                          <option value="center">
                            {i18n.t("certificado.centro")}
                          </option>
                          <option value="flex-end">
                            {i18n.t("certificado.direita")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <h4>{i18n.t("certificado.titulo")}</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.titulo")}</label>
                    <input
                      id="bodyTitle"
                      className="input"
                      type="text"
                      maxLength={40}
                      name="bodyTitle"
                      value={body.bodyTitle}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyTitle: e.target.value,
                        }))
                      }
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(body.bodyTitle)}/40
                    </p>
                  </div>
                </div>

                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.fonte")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="selectFontTitle"
                      onChange={() => selectFontTitle()}
                    >
                      <option value={body.bodyTitleStyle.fontFamily}>
                        {body.bodyTitleStyle.fontFamily}
                      </option>
                      <option value="Nunito">Nunito</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Gar-A-MondTall">Gar-A-MondTall</option>
                      <option value="Learning-Curve">Learning-Curve</option>
                    </select>
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                    <label>{i18n.t("certificado.tamanhoFonte")}</label>
                    <Slider
                      min={10}
                      max={42}
                      value={body.bodyTitleStyle.fontSize}
                      valueLabelDisplay="auto"
                      onChange={(e, newValue) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyTitleStyle: {
                            ...prevState.bodyTitleStyle,
                            fontSize: Number(newValue),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.alinharTexto")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="bodyTitleJustify"
                      onChange={() => selectTitleJustify()}
                    >
                      <option value={body.bodyTitleStyle.textAlign}>
                        {returnJustify(body.bodyTitleStyle.textAlign)}
                      </option>
                      <option value="center">
                        {i18n.t("certificado.centro")}
                      </option>
                      <option value="left">
                        {i18n.t("certificado.esquerda")}
                      </option>
                      <option value="right">
                        {i18n.t("certificado.direita")}
                      </option>
                    </select>
                  </div>

                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("formulario.cor")}</label>
                    <input
                      id="bodyTitleCor"
                      className="input"
                      type="color"
                      name="bodyTitleCor"
                      value={body.bodyTitleStyle.color}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyTitleStyle: {
                            ...prevState.bodyTitleStyle,
                            color: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <h4>{i18n.t("certificado.conteudo")}</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.descricaoCertificado")}</label>
                    <textarea
                      id="bodyDescription"
                      className="input"
                      type="textarea"
                      maxLength={1000}
                      name="bodyDescription"
                      value={body.bodyDescription}
                      onChange={(e) => autoTeste(e)}
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(body.bodyDescription)}/1000
                    </p>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.fonte")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="selectFontBodyDescription"
                      onChange={() => selectFontBodyDescription()}
                    >
                      <option value={body.bodyDescriptionStyle.fontFamily}>
                        {body.bodyDescriptionStyle.fontFamily}
                      </option>
                      <option value="Nunito">Nunito</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Gar-A-MondTall">Gar-A-MondTall</option>
                      <option value="Learning-Curve">Learning-Curve</option>
                    </select>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("formulario.cor")}</label>
                    <input
                      id="bodyDescriptionColor"
                      className="input"
                      type="color"
                      name="bodyDescriptionColor"
                      value={body.bodyColor}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyColor: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                    <label>{i18n.t("certificado.tamanhoFonte")}</label>
                    <Slider
                      id="bodyDescriptionStyleFont"
                      min={10}
                      max={lengthBodyDescription}
                      value={body.bodyDescriptionStyle.fontSize}
                      valueLabelDisplay="auto"
                      onChange={(e, newValue) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyDescriptionStyle: {
                            ...prevState.bodyDescriptionStyle,
                            fontSize: Number(newValue),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.endereco")}</label>
                    <textarea
                      id="bodyAddress"
                      className="input"
                      type="textarea"
                      maxLength={80}
                      name="bodyAddress"
                      value={body.bodyAddress}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyAddress: e.target.value,
                        }))
                      }
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(body.bodyAddress)}/80
                    </p>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                    <label>{i18n.t("certificado.tamanhoFonte")}</label>
                    <Slider
                      id="bodyAddressStyleFont"
                      min={10}
                      max={20}
                      value={body.bodyAddressStyle.fontSize}
                      valueLabelDisplay="auto"
                      onChange={(e, newValue) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyAddressStyle: {
                            ...prevState.bodyAddressStyle,
                            fontSize: Number(newValue),
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.alinharTexto")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="bodyAddressJustify"
                      onChange={() => selectBodyAddressJustify()}
                    >
                      <option value={body.bodyAddressStyle.textAlign}>
                        {returnJustify(body.bodyAddressStyle.textAlign)}
                      </option>
                      <option value="left">
                        {i18n.t("certificado.esquerda")}
                      </option>
                      <option value="center">
                        {i18n.t("certificado.centro")}
                      </option>
                      <option value="right">
                        {i18n.t("certificado.direita")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <h4 className="assinaturaIcon">
                      {i18n.t("certificado.assinatura")}
                      <i
                        title={i18n.t("iconsTitle.excluir")}
                        className="mdi mdi-plus-thick"
                        onClick={addSignature}
                      ></i>
                    </h4>
                  </div>
                </div>
                {signatures.map((index, indice) => (
                  <div key={indice}>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-sm-12">
                        <label>Label</label>
                        <div className="box-label">
                          <input
                            label="Label"
                            id={`signatureLabel${indice}`}
                            className="input"
                            type="text"
                            maxLength={30}
                            name={`signatureLabel${indice}`}
                            value={index.label}
                            onChange={(e) => {
                              const newState = [...signatures];
                              newState[indice].label = e.target.value;
                              setSignatures(newState);
                            }}
                          />
                          <i
                            title={i18n.t("iconsTitle.excluir")}
                            className="mdi mdi-trash-can-outline icon-size delete-table"
                            onClick={() => removeSignature(indice)}
                          ></i>
                        </div>
                      </div>
                    </div>

                    <div className="tr-row">
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <div className="box-img">
                          <img
                            src={signatures[indice].image || iconImg}
                            alt="img"
                          />
                          {signatures[indice].image && (
                            <i
                              title={i18n.t("iconsTitle.excluir")}
                              className="mdi mdi-close-octagon icon-size delete-table "
                              onClick={() => {
                                const newState = [...signatures];
                                newState[indice].image = "";
                                setSignatures(newState);
                              }}
                            ></i>
                          )}
                        </div>
                        <input
                          id="signatureImg"
                          className="input-img"
                          type="file"
                          value={""}
                          name="signatureImg"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => {
                            uploadImg(e).then((data) => {
                              const newState = [...signatures];
                              newState[indice].image = data;
                              setSignatures(newState);
                            });
                          }}
                        />
                        <p className="tipImgSize">
                          {i18n.t("formulario.tamanhoImg300")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                <h4>Código</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>Código</label>
                    <input
                      id="bodyCodigo"
                      className="input"
                      type="text"
                      maxLength={100}
                      name="bodyCodigo"
                      value={body.bodyCodigo}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyCodigo: e.target.value,
                        }))
                      }
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(body.bodyCodigo)}/100
                    </p>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.alinharTexto")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="bodyCodigoStyle"
                      onChange={() => selectBodyCodigoStyleJustify()}
                    >
                      <option value={body.bodyCodigoStyle.textAlign}>
                        {returnJustify(body.bodyCodigoStyle.textAlign)}
                      </option>
                      <option value="center">
                        {i18n.t("certificado.centro")}
                      </option>
                      <option value="left">
                        {i18n.t("certificado.esquerda")}
                      </option>
                      <option value="right">
                        {i18n.t("certificado.direita")}
                      </option>
                    </select>
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("formulario.cor")}</label>
                    <input
                      id="bodyCodigoStyle"
                      className="input"
                      type="color"
                      name="bodyCodigoStyle"
                      value={body.bodyCodigoStyle.color}
                      onChange={(e) =>
                        setBody((prevState) => ({
                          ...prevState,
                          bodyCodigoStyle: {
                            ...prevState.bodyCodigoStyle,
                            color: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <h4>Footer</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.tipo")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="typeFooter"
                      onChange={() => selectTypeFooter()}
                    >
                      <option value={footer.footerType}>
                        {footer.footerType}
                      </option>
                      <option value="Texto">
                        {i18n.t("certificado.texto")}
                      </option>
                      <option value="Background">Background</option>
                    </select>
                  </div>
                </div>
                {footer.footerType === "Background" ? (
                  <div className="tr-row">
                    <div className="tr-form-group tr-col-sm-12">
                      <div className="box-img">
                        <img
                          src={footer.footerBackground || iconImg}
                          alt="background"
                        />
                        {footer.footerBackground && (
                          <i
                            title={i18n.t("iconsTitle.excluir")}
                            className="mdi mdi-close-octagon icon-size delete-table "
                            onClick={() =>
                              setFooter((prevState) => ({
                                ...prevState,
                                footerBackground: "",
                              }))
                            }
                          ></i>
                        )}
                      </div>
                      <input
                        id="footerBackground"
                        className="input-img"
                        type="file"
                        name="footerBackground"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => {
                          uploadImg(e).then((data) => {
                            setFooter((prevState) => ({
                              ...prevState,
                              footerBackground: data,
                            }));
                          });
                        }}
                      />
                      <p className="tipImgSize">
                        {i18n.t("formulario.tamanhoBgCertificado")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-sm-12">
                        <label>{i18n.t("certificado.texto")}</label>
                        <textarea
                          id="footerText"
                          className="input"
                          type="text"
                          name="footerText"
                          maxLength={300}
                          value={footer.footerText}
                          onChange={(e) =>
                            setFooter((prevState) => ({
                              ...prevState,
                              footerText: e.target.value,
                            }))
                          }
                        />
                        <p
                          style={{ fontSize: 10, textAlign: "end", padding: 3 }}
                        >
                          {TextSize(footer.footerText)}/300
                        </p>
                      </div>
                    </div>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.fonte")}</label>
                        <select
                          className="select-input-option-hiden"
                          id="selectFontFooter"
                          onChange={() => selectFontFooter()}
                        >
                          <option value={footer.footerTextStyle.fontFamily}>
                            {footer.footerTextStyle.fontFamily}
                          </option>
                          <option value="Nunito">Nunito</option>
                          <option value="Diploma">Diploma</option>
                          <option value="Gar-A-MondTall">Gar-A-MondTall</option>
                          <option value="Learning-Curve">Learning-Curve</option>
                        </select>
                      </div>
                    </div>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.alinharTexto")}</label>
                        <select
                          className="select-input-option-hiden"
                          id="contentFooterJustify"
                          onChange={() => selectContentFooterJustify()}
                        >
                          <option value={footer.footerTextStyle.textAlign}>
                            {returnJustify(footer.footerTextStyle.textAlign)}
                          </option>
                          <option value="center">
                            {i18n.t("certificado.centro")}
                          </option>
                          <option value="left">
                            {i18n.t("certificado.esquerda")}
                          </option>
                          <option value="right">
                            {i18n.t("certificado.direita")}
                          </option>
                        </select>
                      </div>
                      <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                        <label>{i18n.t("certificado.tamanhoFonte")} </label>
                        <Slider
                          id="footerTextFonte"
                          min={10}
                          max={20}
                          value={footer.footerTextStyle.fontSize}
                          valueLabelDisplay="auto"
                          onChange={(e, newValue) =>
                            setFooter((prevState) => ({
                              ...prevState,
                              footerTextStyle: {
                                ...prevState.footerTextStyle,
                                fontSize: Number(newValue),
                              },
                            }))
                          }
                        />
                      </div>
                    </div>
                    <div className="tr-row">
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.textColor")}</label>
                        <input
                          id="footerTextCorTexto"
                          className="input"
                          type="color"
                          name="footerTextCorTexto"
                          value={footer.footerTextStyle.color}
                          onChange={(e) =>
                            setFooter((prevState) => ({
                              ...prevState,
                              footerTextStyle: {
                                ...prevState.footerTextStyle,
                                color: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.backgroundColor")}</label>
                        <input
                          id="contentFooterBackgroundCor"
                          className="input"
                          type="color"
                          name="contentFooterBackgroundCor"
                          value={footer.footerBackgroudColor}
                          onChange={(e) =>
                            setFooter((prevState) => ({
                              ...prevState,
                              footerBackgroudColor: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h4>{i18n.t("certificado.titulo")}</h4>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.titulo")}</label>
                    <input
                      id="verseTitle"
                      className="input"
                      type="text"
                      maxLength={40}
                      name="verseTitle"
                      value={verse.verseTitle}
                      onChange={(e) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseTitle: e.target.value,
                        }))
                      }
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(verse.verseTitle)}/40
                    </p>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>Verso Tipo</label>
                    <select
                      className="select-input-option-hiden"
                      id="verseType"
                      onChange={() => selectVerseType()}
                    >
                      <option value={verse.verseType}>{verse.verseType}</option>
                      <option value="Simples">Simples</option>
                      <option value="Rubrica">Rubrica</option>
                    </select>
                  </div>
                </div>
                <div className="tr-row">
                  {verse.verseType === "Simples" ? (
                    <>
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <label>{i18n.t("certificado.alinharTexto")}</label>
                        <select
                          className="select-input-option-hiden"
                          id="verseTitleJustify"
                          onChange={() => selectContentVersoJustify()}
                        >
                          <option value={verse.verseTitleStyle.textAlign}>
                            {returnJustify(verse.verseTitleStyle.textAlign)}
                          </option>
                          <option value="left">
                            {i18n.t("certificado.esquerda")}
                          </option>
                          <option value="center">
                            {i18n.t("certificado.centro")}
                          </option>
                          <option value="right">
                            {i18n.t("certificado.direita")}
                          </option>
                        </select>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tr-form-group tr-col-lg tr-col-md-12">
                        <div className="box-img">
                          <img src={verse.verseImg || iconImg} alt="logo" />
                          {verse.verseImg && (
                            <i
                              title={i18n.t("iconsTitle.excluir")}
                              className="mdi mdi-close-octagon delete-table"
                              onClick={() =>
                                setVerse((prevState) => ({
                                  ...prevState,
                                  verseImg: "",
                                }))
                              }
                            ></i>
                          )}
                        </div>
                        <input
                          id="verseImg"
                          className="input-img"
                          type="file"
                          value={""}
                          name="verseImg"
                          accept=".jpeg, .png, .jpg"
                          onChange={(e) => {
                            uploadImg(e).then((data) => {
                              setVerse((prevState) => ({
                                ...prevState,
                                verseImg: data,
                              }));
                            });
                          }}
                        />
                        <p className="tipImgSize">
                          {i18n.t("formulario.tamanhoImg300")}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>Cor </label>
                    <input
                      id="verseTitleCor"
                      className="input"
                      type="color"
                      name="verseTitleCor"
                      value={verse.verseTitleStyle.color}
                      onChange={(e) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseTitleStyle: {
                            ...prevState.verseTitleStyle,
                            color: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.fonte")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="selectFontTitleVerse"
                      onChange={() => selectFontTitleVerse()}
                    >
                      <option value={verse.verseTitleStyle.fontFamily}>
                        {verse.verseTitleStyle.fontFamily}
                      </option>
                      <option value="Nunito">Nunito</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Gar-A-MondTall">Gar-A-MondTall</option>
                      <option value="Learning-Curve">Learning-Curve</option>
                    </select>
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                    <label>{i18n.t("certificado.tamanhoFonte")} </label>
                    <Slider
                      id="verseTitleSize"
                      min={10}
                      max={lengthVerseTitle}
                      value={verse.verseTitleStyle.fontSize}
                      valueLabelDisplay="auto"
                      onChange={(e, newValue) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseTitleStyle: {
                            ...prevState.verseTitleStyle,
                            fontSize: Number(newValue),
                          },
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>{i18n.t("certificado.conteudo")}</label>
                    <textarea
                      id="verseDetails"
                      className="input"
                      type="textarea"
                      name="verseDetails"
                      maxLength={5000}
                      value={verse.verseDetails}
                      onChange={(e) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseDetails: e.target.value,
                        }))
                      }
                    />
                    <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                      {TextSize(verse.verseDetails)}/5000
                    </p>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>{i18n.t("certificado.fonte")}</label>
                    <select
                      className="select-input-option-hiden"
                      id="selectFontDetailsVerse"
                      onChange={() => selectFontDetailsVerse()}
                    >
                      <option value={verse.verseDetailsStyle.fontFamily}>
                        {verse.verseDetailsStyle.fontFamily}
                      </option>
                      <option value="Nunito">Nunito</option>
                      <option value="Diploma">Diploma</option>
                      <option value="Gar-A-MondTall">Gar-A-MondTall</option>
                      <option value="Learning-Curve">Learning-Curve</option>
                    </select>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-lg tr-col-md-12">
                    <label>Cor </label>
                    <input
                      id="verseDetailsCor"
                      className="input"
                      type="color"
                      name="verseDetailsCor"
                      value={verse.verseDetailsStyle.color}
                      onChange={(e) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseDetailsStyle: {
                            ...prevState.verseDetailsStyle,
                            color: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="tr-form-group tr-col-lg tr-col-md-12 font-select">
                    <label>{i18n.t("certificado.tamanhoFonte")}</label>
                    <Slider
                      id="verseDetailsSize"
                      min={8}
                      max={lengthVerse}
                      value={verse.verseDetailsStyle.fontSize}
                      valueLabelDisplay="auto"
                      onChange={(e, newValue) =>
                        setVerse((prevState) => ({
                          ...prevState,
                          verseDetailsStyle: {
                            ...prevState.verseDetailsStyle,
                            fontSize: Number(newValue),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </>
            )}
            <div className="box-btns-end">
              <Button type="submit" onClick={saveCertificado}>
                {i18n.t("button.salvar")}
              </Button>
            </div>
          </div>
        </BoxOptions>
      </div>
    </>
  );
}
