import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { messages } from "./languages";

i18n.use(LanguageDetector).init({
  debug: true,
  // lng: window.navigator.language,
  defaultNS: ["translations"],
  fallbackLng: "pt",
  supportedLngs: ["en", "pt", "es"],
  ns: ["translations"],
  resources: messages,
});

export { i18n };
