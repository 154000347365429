import React, { createContext, useState } from "react";
import service from "../services";
import { toast } from "react-toastify";
import JWT from "jsonwebtoken";

export const AuthContext = createContext({});

export default function AuthProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [emailAtual, setEmailAtual] = useState("");

  async function signIn({ email, senha, tenant }) {
    setEmailAtual(email);
    setLoading(true);
    return await service
      .postAuth(
        {
          email: email,
          senha: senha,
          tenantOrClientId: tenant,
        },
        "/auth"
      )
      .then((data) => {
        if (data) {
          sessionStorage.setItem("app-token", data.token);
          const path = tenant.replace("/login", "");
          const usuarioLogado = {
            nome: data.nome,
            email: data.email,
            tenant: path,
            id: data.id,
          };
          sessionStorage.setItem("user", JSON.stringify(usuarioLogado));
          sessionStorage.setItem("dashboard", JSON.stringify(data.dashboard));
          setLoading(false);
          return path + "/dashboard";
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
        return e;
      });
  }
  const checkPermission = () => {
    const isLogged = !!sessionStorage.getItem("app-token");
    const token = JWT.decode(sessionStorage.getItem("app-token"));

    if (isLogged) {
      if (token.scopes.length > 0) {
        const { authority } = token.scopes[0];

        return authority === "adm";
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        loading,
        setLoading,
        checkPermission,
        emailAtual,
        setEmailAtual,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
