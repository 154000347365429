import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import "../../../../components/css/styleguide.scss";
import Form from "../../../../components/Form";
import FormField from "../../../../components/FormField";
import useForm from "../../../../hooks/useForm";
import { i18n } from "../../../../translate/i18n";
import postMapping from "../../../../repositories/postMapping";
import putMapping from "../../../../repositories/putMapping";
import { PROVA_PERGUNTA } from "../../../../config/endpoint";

export default function FormPergunta(props) {
  const initialData = {
    id: props.initialData.id,
    pergunta: props.initialData.pergunta,
    alt1: props.initialData.alt1,
    alt2: props.initialData.alt2,
    alt3: props.initialData.alt3,
    alt4: props.initialData.alt4,
    resposta: props.initialData.resposta,
  };
  let atualiza = () => {
    values.id = props.initialData.id;
    values.pergunta = props.initialData.pergunta;
    values.alt1 = props.initialData.alt1;
    values.alt2 = props.initialData.alt2;
    values.alt3 = props.initialData.alt3;
    values.alt4 = props.initialData.alt4;
    values.resposta = props.initialData.resposta;
    setTimeout(() => {
      document.querySelector("#id_pergunta").value = values.pergunta;
      document.querySelector("#id_alt1").value = values.alt1;
      document.querySelector("#id_alt2").value = values.alt2;
      document.querySelector("#id_alt3").value = values.alt3;
      document.querySelector("#id_alt4").value = values.alt4;
      document.getElementById("options").value = values.resposta;
    }, 100);
  };
  const limpar = () => {
    values.id = "";
    values.pergunta = "";
    values.alt1 = "";
    values.alt2 = "";
    values.alt3 = "";
    values.alt4 = "";
    values.resposta = "";
    setIserror(false);
  };
  const { handleChange, values, clearForm } = useForm(initialData);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const NumberSize = (e) => {
    return e.length;
  };

  async function handleSubmit(e) {
    e.preventDefault();

    let errorList = [];
    values.resposta = document.getElementById("options").value;

    if (values.pergunta === "") {
      errorList.push("Insira uma pergunta válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.alt1 === "") {
      errorList.push("Insira uma 1° alternativa válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.alt2 === "") {
      errorList.push("Insira uma 2° alternativa válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.alt3 === "") {
      errorList.push("Insira uma 3° alternativa válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.alt4 === "") {
      errorList.push("Insira uma 4° alternativa válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.resposta === "" || values.resposta === undefined) {
      errorList.push("Selecione uma resposta válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (errorList.length < 1) {
      if (values.id === "") {
        await postMapping
          .post(
            {
              provaId: props.initialData.provaId,
              pergunta: values.pergunta,
              alt1: values.alt1,
              alt2: values.alt2,
              alt3: values.alt3,
              alt4: values.alt4,
              resposta: values.resposta,
            },
            PROVA_PERGUNTA
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.perguntaCad"));
            clearForm();
            limpar();
            props.onClose();
            setIserror(false);
          })
          .catch((e) => toast.error(e.message));
      } else {
        await putMapping
          .put(
            {
              id: values.id,
              provaId: props.initialData.provaId,
              pergunta: values.pergunta,
              alt1: values.alt1,
              alt2: values.alt2,
              alt3: values.alt3,
              alt4: values.alt4,
              resposta: values.resposta,
            },
            `${PROVA_PERGUNTA}/${values.id}`
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.perguntaAtt"));
            clearForm();
            limpar();
            props.onClose();
            setIserror(false);
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props.dialog === 2 ? true : false}
        onClose={() => props.onClose()}
        onEnter={() => atualiza()}
        onExit={() => limpar()}
        fullWidth={true}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">
            {i18n.t("menu.cadPergunta")}
          </div>
          <div className="header-dialog-close" onClick={() => props.onClose()}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-dialog">
          <Form className="form-cadastros" onSubmit={handleSubmit}>
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <div className="form-body">
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <FormField
                    label={i18n.t("formulario.pergunta")}
                    name="pergunta"
                    type="textarea"
                    maxLength="1500"
                    value={values.pergunta}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {NumberSize(values.pergunta)}/1500
                  </p>
                </div>
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-6">
                  <FormField
                    label={"1° " + i18n.t("formulario.alternativa")}
                    name="alt1"
                    type="textarea"
                    maxLength="1500"
                    value={values.alt1}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {NumberSize(values.alt1)}/1500
                  </p>
                </div>
                <div className="tr-form-group tr-col-sm-6">
                  <FormField
                    label={"2° " + i18n.t("formulario.alternativa")}
                    name="alt2"
                    type="textarea"
                    maxLength="1500"
                    value={values.alt2}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {NumberSize(values.alt2)}/1500
                  </p>
                </div>
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-6">
                  <FormField
                    label={"3° " + i18n.t("formulario.alternativa")}
                    name="alt3"
                    type="textarea"
                    maxLength="1500"
                    value={values.alt3}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {NumberSize(values.alt3)}/1500
                  </p>
                </div>
                <div className="tr-form-group tr-col-sm-6">
                  <FormField
                    label={"4° " + i18n.t("formulario.alternativa")}
                    name="alt4"
                    type="textarea"
                    maxLength="1500"
                    value={values.alt4}
                    onChange={handleChange}
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {NumberSize(values.alt4)}/1500
                  </p>
                </div>
                <div className="tr-form-group tr-col-sm-6">
                  <select id="options">
                    <option value="">
                      {i18n.t("formulario.selecioneCorreta")}
                    </option>
                    <option value="1">
                      {"1° " + i18n.t("formulario.alternativa")}
                    </option>
                    <option value="2">
                      {"2° " + i18n.t("formulario.alternativa")}
                    </option>
                    <option value="3">
                      {"3° " + i18n.t("formulario.alternativa")}
                    </option>
                    <option value="4">
                      {"4° " + i18n.t("formulario.alternativa")}
                    </option>
                  </select>
                </div>
              </div>
              <div className="tr-mb-4">
                <button type="submit">{i18n.t("button.salvar")}</button>
              </div>
            </div>
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
