import React, { useState } from "react";

import deleteMapping from "../../../repositories/deleteMapping";
import ConfirmeDialog from "../../../components/ConfirmeDialog";
import { CardMenu } from "../../../components/MenuCardOptions";
import DialogGrupo from "../../../components/Dialogs/Grupo";
import getMapping from "../../../repositories/getMapping";
import PageDefault from "../../../template/DefaultPage";
import ButtonIcon from "../../../components/ButtonIcon";
import FormField from "../../../components/FormField";
import Loading from "../../../components/Loading";
import { GRUPO } from "../../../config/endpoint";
import "../../../components/css/styleguide.scss";
import { i18n } from "../../../translate/i18n";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";

export default function CursoLista(props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [grupo, setGrupo] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const buscaNome = {
    nomeGrupo: "",
  };
  const { handleChange, values } = useForm(buscaNome);
  const [opcoes, setOpcoes] = useState(null);
  const [dialogConfirm, setDialogConfirm] = useState(false);

  const handleBusca = () => {
    listar();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBusca();
    }
  };

  async function listar() {
    if (values.nomeGrupo !== "") {
      if (values.nomeGrupo.length >= 3) {
        setLoading(false);
        await getMapping
          .get(
            `${GRUPO}/${values.nomeGrupo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}`
          )
          .then((data) => {
            if (data.length < 1) {
              toast.warning(i18n.t("toastWarning.zeroGrupo"));
            }
            setGrupo(data);
            setLoading(false);
          })
          .catch((err) => {
            return;
          });
      } else {
        toast.error(i18n.t("toastError.catactereConsul"));
      }
    } else {
      setLoading(true);
      await getMapping
        .get(`${GRUPO}/all`)
        .then((data) => {
          setGrupo(data);
          setLoading(false);
        })
        .catch((err) => {
          return;
        });
    }
  }
  const handleDelete = () => {
    deleteMapping
      .deletar(`${GRUPO}/${id}`)
      .then(() => {
        setDialogConfirm(false);
        listar();
        toast.success(i18n.t("toastSuccess.grupoDel"));
      })
      .catch((e) => toast.error(e.message));
  };
  const handleEdita = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClick = () => {
    setId("");
    setNome("");
    setOpenDialog(true);
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th>{i18n.t("tabela.codigo")}</th>
          <th style={{ width: "100%" }}>{i18n.t("formulario.nome")}</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };
  const TableBody = () => {
    return (
      <tbody>
        {grupo.map((item, index) => (
          <tr key={index}>
            <td data-title="ID">
              <span>{item.id}</span>
            </td>

            <td style={{ flex: "1" }} data-title="Nome">
              <span>{item.nome}</span>
            </td>
            <td data-title="Ações" className="text-center">
              <div>
                <a style={{ cursor: "pointer" }}>
                  <i
                    style={{ fontSize: "1.8em" }}
                    className="mdi mdi-chevron-down"
                    onClick={() => setOpcoes(index)}
                  />
                </a>
                <CardMenu open={opcoes === index} width="150px">
                  <a
                    onClick={() => {
                      setId(item.id);
                      setNome(item.nome);
                      handleEdita();
                    }}
                  >
                    <i className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"></i>
                    Editar
                  </a>
                  <a
                    onClick={() => {
                      setDialogConfirm(true);
                      setId(item.id);
                    }}
                  >
                    <i className="mdi mdi-trash-can-outline icon-size delete-table"></i>
                    Excluir
                  </a>
                </CardMenu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <PageDefault menuLateral>
      <div
        className="largura-padrao"
        onClick={() => {
          if (opcoes !== null) {
            setOpcoes(null);
          }
        }}
      >
        <h5 className="titulo-pages tr-mb-5">
          <span> {i18n.t("menu.cadGrupo")}</span>
        </h5>
        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-5">
              <FormField
                label=""
                name="nomeGrupo"
                onKeyPress={handleKeyPress}
                placeholder={i18n.t("consulta.nomeGrupo")}
                onChange={handleChange}
                value={values.nomeGrupo}
                maxLength="30"
              />
            </div>
            <div className="tr-col"></div>
            <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
              <div className="tr-mr-4">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={handleBusca}
                />
              </div>
              <div>
                <ButtonIcon
                  value={i18n.t("button.novo")}
                  icon="mdi mdi-file icon-mr-12"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          {loading && <Loading title="container-loading-login" />}
          <table>
            <TableHead />
            <TableBody />
          </table>
        </div>
      </div>
      <DialogGrupo
        id={id}
        nome={nome}
        onClose={handleClose}
        open={openDialog}
        listar={listar}
      />
      <ConfirmeDialog
        title="Excluir"
        description="Deseja realmente excluir esse item?"
        open={dialogConfirm}
        confirm={handleDelete}
        onClose={() => setDialogConfirm(false)}
      />
    </PageDefault>
  );
}
