import React from "react";

import { View, Text, StyleSheet } from "@react-pdf/renderer";

import "./index.scss";

export function ContainerCodigoPdf({ bodyCodigo, bodyCodigoStyle }) {
  return (
    <View style={styles.Container}>
      <Text style={bodyCodigoStyle}>{bodyCodigo || ""}</Text>
    </View>
  );
}

export function ContainerCodigo({ bodyCodigo, bodyCodigoStyle }) {
  return (
    <div className="variableContainer">
      <h1 style={bodyCodigoStyle}>{bodyCodigo || ""}</h1>
    </div>
  );
}

const styles = StyleSheet.create({
  Container: {
    width: "100%",
  },
});
