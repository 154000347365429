import React, { useContext } from "react";

import LogoNotFound from "../../components/img/logo/not.png";
import { AuthContext } from "../../contexts/auth.context";
import { Link, useParams } from "react-router-dom";
import Button2 from "../ButtonLink/ButtonLink2";
import { i18n } from "../../translate/i18n";
import Menu from "@material-ui/core/Menu";
import Button from "../ButtonLink";
import "../css/inputguide.scss";

export default function MenuDefault({ menuLateral }) {
  const { checkPermission } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tenant } = useParams();
  const usuario = JSON.parse(sessionStorage.getItem("user"));
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    sessionStorage.clear();
  }

  return checkPermission && menuLateral ? (
    <nav className="nav-menu">
      <div className="nav-menu-body largura-padrao">
        <div className="holder"></div>
        <div className="opcoes">
          <Button
            id="ButtonName"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <p>
              {i18n.t("menu.ola")}, {usuario.nome}{" "}
              <i className="mdi mdi-chevron-down" />
            </p>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Button2 as={Link} to={`/${tenant}/perfil/usuario`}>
              <i className="mdi mdi-account-details" />
              {i18n.t("menu.perfil")}
            </Button2>
            {/* <Button2 as={Link} to={`/${tenant}/maiscursos/buscar`}>
              <i className="mdi mdi-notebook-check" />
              Mais Cursos
            </Button2> */}
            <Button2 as={Link} to={`/${tenant}/dashboard`}>
              <i className="mdi mdi-school" />
              Portal Aluno
            </Button2>

            {/* <div>
              <Button2 as={Link} to={`/${tenant}/config/empresa`}>
                <i className="mdi mdi-office-building" />
                {i18n.t("menu.configEmpresa")}
              </Button2>
              <Button2 as={Link} to={`/${tenant}/cadastro/curso`}>
                <i className="mdi mdi-book" />
                {i18n.t("menu.cadCurso")}
              </Button2>

              <Button2 as={Link} to={`/${tenant}/cadastro/modulo`}>
                <i className="mdi mdi-view-module" />
                {i18n.t("menu.cadModulo")}
              </Button2>

              <Button2 as={Link} to={`/${tenant}/cadastro/certificado`}>
                <i className="mdi mdi-certificate" />
                Cadastro de Certificado
              </Button2>

              <Button2 as={Link} to={`/${tenant}/cadastro/instrutor`}>
                <i className="mdi mdi-account" />
                {i18n.t("menu.cadInstrutor")}
              </Button2>

              <Button2 as={Link} to={`/${tenant}/cadastro/grupo`}>
                <i className="mdi mdi-account-switch" />
                {i18n.t("menu.cadGrupo")}
              </Button2>
              <Button2 as={Link} to={`/${tenant}/integracoes`}>
                <i className="mdi mdi-web" />
                Integrações
              </Button2>
              <Button2 as={Link} to={`/${tenant}/cadastro/usuario`}>
                <i className="mdi mdi-account" />
                {i18n.t("menu.usuarios")}
              </Button2>
              <Button2 as={Link} to={`/${tenant}/consultas`}>
                <i className="mdi mdi-file-search-outline" />
                {i18n.t("menu.consultas")}
              </Button2>
            </div> */}

            <Button2 as={Link} to={`/${tenant}/login`} onClick={logout}>
              <i className="mdi mdi-logout" />
              {i18n.t("menu.sair")}
            </Button2>
          </Menu>
        </div>
      </div>
    </nav>
  ) : (
    <nav className="nav-menu">
      <div className="nav-menu-body largura-padrao">
        <div className="holder">
          <div className="imagem">
            <Link to={`/${tenant}/dashboard`}>
              <img
                src={dash.logo || LogoNotFound}
                alt="logo empresa"
                className="logo-ga"
              />
            </Link>
          </div>
        </div>
        <div className="opcoes">
          <Button
            id="ButtonName"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <p>
              {i18n.t("menu.ola")}, {usuario.nome}{" "}
              <i className="mdi mdi-chevron-down" />
            </p>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Button2 as={Link} to={`/${tenant}/perfil/usuario`}>
              <i className="mdi mdi-account-details" />
              {i18n.t("menu.perfil")}
            </Button2>
            <Button2 as={Link} to={`/${tenant}/maiscursos/buscar`}>
              <i className="mdi mdi-notebook-check" />
              Mais Cursos
            </Button2>

            <Button2 as={Link} to={`/${tenant}/cadastro/curso`}>
              <i className="mdi mdi-view-dashboard" />
              Portal Administrador
            </Button2>

            <Button2 as={Link} to={`/${tenant}/login`} onClick={logout}>
              <i className="mdi mdi-logout" />
              {i18n.t("menu.sair")}
            </Button2>
          </Menu>
        </div>
      </div>
    </nav>
  );
}
