import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import ButtonIcon from "../ButtonIcon";
import { i18n } from "../../translate/i18n";
import { StyledBoxButton } from "./styles";

export default function ConfirmeDialog({
  title,
  description,
  confirm,
  open,
  onClose,
}) {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll={"paper"}
      onClose={() => onClose()}
    >
      <div className="header-dialog">
        <div className="header-dialog-title">{title}</div>
        <div className="header-dialog-close" onClick={() => onClose()}>
          <CloseIcon />
        </div>
      </div>
      <div className="container-dialog">
        <div className="header-dialog-close" onClick={() => onClose()}></div>
        <p>{description}</p>
        <StyledBoxButton className="tr-row">
          <div className="tr-col-sm-6 tr-mt-4">
            <ButtonIcon
              style={{ margin: "0 auto" }}
              value={i18n.t("button.nao")}
              onClick={onClose}
            />
          </div>
          <div className="tr-col-sm-6 tr-mt-4">
            <ButtonIcon value={i18n.t("button.sim")} onClick={confirm} />
          </div>
        </StyledBoxButton>
      </div>
    </Dialog>
  );
}
