import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function DialogForm({
  open,
  onClose,
  title,
  fullWidth,
  maxWidth,
  children,
  style,
}) {
  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        scroll={"paper"}
        maxWidth={maxWidth}
        onClose={() => onClose()}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">{title}</div>
          <div className="header-dialog-close" onClick={() => onClose()}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-dialog">
          <div className="header-dialog-close" onClick={() => onClose()}></div>
          <DialogContent style={style}>{children}</DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
