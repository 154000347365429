const messages = {
  pt: {
    translations: {
      menu: {
        perfil: "Editar Perfil",
        configEmpresa: "Configurações Empresa",
        cadCurso: "Cadastro de Curso",
        cadModulo: "Cadastro de Módulo",
        cadInstrutor: "Cadastro de Instrutor",
        cadProva: "Cadastro de Prova",
        cadUsuario: "Cadastro de Usuário",
        cadGrupo: "Cadastro de Grupo",
        usuarios: "Usuários",
        sair: "Sair",
        ola: "Olá",
        perfilUsuario: "Perfil de Usuário",
        cadPergunta: "Cadastro de Perguntas",
        cadConteudo: "Cadastro de conteúdo",
        usuario: "Usuário",
        consultas: "Consultas",
        meuscursos: "Meus Cursos",
      },
      dashboard: {
        botaoDestaque: "VEJA OS CURSOS",
        cursosLivres: "Cursos Livres",
        cursosExclusivos: "Cursos Exclusivos",
      },
      button: {
        salvar: "Salvar",
        novo: "Novo",
        listar: "Listar",
        sim: "Sim",
        nao: "Não",
        imprimir: "Imprimir",
        gerar: "Gerar",
        consultar: "Consultar",
        proximaAtividade: "Próxima atividade",
      },

      login: {
        email: "E-mail",
        senha: "Senha",
        entrar: "Entrar",
      },
      formulario: {
        titulo: "Título",
        descricao: "Descrição",
        cor: "Cor",
        corTitulo: "Cor título",
        corDescricao: "Cor descrição",
        corBotao: "Cor botão",
        corTextoBotao: "Cor texto botão",
        corBotaoMenu: "Cor botão menu",
        corIlustracao: "Cor ilustração",
        status: "Status",
        thumbnail: "Thumbnail",
        icone: "Ícone",
        curso: "Curso",
        instrutor: "Instrutor",
        preRequisito: "Pré-Requisito",
        resetMod: "Resetar módulo para usuário",
        nome: "Nome",
        novaSenha: "Nova senha",
        confirmarSenha: "Confirmar nova senha",
        usuario: "Usuário",
        senha: "Senha",
        tituloVideo: "Título do Video",
        urlVideo: "Url do Video",
        tamanhoLogo: "125X75 pixels. inferior a 300kb",
        tamanhoImg300: "Inferior a 300kb",
        tamanhoBackground: "1920X990 pixels. inferior a 300kb",
        tamanhoIconeCurso: "50X50 pixels. inferior a 300kb",
        tamanhothumbMod: "300x160 pixels. inferior a 300kb",
        tamanhoBgCertificado: "1754x186 pixels. inferior a 300kb",
        pergunta: "Pergunta",
        alternativa: "Alternativa",
        exclusivo: "EXCLUSIVO",
        gratuito: "GRATUITO",
        encerrado: "ENCERRADO",
        certificado: "Backgorund Certificado",
        layoutCertificado: "Layout Certificado",
        tempoEstimado: "Duração estimada (min)",
        ativo: "Ativo",
        selecione: "Selecione",
        cursoModulo: "Curso/Módulo (Sem prova vinculada)",
        mediaaprovacao: "Media de Aprovação",
        selecioneCorreta: "Selecione alternativa correta",
        configUsuario: "Configurar Usuário",
        modulosCompletado: "Módulos concluídos",
        selecionarImg: "Selecionar imagem",
        selecionarArq: "Selecinar arquivo",
        zeroImg: "Nenhuma imagem selecionada",
        zeroArq: "Nenhum arquivo selecionado",
        grupo: "Grupo",
        videoAula: "Vídeo aula",
        arquivos: "Arquivos",
        filtro: "Filtro",
        todosGrupos: "Todos os grupos",
      },
      consulta: {
        tituloCurso: "Título Curso",
        cursosDisp: "Cursos Públicos",
        cursosExcl: "Cursos Exclusivos",
        cursosEnc: "Cursos Encerrados",
        todosCurs: "Todos Cursos",
        selecioneCurs: "Selecione o curso",
        nomeInst: "Nome Instrutor",
        nomeUsuario: "Nome do Usuário",
        nomeGrupo: "Nome Grupo",
        ativo: "Ativo",
        bloqueado: "Bloqueado",
        relatorio: "Relatório",
      },
      tabela: {
        codigo: "Código",
        inscritos: "Inscritos",
        opcoes: "Opções",
        tituloModulo: "Título Módulo",
        conteudo: "Conteúdo",
        modulo: "Módulo",
        concluidos: "Concluídos",
        data: "Data",
        notaAprovação: "Nota de aprovação",
        tentativas: "Tentativas",
        zeroRegistros: "Nenhum registro encontrado.",
        tentativa: "Tentativa",
        cursoModulo: "Curso / Módulo",
        nomeEmail: "Nome / Email",
        perguntaProva: "Perguntas por prova",
      },
      toastSuccess: {
        dashboardAtt: "Atualizado Dashboard.",
        certificadoAtt: "Atualizado Certificado.",
        cursoAtt: "Curso Atualizado.",
        cursoCad: "Curso Cadastrado.",
        cursoDel: "Curso excluido.",
        moduloCad: "Módulo Cadastrado.",
        moduloAtt: "Módulo Atualizado.",
        moduloDel: "Módulo Excluído.",
        aulaCad: "Aula cadastrada.",
        aulaAtt: "Aula atualizada.",
        aulaDel: "Aula excluída",
        provaCad: "Prova Cadastrada.",
        provaAtt: "Prova Atualizada.",
        provaDel: "Prova Excluída.",
        perguntaCad: "Pergunta Cadastrada.",
        perguntaAtt: "Pergunta Atualizada.",
        perguntaDel: "Pergunta Excluída.",
        usuarioCad: "Usuário cadastrado.",
        usuarioAtt: "Usuário Atualizado.",
        usuarioDel: "Usuário Excluído.",
        instrutorCad: "Instrutor Cadastrado.",
        instrutorAtt: "Instrutor Atualizado.",
        instrutorDel: "Instrutor Excluído.",
        grupoCad: "Grupo Cadastrado",
        grupoAtt: "Grupo Atualizado",
        grupoDel: "Grupo Excluído",
        reLogar: "Atualizado Usuário, logue novamente.",
        certificadoCad: "Certificado cadastrado com sucesso!",
      },
      toastInfo: {
        aprovadoMod: "Você já foi aprovado no Módulo.",
      },
      toastWarning: {
        cursoVazio: "AVISO: Nenhum Módulo cadastrado no curso.",
        modVazio: "AVISO: Módulo sem vídeo cadastrado.",
        modBlock: "AVISO: Módulo bloqueado.",
        blockProva:
          "AVISO: É necessário concluir todas as aulas antes de prosseguir.",
        zeroCurso: "AVISO: Nenhum Curso encontrado na consulta.",
        zeroModulo: "AVISO: Nenhum Módulo encontrado na consulta.",
        zeroUsuario: "AVISO: Nenhum Usuário encontrado na consulta.",
        zeroInstrutor: "AVISO: Nenhum Instrutor encontrado na consulta.",
        zeroProva: "AVISO: Nenhuma Prova encontrada na consulta.",
        zeroGrupo: "AVISO: Nenhum grupo encontrado na consulta.",
        provaIndisp: "AVISO: Prova não disponível até o momento.",
        zeroConsulta: "AVISO: Nenhum registro encontrado.",
        limiteAssinaturas: "Atenção: Limite de assinaturas atingida.",
      },
      toastError: {
        catactereConsul: "Necessario no minimo 3 caracteres na consulta.",
        semResp: "Existe questões sem resposta.",
        preenchimentoErro: "Preenchimento inválido.",
      },
      treinamentoPainel: {
        iniciarAula: "Iniciar aula!",
        textoIniciar: "Clique em iniciar e bons estudos!",
        iniciar: "Iniciar",
        descVideo: "Descrição do vídeo",
        prova: "Prova de avaliação",
        historico: "Histórico",
        notas: "Histórico de notas",
        nota: "Nota",
        emitirCert: "Emitir Certificado",
        arquivosAnexo: "Arquivos em anexo",
        acertos: "Acertos",
        descricaoAula: "Descrição da aula",
      },
      footer: {
        direitos: "Todos os direitos reservados",
      },
      idioma: {
        idioma: "Idioma",
        portugues: "Português",
        ingles: "Inglês",
        espanhol: "Espanhol",
      },
      relatorio: {
        relatorio1: "Inscritos no módulo",
        relatorio2: "Resultado de provas por módulo",
        relatorio3: "Prova do aluno",
        relatorio4: "Histórico do aluno no módulo",
        relatorio5: "Histórico geral do aluno",
        relatorio6: "Histórico geral do grupo",
        cursoModulo: "Curso / Módulo",
        totalInscritos: "Total de inscritos",
        usuario: "Usuário",
        dataInscricao: "Data inscrição",
        progresso: "Progresso",
        totalTentativa: "Total de tentativas",
        notaAprovacao: "Nota de aprovação",
        media: "Média",
        dataRealizacao: "Data de realização",
        nota: "Nota",
        aprovado: "Aprovado",
        reprovado: "Reprovado",
        mediaAprovacao: "Média de aprovação",
        tentativa: "Tentativa",
        acerto: "Acerto",
        data: "Data",
        alternativaCorreta: "Alternativa correta",
        respostaAluno: "Resposta do aluno",
        grupo: "Grupo",
        modulo: "Módulo",
        curso: "Curso",
      },
      certificado: {
        editor: "Editor de Certificado",
        edicao: "Edição Certificado",
        frente: "Frente",
        verso: "Verso",
        digiteNome: "Dígite um nome",
        header: "Cabeçalho",
        tipo: "Tipo",
        alinharIcon: "Alinhar Icone",
        alinharTexto: "Alinhar Texto",
        titulo: "Título",
        fonte: "Fonte",
        tamanhoFonte: "Tamanho da Fonte",
        conteudo: "Conteúdo",
        descricaoCertificado: "Descrição (Sugestões de variáves @)",
        endereco: "Endereço",
        assinatura: "Assinatura",
        backgroundColor: "Cor do Fundo",
        textColor: "Cor do texto",
        texto: "Texto",
        esquerda: "Esquerda",
        direita: "Direita",
        centro: "Centro",
      },
      iconsTitle: {
        editarCurso: "Editar Curso",
        excluir: "Excluir",
        excluirCurso: "Excluir Curso",
        resetarMod: "Resetar Módulo",
        editarMod: "Editar Módulo",
        excluirMod: "Excluir Módulo",
      },
    },
  },
};

export { messages };
