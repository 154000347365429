import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { i18n } from "../../translate/i18n";
import postMapping from "../../repositories/postMapping";
import { INSCRITOS_MOD } from "../../config/endpoint";

export default function Msg(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    inscricao();
  };

  let teste = 1;
  if (teste === 1) {
    useState.condicional = false;
  } else {
    useState.condicional = true;
  }

  async function inscricao() {
    await postMapping
      .post(
        {
          moduloId: props.moduloId,
        },
        INSCRITOS_MOD
      )
      .then(() => {
        setOpen(false);

        return;
      })
      .catch(() => {
        return;
      });
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {i18n.t("treinamentoPainel.iniciarAula")}
      </DialogTitle>
      <DialogContent>
        <p>{i18n.t("treinamentoPainel.textoIniciar")}</p>
      </DialogContent>

      <Button onClick={handleClick} color="primary" autoFocus>
        {i18n.t("treinamentoPainel.iniciar")}
      </Button>
    </Dialog>
  );
}
