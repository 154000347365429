import React, { useState, useEffect, useContext } from "react";

import { ReactComponent as SVG } from "../img/DrawFigure/influencer.svg";
import { CacheContext } from "../../contexts/cache.context";
import { useHistory, useParams } from "react-router-dom";
import LogoGa from "../../components/img/logo/not.png";
import ReactPlayer from "react-player/youtube";
import { i18n } from "../../translate/i18n";
import useForm from "../../hooks/useForm";
import InputSearch from "../SearchInput";
// import { toast } from "react-toastify";
import Loading from "../Loading";
import "../css/tabela.scss";

let teste = [];
export default function MainWrapper() {
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));
  const { meusCursos, listarMeusCursos, bitmc } = useContext(CacheContext);
  const history = useHistory();
  const { tenant } = useParams();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(meusCursos);
  const buscaNome = {
    tituloCurso: "",
  };

  const handleBusca = () => {
    consul();
  };
  const { handleChange, values } = useForm(buscaNome);

  // const [step, setStep] = useState(1);

  function consul() {
    if (values.tituloCurso !== "") {
      teste = meusCursos.filter(
        (el) =>
          el.titulo.toLowerCase().indexOf(values.tituloCurso.toLowerCase()) > -1
      );
      if (teste.length < 1) {
        setResult([]);
        return;
        // toast.warning(i18n.t("tabela.zeroRegistros"));
      }
      setResult(teste);
    } else {
      setResult(meusCursos);
    }
  }

  const handleClick = (id, titulo) => {
    history.push(`/${tenant}/curso/${id}/${titulo}`);
  };

  // const handleRenderMore = (s) => {
  //   setStep(s);
  //   const container = document.getElementById("render-cursos");

  //   let max = s * 5;
  //   if (max >= container.children.length) {
  //     max = container.children.length;
  //     document.getElementById("ver-mais").classList.add("ver-mais-invisible");
  //   }

  //   container.style.maxHeight = `calc((120px + 10px) * ${max})`;

  //   setTimeout(
  //     () =>
  //       window.scrollTo({
  //         top: container.offsetTop + container.offsetHeight,
  //         behavior: "smooth",
  //       }),
  //     350
  //   );
  // };

  useEffect(() => {
    async function listar() {
      setLoading(true);
      await listarMeusCursos().then((data) => setResult(data));
      setLoading(false);
    }
    if (!bitmc) {
      listar();
    }
  }, []);

  useEffect(() => {
    handleBusca();
  }, [values.tituloCurso]);

  const scrollTop = () => {
    const topCurso = document.getElementById("#cursos-container");
    window.scrollTo({ top: topCurso.offsetTop, behavior: "smooth" });
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     handleBusca();
  //   }
  // };

  return (
    <>
      <div className="container largura-padrao container-header">
        <div className="container-texto">
          <h4 style={{ color: dash.texto01Cor || "" }}>
            {dash.texto01 || "Texto 01"}
          </h4>
          <p style={{ color: dash.texto02Cor || "" }}>
            {dash.texto02 || "Texto 02"}
          </p>
          <button
            style={{
              color: dash.botaoTextoCor || "",
              backgroundColor: dash.botaoCor || "",
            }}
            onClick={scrollTop}
            className="botao-destaque"
          >
            {i18n.t("dashboard.botaoDestaque")}
          </button>
        </div>

        <div className="container-sistemas">
          {dash.urlVideo ? (
            <div id="video-intro-container">
              <ReactPlayer
                url={dash.urlVideo}
                id="video-intro"
                className="react-player"
                controls={false}
              />
            </div>
          ) : (
            <div id="img-intro-container">
              <SVG
                id="svg-into"
                className="react-player"
                fill={dash.imgIntoCor}
                width="100%"
                height="100%"
                alt="img_into"
              />
            </div>
          )}
        </div>
      </div>

      <div
        id="#cursos-container"
        className="largura-padrao"
        style={{ marginTop: 52 }}
      >
        <h5 className="titulo-cursos">Meus Cursos</h5>
        <span title={i18n.t("dashboard.cursosLivres")}>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-4">
              <InputSearch
                name="tituloCurso"
                value={values.tituloCurso}
                // keyPress={handleKeyPress}
                placeholder={i18n.t("consulta.tituloCurso")}
                onChange={handleChange}
                icon="mdi mdi-magnify"
                onClick={() => consul()}
              />
            </div>
          </div>
          {loading && <Loading title="container-loading-login" />}
          {/* <ul id="render-cursos" className="container container-curso">
            {result.length > 0 ? (
              <>
                {result.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleClick(item.id, item.titulo)}
                  >
                    <div className="container-sistemas-card">
                      <div className="card-body">
                        <span
                          className="card-body-after"
                          style={{ backgroundColor: item.cor || "#008288" }}
                        ></span>
                        <div className="card-body-titulo">
                          <div className="logo-curso">
                            <img
                              alt="logo"
                              title="logo"
                              width="50px"
                              src={item.imagem || LogoGa}
                            />
                          </div>
                          <div className="titulo">
                            {[item.titulo].map((titulo, index) => (
                              <span
                                key={index}
                                title={titulo}
                                alt={titulo}
                                className="titulo-curso"
                              >
                                {titulo}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="card-body-descricao">
                          {[item.descricao].map((descricao, index) => (
                            <span
                              key={index}
                              title={descricao}
                              alt={descricao}
                              className="descricao-curso"
                            >
                              {descricao}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <label>{i18n.t("tabela.zeroRegistros")}</label>
            )}
          </ul> */}
          <ul id="render-cursos" className="container container-curso">
            {result.length > 0 ? (
              <>
                {result.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleClick(item.id, item.titulo)}
                  >
                    <div className="container-sistemas-card">
                      <div className="card-body">
                        <div className="card-body-icon">
                          <span
                            className="card-body-after"
                            style={{ backgroundColor: item.cor || "#008288" }}
                          ></span>
                        </div>
                        <img
                          alt="logo"
                          title="logo"
                          width="50px"
                          src={item.imagem || LogoGa}
                        />
                        <div className="card-body-descricao">
                          {[item.titulo].map((titulo, index) => (
                            <span
                              key={index}
                              title={titulo}
                              alt={titulo}
                              className="titulo-curso"
                            >
                              {titulo}
                            </span>
                          ))}
                          {[item.descricao].map((descricao, index) => (
                            <span
                              key={index}
                              title={descricao}
                              alt={descricao}
                              className="descricao-curso"
                            >
                              {descricao}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </>
            ) : (
              <div className="cad-semcursos">
                <span>
                  Nenhum curso com inscrição ativa encontrado, clique em "Mais
                  Cursos" e se inscreva já
                </span>
              </div>
            )}
          </ul>
        </span>
        <div className="card-mais-cursos">
          <button
            onClick={() => history.push(`/${tenant}/maiscursos/buscar`)}
            className="botao-destaque"
          >
            MAIS CURSOS
          </button>
        </div>
        {/* <div
          id="ver-mais"
          className={
            cursosLivres.length > 5 ? "ver-mais-active" : "ver-mais-invisible"
          }
        >
          <span
            className="text-ver-mais"
            onClick={() => handleRenderMore(step + 1)}
          >
            Ver Mais Cursos ( {cursosLivres.length - step * 5} )
          </span>
        </div> */}
      </div>
    </>
  );
}
