import React, { useState } from "react";

import AutoCompleteLabel from "../../../components/AutoComplete";
import deleteMapping from "../../../repositories/deleteMapping";
import ConfirmeDialog from "../../../components/ConfirmeDialog";
import { CardMenu } from "../../../components/MenuCardOptions";
import LogoGa from "../../../components/img/logo/not.png";
import getMapping from "../../../repositories/getMapping";
import ButtonIcon from "../../../components/ButtonIcon";
import PageDefault from "../../../template/DefaultPage";
import FormField from "../../../components/FormField";
import DialogAcesso from "./Dialog/controleAcesso";
import Loading from "../../../components/Loading";
import DialogInscritos from "./Dialog/inscritos";
import "../../../components/css/styleguide.scss";
import { i18n } from "../../../translate/i18n";
import DialogCurso from "./Dialog/novo";
import { toast } from "react-toastify";

let cursoInicial = {
  id: "",
  titulo: "",
  descricao: "",
  cor: "",
  imagem: "",
  status: "",
  certificadoId: "",
  certificadoNome: "",
  hotmartId: "",
  publicado: false,
};
export default function CursoLista() {
  const [cursos, setCursos] = useState([]);
  const [curso, setCurso] = useState(cursoInicial);
  const [loading, setLoading] = useState(false);
  const [openDialogCurso, setOpenDialogCurso] = useState(false);
  const [openDialogAcesso, setOpenDialogAcesso] = useState(false);
  const [openDialogInscritos, setOpenDialogInscritos] = useState(false);
  const [cursoTitulo, setCursoTitulo] = useState("");
  const [filtro, setFiltro] = useState(changeFiltro("1", false));
  const [opcoes, setOpcoes] = useState(null);

  function changeFiltro(e, type) {
    if (type) {
      switch (e) {
        case i18n.t("consulta.cursosDisp"):
          return "1";

        case i18n.t("consulta.cursosExcl"):
          return "0";
        default:
          return "0";
      }
    } else {
      switch (e) {
        case "0":
          return i18n.t("consulta.cursosExcl");
        case "1":
          return i18n.t("consulta.cursosDisp");
        default:
          return i18n.t("consulta.cursosExcl");
      }
    }
  }

  const [dialogConfirm, setDialogConfirm] = useState(false);

  const handleBusca = () => {
    listar();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBusca();
    }
  };

  const options = [
    i18n.t("consulta.cursosDisp"),
    i18n.t("consulta.cursosExcl"),
  ];

  async function listar() {
    let codFiltro = changeFiltro(filtro, true);

    if (cursoTitulo !== "") {
      if (cursoTitulo.length >= 3) {
        setLoading(true);
        await getMapping
          .get(
            `/cursos/status/${codFiltro}/${cursoTitulo
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}`
          )
          .then((data) => {
            if (data.length < 1) {
              toast.warning(i18n.t("toastWarning.zeroCurso"));
            }
            setCursos(data);
            setLoading(false);
          })
          .catch((err) => {
            return;
          });
      } else {
        toast.error(i18n.t("toastError.catactereConsul"));
      }
    } else {
      setLoading(true);
      await getMapping
        .get(`/cursos/status/${codFiltro}/all`)
        .then((data) => {
          if (data.length < 1) {
            toast.warning(i18n.t("toastWarning.zeroCurso"));
          }
          setCursos(data);
          setLoading(false);
        })
        .catch((err) => {
          return;
        });
    }
  }

  const handleDelete = () => {
    deleteMapping
      .deletar(`/cursos/${curso.id}`)
      .then(() => {
        setDialogConfirm(false);
        listar();
        toast.success(i18n.t("toastSuccess.cursoDel"));
      })
      .catch((e) => toast.error(e.message));
  };

  const handleClick = () => {
    setCurso(cursoInicial);
    setOpenDialogCurso(true);
  };

  return (
    <PageDefault menuLateral>
      <div
        className="largura-padrao"
        onClick={() => {
          if (opcoes !== null) {
            setOpcoes(null);
          }
        }}
      >
        <h5 className="titulo-pages tr-mb-5">
          <span>{i18n.t("menu.cadCurso")}</span>
        </h5>
        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-3">
              <FormField
                style={{ height: 56 }}
                onChange={(e) => setCursoTitulo(e.target.value)}
                onKeyPress={handleKeyPress}
                name="tituloCurso"
                placeholder={i18n.t("consulta.tituloCurso")}
                value={cursoTitulo}
                maxLength="30"
                label=""
              />
            </div>
            <div className="tr-form-group tr-col-sm-3">
              <AutoCompleteLabel
                id="curso"
                options={options}
                value={filtro}
                onChangeText={setFiltro}
                label={i18n.t("formulario.status")}
                textFieldId="labelFiltro"
                clearable
              />
            </div>
            <div className="tr-col"></div>
            <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
              <div className="tr-mr-4">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={handleBusca}
                />
              </div>
              <div>
                <ButtonIcon
                  value={i18n.t("button.novo")}
                  icon="mdi mdi-file icon-mr-12"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="table-container">
          {loading && <Loading title="container-loading-login" />}
          <table>
            {/* <TableHead /> */}
            <thead>
              <tr className="tHead">
                <th className="text-center">{i18n.t("tabela.codigo")}</th>
                <th>{i18n.t("formulario.titulo")}</th>
                <th className="text-center">{i18n.t("formulario.icone")}</th>
                {/* <th className="text-center">{i18n.t("tabela.inscritos")}</th> */}
                <th className="text-center">{i18n.t("tabela.opcoes")}</th>
              </tr>
            </thead>
            {/* <TableBody /> */}
            <tbody>
              {cursos.map((item, index) => (
                <tr key={index}>
                  <td data-title="Código" className="text-center">
                    <span key={index}>{item.id}</span>
                  </td>

                  <td data-title="Titulo">
                    <span key={index}>{item.titulo}</span>
                  </td>

                  <td data-title="Imagem" className="text-center">
                    <img
                      key={index}
                      width="50px"
                      src={item.imagem || LogoGa}
                      alt=""
                    />
                  </td>
                  {/* <td data-title="inscritos" className="text-center">
              {[item.inscritos].map((texto, index) => (
                <span key={index}>{texto + "  "}</span>
              ))}
              <i
                className="mdi mdi-account-group-outline icon-size icon-green"
                onClick={() => {
                  setCurso({
                    id: item.id,
                    titulo: item.titulo,
                  });
                  setOpenDialogInscritos(true);
                }}
              ></i>
              <br />
            </td> */}

                  <td data-title="Ações" className="text-center">
                    <div>
                      <a style={{ cursor: "pointer" }}>
                        <i
                          style={{ fontSize: "1.8em" }}
                          className="mdi mdi-chevron-down"
                          onClick={() => setOpcoes(index)}
                        />
                      </a>

                      <CardMenu open={opcoes === index} width="150px">
                        <a
                          onClick={() => {
                            setCurso({
                              id: item.id,
                              titulo: item.titulo,
                            });
                            setOpenDialogAcesso(true);
                          }}
                        >
                          <i
                            style={{ color: "#a59900" }}
                            className="mdi mdi-account-key-outline icon-size edit-table icon-mr-12"
                          ></i>
                          Controle de Acesso
                        </a>

                        <a
                          onClick={() => {
                            setCurso({
                              id: item.id,
                              titulo: item.titulo,
                              descricao: item.descricao,
                              cor: item.cor,
                              status: item.status,
                              certificadoNome: item.certificadoNome,
                              certificadoId: item.certificadoId,
                              hotmartId: item.hotmartId || "",
                              publicado: item.publicado,
                              pagamentoURL: item.pagamentoURL,
                              valor: item.valor,
                            });
                            setOpenDialogCurso(true);
                          }}
                        >
                          <i
                            title={i18n.t("iconsTitle.editarCurso")}
                            className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"
                          ></i>
                          Editar
                        </a>

                        <a
                          onClick={() => {
                            setDialogConfirm(true);
                            setCurso((prevState) => ({
                              ...prevState,
                              id: item.id,
                            }));
                          }}
                        >
                          <i
                            title={i18n.t("iconsTitle.excluirCurso")}
                            className="mdi mdi-trash-can-outline icon-size delete-table "
                          ></i>
                          Excluir
                        </a>
                      </CardMenu>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <DialogCurso
          data={curso}
          onClose={() => setOpenDialogCurso(false)}
          open={openDialogCurso}
          listar={listar}
        />

        <DialogInscritos
          data={curso}
          onClose={() => setOpenDialogInscritos(false)}
          open={openDialogInscritos}
          listar={listar}
        />

        <DialogAcesso
          curso={curso}
          onClose={() => setOpenDialogAcesso(false)}
          open={openDialogAcesso}
          listar={listar}
        />

        <ConfirmeDialog
          title="Excluir"
          description="Deseja realmente excluir esse item?"
          open={dialogConfirm}
          confirm={handleDelete}
          onClose={() => setDialogConfirm(false)}
        />
      </div>
    </PageDefault>
  );
}
