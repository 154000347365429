import React, { useContext } from "react";

import { AuthContext } from "../../contexts/auth.context";
import { AulaContext } from "../../contexts/aula.context";
import { Link, useParams } from "react-router-dom";
import Button2 from "../ButtonLink/ButtonLink2";
import { i18n } from "../../translate/i18n";
import Menu from "@material-ui/core/Menu";
import Button from "../ButtonLink";

export default function MenuDefault() {
  const { checkPermission } = useContext(AuthContext);
  const { tituloVideo } = useContext(AulaContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tenant } = useParams();
  const usuario = JSON.parse(sessionStorage.getItem("user"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    sessionStorage.clear();
  }

  return (
    <nav className="nav-menu nav-video">
      <div className="nav-menu-body largura-padrao">
        <div className="holder">
          <h3 className="nav-titulo-video">
            <i
              className="mdi mdi-television-play"
              style={{ marginRight: "12px" }}
            />
            <span className="title-video-page">{tituloVideo}</span>
          </h3>
        </div>
        <div className="opcoes">
          <Button
            id="ButtonName"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <p>
              {i18n.t("menu.ola")}, {usuario.nome}{" "}
              <i className="mdi mdi-chevron-down" />
            </p>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Button2 as={Link} to={`/${tenant}/perfil/usuario`}>
              <i className="mdi mdi-account-details" />
              {i18n.t("menu.perfil")}
            </Button2>
            <Button2 as={Link} to={`/${tenant}/maiscursos/buscar`}>
              <i className="mdi mdi-notebook-check" />
              Mais Cursos
            </Button2>

            {checkPermission() && (
              <Button2 as={Link} to={`/${tenant}/cadastro/curso`}>
                <i className="mdi mdi-view-dashboard" />
                Portal Administrador
              </Button2>
            )}

            <Button2 as={Link} to={`/${tenant}/login`} onClick={logout}>
              <i className="mdi mdi-logout" />
              {i18n.t("menu.sair")}
            </Button2>
          </Menu>
        </div>
      </div>
    </nav>
  );
}
