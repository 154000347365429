import URL_BACKEND from "../config/back-end"

function deletar(api) {
    return new Promise((resolver, reject) => {
      fetch(`${URL_BACKEND}${api}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
        },
      })
        .then(async (response) => {
          if (response.ok) {
            resolver(true);
          } else if (response.status === 403) {
            sessionStorage.clear();
            window.location.reload();
          } else {
            const res = await response.json();
    
            reject(new Error(res.codigo + " : " + res.mensagem));
          }
        })
        .catch((err) => reject(err));
    });
  }

  export default { deletar }