import React from "react";
import NunitoRegular from "../../components/fonts/Nunito-Regular.ttf";
import NunitoBold from "../../components/fonts/Nunito-Bold.ttf";
import {
  PDFViewer,
  Page,
  Document,
  StyleSheet,
  View,
  Image,
  Text,
  Font,
} from "@react-pdf/renderer";

let tituloRelatorio = "";
export function Titulo(e) {
  tituloRelatorio = e;
}
let data = new Date();
let dia = String(data.getDate()).padStart(2, "0");
let mes = String(data.getMonth() + 1).padStart(2, "0");
let ano = data.getFullYear();
let dataAtual = dia + "/" + mes + "/" + ano;

export default function Relatorio({ children }) {
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));
  Font.register({
    family: "Nunito",
    fonts: [
      {
        src: NunitoRegular,
      },
      {
        src: NunitoBold,
        fontWeight: "bold",
      },
    ],
  });

  return (
    <PDFViewer style={{ height: 800, width: "100%" }}>
      <Document>
        <Page size="A4" style={styles.page} wrap={false}>
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              <Image src={dash.logo} />
            </View>
            <Text style={styles.titulo}>{tituloRelatorio}</Text>
            <Text style={styles.data}>{dataAtual}</Text>
          </View>
          {children}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    paddingTop: 30,
    paddingBottom: 30,
  },

  header: {
    width: "auto",
    height: 60,
    justifyContent: "space-between",
    flexDirection: "row",
    marginHorizontal: 20,
    alignItems: "flex-start",
  },
  data: {
    fontSize: 8,
    color: "#4D4D4D",
  },
  logoContainer: {
    backgroundRepeat: "no-repeat",
    width: 60,
    height: 60,
  },
  titulo: {
    fontSize: 15,
    color: "#000",
    fontFamily: "Nunito",
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 10,
    marginHorizontal: 25,
    textAlign: "right",
    color: "grey",
  },
});
