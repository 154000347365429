import React, { useState, useEffect } from "react";
import FormField from "../../../../components/FormField";
import Button from "../../../../components/Button";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../../translate/i18n";
import postMapping from "../../../../repositories/postMapping";
import putMapping from "../../../../repositories/putMapping";
import AutoCompleteLabel from "../../../../components/AutoComplete";
import Dialog from "../../../../components/Dialog";
import { moduloInicial } from "../../Modulo";
import { TextSize } from "../../../../js/index";
import getMapping from "../../../../repositories/getMapping";

export default function CadastroModulo({ data, open, listar, onClose }) {
  const options = [i18n.t("button.sim"), i18n.t("button.nao")];

  const [modulo, setModulo] = useState(data);
  const [cursos, setCursos] = useState([]);
  const [instrutores, setInstrutores] = useState([]);

  const [modulos, setModulos] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    getCursos();
    getModulos();
    getInstrutor();
  }, []);
  useEffect(() => {
    setModulo(data);
  }, [open]);

  async function getCursos() {
    await getMapping.get("/cursos/autocomplete").then((cursos) => {
      setCursos(cursos);
    });
  }
  async function getModulos() {
    await getMapping.get(`/modulos/${0}`).then((modulos) => {
      setModulos(modulos);
    });
  }
  async function getInstrutor() {
    await getMapping.get(`/instrutor/all`).then((instrutores) => {
      setInstrutores(instrutores);
    });
  }

  async function handleSubmit() {
    const selecAvaliacao = modulo.avaliacao === i18n.t("button.sim");

    const selectCancelado = modulo.cancelado === i18n.t("button.nao");

    const chosenCurso = cursos.find((curso) => {
      return curso.titulo === modulo.cursoTitulo;
    });

    const chosenInstrutor = instrutores.find((instrutor) => {
      return instrutor.nome === modulo.instrutorNome;
    });

    const chosenModulo = modulos.find((e) => {
      return e.cursoTitulo + ": " + e.titulo === modulo.nomeRequisito;
    });

    let cursoId;
    let instrutorId;
    let requisitoId;

    let errorList = [];

    if (chosenCurso !== undefined) {
      cursoId = chosenCurso.id;
    } else {
      cursoId = modulo.cursoId;
    }

    if (chosenInstrutor !== undefined) {
      instrutorId = chosenInstrutor.id;
    } else {
      instrutorId = modulo.instrutorId;
    }

    if (chosenModulo !== undefined) {
      requisitoId = chosenModulo.id;
    } else {
      requisitoId = null;
    }

    //validações
    if (!modulo.titulo.trim()) {
      errorList.push("Insira um título válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (modulo.minutos < 0) {
      errorList.push("Insira uma duração estimada válida!");
      setErrorMessages(errorList);
      setModulo((prevState) => ({
        ...prevState,
        minutos: 0,
      }));

      setIserror(true);
    }
    if (!modulo.descricao.trim()) {
      errorList.push("Insira uma descrição válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!modulo.cursoTitulo.trim()) {
      errorList.push("Insira um curso!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!modulo.instrutorNome.trim()) {
      errorList.push("Insira um instrutor válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (errorList.length < 1) {
      //cadastrar
      if (modulo.id === "") {
        await postMapping
          .post(
            {
              titulo: modulo.titulo,
              descricao: modulo.descricao,
              cursoId: cursoId,
              instrutorId: instrutorId,
              requisito_modulo: requisitoId,
              cancelado: selectCancelado,
              avaliacao: selecAvaliacao,
              minutos: modulo.minutos,
            },
            "/modulos"
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.moduloCad"));
            setModulo(moduloInicial);
            setIserror(false);
          })
          .catch((e) => toast.error(e.message));
      } else {
        await putMapping
          .put(
            {
              id: modulo.id,
              titulo: modulo.titulo,
              descricao: modulo.descricao,
              cursoId: cursoId,
              instrutorId: instrutorId,
              requisito_modulo: requisitoId,
              cancelado: selectCancelado,
              avaliacao: selecAvaliacao,
              minutos: modulo.minutos,
            },
            `/modulos/${modulo.id}`
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.moduloAtt"));
            setModulo(moduloInicial);
            setIserror(false);
            listar();
            onClose();
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={i18n.t("menu.cadModulo")}
    >
      <div className="form-cadastros">
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="form-body">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <FormField
                label={i18n.t("formulario.titulo")}
                name="titulo"
                maxLength="100"
                value={modulo.titulo}
                onChange={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    titulo: e.target.value,
                  }))
                }
              />
              <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                {TextSize(modulo.titulo)}/100
              </p>
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <FormField
                label={i18n.t("formulario.descricao")}
                name="descricao"
                type="textarea"
                maxLength="150"
                value={modulo.descricao}
                onChange={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    descricao: e.target.value,
                  }))
                }
              />
              <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                {TextSize(modulo.descricao)}/150
              </p>
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-6">
              <AutoCompleteLabel
                id="id_curso"
                options={cursos.map(({ titulo }) => titulo)}
                value={modulo.cursoTitulo}
                onChangeText={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    cursoTitulo: e,
                  }))
                }
                label={i18n.t("formulario.curso")}
                textFieldId="labelCurso"
                labelavel
                clearable
              />
            </div>
            <div className="tr-form-group tr-col-sm-6">
              <AutoCompleteLabel
                id="id_moduloReq"
                options={modulos.map(({ titulo, cursoTitulo }) =>
                  cursoTitulo.concat(": " + titulo)
                )}
                value={modulo.nomeRequisito}
                onChangeText={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    nomeRequisito: e,
                  }))
                }
                label={i18n.t("formulario.preRequisito")}
                textFieldId="labelModuloReq"
                labelavel
              />
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm">
              <AutoCompleteLabel
                id="id_instrutor"
                options={instrutores.map(({ nome }) => nome)}
                value={modulo.instrutorNome}
                onChangeText={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    instrutorNome: e,
                  }))
                }
                label={i18n.t("formulario.instrutor")}
                textFieldId="labelInstrutor"
                clearable
                labelavel
              />
            </div>
            <div className="tr-form-group tr-col-sm dialog-box-input">
              <label>{i18n.t("formulario.tempoEstimado")}</label>
              <input
                name="minutos"
                type="number"
                min={0}
                value={modulo.minutos}
                onChange={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    minutos: Number(e.target.value),
                  }))
                }
              />
            </div>
            <div className="tr-form-group tr-col-sm">
              <AutoCompleteLabel
                id="avaliacao"
                options={options}
                value={
                  modulo.avaliacao
                    ? `${i18n.t("button.sim")}`
                    : `${i18n.t("button.nao")}`
                }
                onChangeText={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    avaliacao: e,
                  }))
                }
                label={i18n.t("treinamentoPainel.prova")}
                textFieldId="labelModuloReq"
                labelavel
                clearable
              />
            </div>
            <div className="tr-form-group tr-col-sm">
              <AutoCompleteLabel
                id="cancelado"
                options={options}
                value={
                  modulo.cancelado
                    ? `${i18n.t("button.nao")}`
                    : `${i18n.t("button.sim")}`
                }
                onChangeText={(e) =>
                  setModulo((prevState) => ({
                    ...prevState,
                    cancelado: e,
                  }))
                }
                label={i18n.t("formulario.ativo")}
                textFieldId="labelModuloReq"
                labelavel
                clearable
              />
            </div>
          </div>
          <Button onClick={handleSubmit}>{i18n.t("button.salvar")}</Button>
        </div>
      </div>
    </Dialog>
  );
}
