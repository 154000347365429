import React, { useState, useEffect } from "react";
import getMapping from "../../repositories/getMapping";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Relatorio, { Titulo } from ".";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import NunitoLight from "../../components/fonts/Nunito-Light.ttf";
import NunitoBold from "../../components/fonts/Nunito-Bold.ttf";
import NunitoRegular from "../../components/fonts/Nunito-Regular.ttf";
import ButtonIcon from "../ButtonIcon";
import { StyledBoxButton } from "./styles";
import AutoCompleteLabel from "../AutoComplete";


export default function HistoricoAlunoModulo() {
    const [cursoModulo, setCursoModulo] = useState([]);
    const [openExport, setOpenExport] = useState(false);
    const [usuarios, setUsuario] = useState([]);
    const [historico, setHistorico] = useState([]);
    const [media, setMedia] = useState();
    const [filtro, setFiltro] = useState({
        usuario: "",
        modulo: ""
    });


    useEffect(() => {
        listarCursoModulo();
        listarUsuario();
        Titulo(i18n.t("relatorio.relatorio4"))
    }, []);

    Font.register({
        family: "Nunito", fonts: [
            {
                src: NunitoRegular
            },
            {
                src: NunitoBold,
                fontWeight: 'bold'
            },
            {
                src: NunitoLight,
                fontWeight: 'light'
            }

        ]
    });

    function listarCursoModulo() {
        getMapping.get(`/modulos/curso/1`)
            .then((data) => {
                setCursoModulo(data)
            })
            .catch(() => { return; });
    }
    function listarUsuario() {
        getMapping.get(`/usuarios/listar`)
            .then((data) => {
                setUsuario(data);

            })
            .catch(() => { return; });
    }

    function listarHistorico() {
        const chosenModulo = cursoModulo.find((modulo) => {
            return modulo.cursoTitulo + " / " + modulo.titulo === filtro.modulo;
        });
        const chosenUsuario = usuarios.find((usuario) => {
            return usuario.nome + " - " + usuario.email === filtro.usuario;
        })
        if (chosenModulo !== undefined && chosenUsuario !== undefined) {
 
            getMapping.get(`/nota/modulos/${chosenModulo.id}/${chosenUsuario.id}`)
                .then((data) => {
                   
                    if (data.length < 1) {
                        toast.warning(i18n.t("toastWarning.zeroConsulta"))
                    } else {
                        setHistorico(data);
                        setMedia(data[0].media)
                        setOpenExport(true)

                    }
                })
        } else {
            toast.error(i18n.t("toastError.preenchimentoErro"))
        }

    }

    function DialogExport() {

        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={openExport}
                fullWidth={true}
                scroll={"paper"}
            >
                <div className="header-dialog">
                    <div className="header-dialog-title">{i18n.t("consulta.relatorio")}</div>
                    <div className="header-dialog-close" onClick={() => setOpenExport(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <Relatorio >
                    <View style={styles.container}>

                        <View style={styles.areaTexto}>
                            <Text style={styles.texto}>{i18n.t("relatorio.usuario")}:  {filtro.usuario}</Text>
                        </View>

                        <View style={styles.areaTexto}>
                            <Text style={styles.texto}>{i18n.t("relatorio.cursoModulo")}:  {filtro.modulo}</Text>
                        </View>
                        <View style={styles.areaTexto}>
                            <Text style={styles.texto}>{i18n.t("relatorio.mediaAprovacao")}:  {media}</Text>
                        </View>


                        <View style={styles.areaTable}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <View style={{ height: 10, width: 10, backgroundColor: '#EFD0D0', paddingRight: 5 }} />
                                <Text style={styles.textoLegenda}>{i18n.t("relatorio.reprovado")}</Text>
                                <View style={{ height: 10, width: 10, backgroundColor: '#D8F2D1', paddingRight: 5 }} />
                                <Text style={styles.textoLegenda}>{i18n.t("relatorio.aprovado")}</Text>

                            </View>
                            <View style={styles.headerTable}>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.tentativa")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.nota")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.acerto")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.data")}</Text>
                                </View>

                            </View>
                            {historico.map((item, index) => (

                                <View key={index} style={[styles.bodyTable, {
                                    backgroundColor: item.aprovado ? "#D8F2D1" : "#EFD0D0"
                                }]
                                }>

                                    {[index].map((texto, index) => (
                                        <View key={index} style={styles.column}>
                                            <Text style={styles.textoBody}>{texto + 1}°</Text>
                                        </View>
                                    ))}

                                    {[item.nota].map((texto, index) => (
                                        <View key={index} style={styles.column}>
                                            <Text style={styles.textoBody}>{texto}</Text>
                                        </View>
                                    ))}
                                    {[item.acertos].map((texto, index) => (
                                        <View key={index} style={styles.column}>
                                            <Text style={styles.textoBody}>{texto + "/" + historico[index].perguntas}</Text>
                                        </View>
                                    ))}
                                    {[item.provaUsuarioId.data].map((texto, index) => (
                                        <View key={index} style={styles.column}>
                                            <Text style={styles.textoBody}>{texto}</Text>
                                        </View>
                                    ))}

                                </View>

                            ))}
                        </View>
                    </View>
                </Relatorio>
            </Dialog>
        );
    }

    return (
        <div>
            <div className="tr-row">
                <div className="tr-form-group tr-col-sm-4">
                    <br />
                    <AutoCompleteLabel
                        id="curso-modulo"
                        options={cursoModulo.map(({ titulo, cursoTitulo }) =>
                            cursoTitulo.concat(" / " + titulo)
                        )}
                        value={filtro.modulo}
                        onChangeText={e => setFiltro(prevState => ({
                            ...prevState,
                            modulo: e
                        }))}
                        label={i18n.t("consulta.selecioneCurs") + "/módulo"}
                        textFieldId="labelSelectCurso"
                    // labelavel
                    />
                   
                </div>
                <div className="tr-form-group tr-col-sm-4">
                    <br />
                    <AutoCompleteLabel
                        id="curso-modulo"
                        options={usuarios.map(({ nome, email }) =>
                            nome.concat(" - " + email)
                        )}
                        value={filtro.usuario}
                        onChangeText={e => setFiltro(prevState => ({
                            ...prevState,
                            usuario: e
                        }))}
                        label={i18n.t("tabela.nomeEmail")}
                        textFieldId="labelSelectCurso"
                    // labelavel
                    />
                    
                </div>
                <div className="tr-form-group tr-col-sm-2">
                    <StyledBoxButton>
                        <ButtonIcon
                            value={i18n.t("button.gerar")}
                            icon="mdi mdi-text-search icon-mr-12"
                            onClick={() => listarHistorico()}
                        />
                    </StyledBoxButton>

                </div>

            </div>
            <Divider />

            <DialogExport />
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 25,
    },
    areaTexto: {
        paddingVertical: 2
    },
    texto: {
        fontSize: 12,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
        fontWeight: 'bold'
    },
    areaTable: {
        paddingTop: 15,
        flexDirection: 'column',
    },
    headerTable: {
        backgroundColor: '#2d71cc',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    textoHeader: {
        fontSize: 12,
        color: '#fff',
        fontFamily: 'Nunito',
        fontWeight: 'bold'
    },
    textoBody: {
        fontSize: 11,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
        fontWeight: 'light'
    },
    bodyTable: {
        flexDirection: 'row',
        paddingTop: 10,
        borderWidth: 0.2,
        borderColor: '#fff'


    },
    column: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textoLegenda: {
        fontSize: 9,
        padding: 5,
        fontFamily: 'Nunito',
        color: '#4D4D4D',
    }

})