import URL_BACKEND from "../config/back-end"

function get(api) {
  return fetch(`${URL_BACKEND}${api}`, {
    method: "GET",

    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
    },
  })
    .then(async (response) => {
      if (response.ok) {
        const res = await response.json();
        return res;
      } else if (response.status === 403) {
        sessionStorage.clear();
        window.location.reload();
      }
    })
    .catch((error) => {
      return error;
    });
}

function getFree(api) {
  return fetch(`${URL_BACKEND}${api}`, {
    method: "GET",

    headers: {
      "Content-type": "application/json",
    },
  })
    .then(async (response) => {
  
      if (response.ok) {
        const res = await response.json();
        return res;
      } else if (response.status === 403) {
       
        sessionStorage.clear();
        window.location.reload();

      } else if (response.status === 400) {
      
         return 0;
      }
    })
    .catch(() => {
      return;
    });
}

export default { get, getFree };