import React, { useState } from "react";
import "../../../../components/FormField";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../../translate/i18n";
import postMapping from "../../../../repositories/postMapping";
import putMapping from "../../../../repositories/putMapping";
import Dialog from "../../../../components/Dialog";

export default function FormConfigProva(props) {
  const [prova, setProva] = useState({
    id: props.provaSalva.id,
    moduloId: props.moduloId,
    media: props.provaSalva.media,
    total: props.provaSalva.total,
  });

  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  function selectMedia() {
    const media = document.getElementById("options").value;
    setProva((prevState) => ({
      ...prevState,
      media: media,
    }));
  }

  async function handleSubmit() {
    let errorList = [];

    //verificações

    if (prova.media === null) {
      errorList.push("Selecione a média!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (prova.total === "" || prova.total < 1) {
      errorList.push("Insira um numero inteiro válido");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      //atualizar
      if (props.provaSalva.id) {
        await putMapping
          .put(
            {
              id: props.provaSalva.id,
              titulo: "",
              moduloId: prova.moduloId,
              media: prova.media,
              total: Number(prova.total),
              cancelado: false,
            },
            `/prova/${props.provaSalva.id}`
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.provaAtt"));
            setIserror(false);
            props.onClose();
          })
          .catch((e) => toast.error(e.message));
      } else {
        await postMapping
          .post(
            {
              titulo: "",
              moduloId: prova.moduloId,
              media: prova.media,
              total: prova.total,
              cancelado: false,
            },
            "/prova"
          )
          .then(() => {
            props.listarProva();
            setIserror(false);
            props.onClose();
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={() => props.onClose()}
      open={props.dialog === 1 ? true : false}
      title="Configurações da avaliação"
    >
      <div className="container-dialog">
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <span style={{ fontSize: 14 }}>
              *Ordem de perguntas e sequencia de alternativas será renderizada
              randomicamente no momento da prova.{" "}
            </span>
          </div>
        </div>
        <div className="form-cadastros">
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          <div className="form-body">
            <div className="tr-row">
              <div className="tr-form-group tr-col-sm-6">
                <label>{i18n.t("formulario.mediaaprovacao")}</label>
                <select
                  id="options"
                  className="select-input-option-hiden"
                  onChange={selectMedia}
                >
                  <option value={prova.media}>{prova.media}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>

              <div className="tr-form-group tr-col-sm-6">
                <label>{i18n.t("tabela.perguntaProva")}</label>
                <input
                  name="total"
                  value={prova.total}
                  onChange={(e) =>
                    setProva((prevState) => ({
                      ...prevState,
                      total: e.target.value,
                    }))
                  }
                  placeholder="10"
                  type="number"
                />
              </div>
            </div>
            <button onClick={handleSubmit}>{i18n.t("button.salvar")}</button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
