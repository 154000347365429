import styled from "styled-components";

const Main = styled.main`
  background-color:white;
  color: white;
  flex: 1;
  padding-top: 50px;
  padding-left: 5%;
  padding-right: 5%;
`;

export default Main;
