import React, { useState } from "react";
import "../../../components/css/styleguide.scss";
import { toast } from "react-toastify";
import FormPergunta from "./Dialog/formPergunta";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Conditional from "../../../components/Operators/Conditional";
import { i18n } from "../../../translate/i18n";
import deleteMapping from "../../../repositories/deleteMapping";
import FormConfigProva from "./Dialog/formConfigProva";

export default function Avaliacao(props) {
  const [dialog, setDialog] = useState(0);
  const [id, setId] = useState("");
  const [pergunta, setPergunta] = useState("");
  const [alt1, setAlt1] = useState("");
  const [alt2, setAlt2] = useState("");
  const [alt3, setAlt3] = useState("");
  const [alt4, setAlt4] = useState("");
  const [resposta, setResposta] = useState("");
  const [edit, setEdit] = useState(false);

  const handleOpen = (e) => {
    if (e === 2) {
      setId("");
      setPergunta("");
      setAlt1("");
      setAlt2("");
      setAlt3("");
      setAlt4("");
      setResposta("");
    }
    setDialog(e);
  };

  const handleClose = () => {
    setEdit(false);
    props.listarProva();
    setDialog(0);
  };

  const handleEdita = (e) => {
    if (e === 1) {
      setEdit(true);
    }
    setDialog(e);
  };

  const initialData = {
    id: id,
    pergunta: pergunta,
    alt1: alt1,
    alt2: alt2,
    alt3: alt3,
    alt4: alt4,
    resposta: resposta,
    provaId: props.prova !== null ? props.prova.id : "",
  };

  const handleDelete = (id) => {
    deleteMapping
      .deletar(`/provapergunta/${id}`)
      .then(() => {
        props.listarProva();
        toast.success(i18n.t("toastSuccess.perguntaDel"));
      })
      .catch((e) => toast.error(e.message));
  };

  function CriarProva() {
    return (
      <div className="tr-row">
        <div className="tr-col-sm-12">
          <button
            onClick={() => {
              handleOpen(1);
            }}
          >
            <i className="mdi mdi-cog icon-mr-12" />
            Criar prova
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: 10 }}>
      <FormConfigProva
        moduloId={props.moduloId}
        onClose={() => handleClose()}
        dialog={dialog}
        edit={edit}
        listarProva={props.listarProva}
        provaSalva={
          props.prova !== null
            ? {
                id: props.prova.id,
                media: props.prova.media,
                total: props.prova.total,
              }
            : { id: "", media: "", total: "" }
        }
      />

      <FormPergunta
        initialData={initialData}
        onClose={() => handleClose()}
        dialog={dialog}
      />

      {props.prova === null ? (
        <CriarProva />
      ) : (
        <>
          <div className="tr-row">
            <div className="tr-col-sm-1 tr-display-flex tr-align-itens">
              <i
                className="mdi mdi-cog icon-mr-12 icon-size-md"
                onClick={() => {
                  handleOpen(1);
                }}
              />
            </div>

            <div className="tr-col-sm-11">
              <button onClick={() => handleOpen(2)}>
                <i className="mdi mdi-file icon-mr-12" />
                Nova Pergunta
              </button>
            </div>
          </div>
          <div className="list">
            <div className="tr-row">
              {props.prova !== null &&
                props.prova.perguntas.map((item, index) => (
                  <>
                    <div
                      className="tr-col-12"
                      key={item.id}
                      style={{ width: "100%", whiteSpace: "break-spaces" }}
                    >
                      <div className="perguntas">
                        <List>
                          {[item.pergunta].map((texto, e) => (
                            <ListItem key={e}>
                              <ListItemText
                                style={{ wordBreak: "break-word" }}
                                key={e}
                                primary={index + 1 + ". " + texto}
                                id="resposta"
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                      <div className="alternativas">
                        <List>
                          {[item.alt1].map((texto, index) => (
                            <ListItem key={index}>
                              <Conditional
                                test={item.resposta === 1}
                                onSuccess={
                                  <i className="mdi mdi-check icon-question-true" />
                                }
                                onFailue={<div> </div>}
                              />
                              <ListItemText
                                style={{ wordBreak: "break-word" }}
                                secondary={"1)  " + texto}
                                id="alt1"
                              />
                            </ListItem>
                          ))}

                          {[item.alt2].map((texto, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                style={{ wordBreak: "break-word" }}
                                secondary={"2)  " + texto}
                                id="alt2"
                              />
                              <Conditional
                                test={item.resposta === 2}
                                onSuccess={
                                  <i className="mdi mdi-check icon-question-true" />
                                }
                                onFailue={<div> </div>}
                              />
                            </ListItem>
                          ))}

                          {[item.alt3].map((texto, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                style={{ wordBreak: "break-word" }}
                                secondary={"3)  " + texto}
                                id="alt3"
                              />
                              <Conditional
                                test={item.resposta === 3}
                                onSuccess={
                                  <i className="mdi mdi-check icon-question-true" />
                                }
                                onFailue={<div> </div>}
                              />
                            </ListItem>
                          ))}

                          {[item.alt4].map((texto, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                style={{ wordBreak: "break-word" }}
                                secondary={"4)  " + texto}
                                id="alt4"
                              />
                              <Conditional
                                test={item.resposta === 4}
                                onSuccess={
                                  <i
                                    key={`${index}.3`}
                                    className="mdi mdi-check icon-question-true"
                                  />
                                }
                                onFailue={<div> </div>}
                              />
                            </ListItem>
                          ))}
                        </List>
                        <div className="tr-row">
                          <div
                            className="tr-col-12 tr-form-group tr-display-flex"
                            style={{ justifyContent: "center" }}
                          >
                            <i
                              className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"
                              onClick={() => {
                                setId(item.id);
                                setPergunta(item.pergunta);
                                setAlt1(item.alt1);
                                setAlt2(item.alt2);
                                setAlt3(item.alt3);
                                setAlt4(item.alt4);
                                setResposta(item.resposta);
                                handleEdita(2);
                              }}
                            ></i>
                            <i
                              className="mdi mdi-trash-can-outline icon-size delete-table"
                              onClick={() => {
                                handleDelete(item.id);
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
