import URL_BACKEND from "../../config/back-end";

const URL_EMPRESA = `${URL_BACKEND}/empresa`;
const URL_DASHBOARD = `${URL_BACKEND}/dashboard`;
const myHeadersCreate = new Headers({
  "Content-type": "application/json",
});

function cadastrarEmpresa(object) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_EMPRESA}`, {
      method: "POST",
      headers: myHeadersCreate,
      body: JSON.stringify(object),
    })
      .then(async (response) => {
        if (response.ok) {
          resolver(true);
        } else {
          const res = await response.json();

          reject(new Error(res.codigo + " : " + res.mensagem));
        }
      })
      .catch((err) => reject(err));
  });
}

function atualizarDashboard(object, id) {
  return fetch(`${URL_DASHBOARD}/${id}`, {
    method: "PUT",

    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
    },
    body: JSON.stringify(object),
  })
    .then(async (response) => {
      if (response.ok) {
        const res = await response.json();

        if (res) {
          sessionStorage.removeItem("dashboard");
          sessionStorage.setItem("dashboard", JSON.stringify(res));
        }
        return res;
      }

      throw new Error("Error Unknow");
    })
    .catch(() => {
      return;
    });
}

export default { cadastrarEmpresa, atualizarDashboard };
