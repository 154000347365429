import React, { useState, useEffect } from "react";

import AutoCompleteLabel from "../../components/AutoComplete";
import ConfirmeDialog from "../../components/ConfirmeDialog";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import postMapping from "../../repositories/postMapping";
import putMapping from "../../repositories/putMapping";
import getMapping from "../../repositories/getMapping";
import { copyClipboard, removeSpace } from "../../js";
import { Tabs } from "../../components/TabSelect/Tab";
import ButtonIcon from "../../components/ButtonIcon";
import PageDefault from "../../template/DefaultPage";
import FormField from "../../components/FormField";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { i18n } from "../../translate/i18n";
import EmailForm from "./Dialog/EmailForm";
import { toast } from "react-toastify";

const initialDefault = {
  id: 1,
  url: "",
  hottok: "",
  urlObrigado: "",
};

export default function IntegracaoHotmart() {
  const history = useHistory();
  const [webHook, setWebHook] = useState(initialDefault);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [emails, setEmails] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [eventoId, setEventoId] = useState(null);
  const [filtro, setFiltro] = useState({
    usuario: "",
    curso: "",
    datainicial: "",
    datafinal: "",
    evento: "Todos",
  });

  useEffect(() => {
    setLoading(true);
    listarConfig();
    listarEmails();
    setLoading(false);
  }, []);

  async function listarConfig() {
    await getMapping
      .get(`/hotmartwebhook`)
      .then((data) => {
        setWebHook(data[0]);
      })
      .catch((e) => toast.error(e.message));
  }

  async function listarEmails() {
    await getMapping
      .get(`/hotmartwebhook/email`)
      .then((data) => {
        setEmails(data);
      })
      .catch((e) => toast.error(e.message));
  }

  async function listarHistorico() {
    const { evento, datainicial, datafinal, usuario, curso } = filtro;

    const url = `/hotmartwebhook/historico/?
    ${evento !== "Todos" ? `evento=${returnEvento(evento, false)}&` : ""}
    ${datainicial && `datainicial=${datainicial}&`}
    ${datafinal && `datafinal=${datafinal}&`}
    ${usuario && `usuario=${usuario}&`}
    ${curso && `curso=${curso}`}`;

    await getMapping
      .get(removeSpace(url))
      .then((data) => {
        if (data.length < 1) toast.warn("Nenhum registro encontrado");
        setHistorico(data);
      })
      .catch((e) => toast.error(e.message));
  }

  async function atualizarStatus(value, emailId, index) {
    await putMapping
      .put(value, `/hotmartwebhook/email/status/${emailId}`)
      .then(() => {
        const listaemails = emails;
        listaemails[index].ativo = value;
        setEmails(listaemails);
        toast.success("Atualizado com sucesso");
      })
      .catch((e) => toast.error(e.message));
  }

  async function reprocessarEmail() {
    await postMapping
      .post(null, `/hotmartwebhook/historico/reprocessar/${eventoId}`)
      .then((data) => {
        if (data) {
          const newList = historico.map((item) => {
            if (item.id === data.id) {
              return data;
            } else {
              return item;
            }
          });
          setHistorico(newList);
        }
        toast.success("Evento reprocessado");
        setDialogConfirm(false);
      })
      .catch((e) => toast.error(e.message));
  }

  async function atualizar() {
    await postMapping
      .post(webHook, `/hotmartwebhook`)
      .then(() => {
        toast.success("Atualizado com sucesso.");
      })
      .catch((e) => toast.error(e.message));
  }

  const options = [
    "Todos",
    "Compra aprovada",
    "Compra cancelada",
    "Compra reembolsada",
  ];

  const TableHeadHistorico = () => {
    return (
      <thead>
        <tr className="tHead">
          <th className="text-center">Transação</th>
          <th>Usuário</th>
          <th>Curso</th>
          <th className="text-center">Evento</th>
          <th className="text-center">Recebido</th>
          <th className="text-center">Atualização</th>
          <th className="text-center">Status Email</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };
  const TableBodyHistorico = () => {
    return historico.length > 0 ? (
      <tbody>
        {historico.map((item, index) => (
          <tr key={index}>
            <td
              style={{ flex: "1" }}
              data-title="Transação"
              className="text-center"
            >
              <span>{item.transacao}</span>
            </td>
            <td
              style={{ flex: "1", wordBreak: "break-all" }}
              data-title="Usuário"
            >
              <span>{item.usuarioEmail}</span>
            </td>
            <td style={{ flex: "1" }} data-title="Curso">
              <span>{item.cursoTitulo}</span>
            </td>
            <td
              style={{ flex: "1" }}
              data-title="Evento"
              className="text-center"
            >
              <span>{returnEvento(item.evento, true)}</span>
            </td>
            <td style={{ flex: "1" }} data-title="Data" className="text-center">
              <span>{item.dataRecebido}</span>
            </td>
            <td
              style={{ flex: "1" }}
              data-title="Atualização"
              className="text-center"
            >
              <span>{item.ultimaAtualizacao}</span>
            </td>
            <td
              style={{ flex: "1" }}
              data-title="Status"
              className="text-center"
            >
              <span>{item.statusEmail}</span>
            </td>

            <td data-title="Ações" className="text-center">
              <span>
                <i
                  className="mdi mdi-reload icon-size edit-table icon-mr-12"
                  title="Reprocessar envio de email"
                  onClick={() => {
                    setEventoId(item.id);
                    setDialogConfirm(true);
                  }}
                />
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td
            data-title="Empty Table"
            colSpan="8"
            className="text-left"
            style={{ backgroundColor: "#eff1f9" }}
          >
            Nenhum registro encontrado
          </td>
        </tr>
      </tbody>
    );
  };

  const TableHeadEmail = () => {
    return (
      <thead>
        <tr className="tHead">
          <th className="text-center">Assunto</th>
          <th className="text-center">Evento</th>
          <th className="text-center">Status</th>
          <th className="text-center">Editar</th>
        </tr>
      </thead>
    );
  };

  const TableBodyEmail = () => {
    return emails.length > 0 ? (
      <tbody>
        {emails.map((item, index) => (
          <tr key={index}>
            <td style={{ flex: "1" }} data-title="Assunto">
              <span>{item.assunto}</span>
            </td>
            <td
              style={{ flex: "1" }}
              data-title="evento"
              className="text-center"
            >
              <span>{returnEvento(item.evento, true)}</span>
            </td>
            <td
              style={{ flex: "1" }}
              data-title="ativo"
              className="text-center"
            >
              <label className="switch">
                <input
                  type="checkbox"
                  name="site_name"
                  defaultChecked={item.ativo}
                  onChange={(e) =>
                    atualizarStatus(e.target.checked, item.id, index)
                  }
                />
                <span className="slider round"></span>
              </label>
            </td>
            <td data-title="Ações" className="text-center">
              <i
                className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"
                onClick={() => {
                  setEmail(item);
                  setOpenDialog(true);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td
            data-title="Empty Table"
            colSpan="3"
            className="text-left"
            style={{ backgroundColor: "#eff1f9" }}
          >
            Nenhum email encontrado
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <PageDefault menuLateral>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <span>Integração Hotmart</span>
        </h5>
        <div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <div className="sequence-page">
                <a onClick={() => history.goBack()}>Integrações</a>
                <i className="mdi mdi-chevron-right" />
                <span>Integração Hotmart</span>
              </div>
            </div>
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-6">
            <label>
              URL de integração{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">
                  URL para inserção nas configurações de WebHook da Hotmart
                </span>
              </i>
            </label>
            <div
              className="inputReadOnly"
              id="URL_INTE_HOTMART"
              onClick={() => {
                copyClipboard("URL_INTE_HOTMART");
                toast.success("Copiado valor");
              }}
            >
              <a>{webHook.url}</a>
              <span>Copiar</span>
            </div>
          </div>
          <div className="tr-form-group tr-col-sm-6">
            <label>
              URL de agradecimento{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">
                  URL para inserção nas configurações de pagamento aprovado da
                  Hotmart
                </span>
              </i>
            </label>
            <div
              className="inputReadOnly"
              id="URL_INTE_HOTMART_OBG"
              onClick={() => {
                copyClipboard("URL_INTE_HOTMART_OBG");
                toast.success("Copiado valor");
              }}
            >
              <a>{webHook.urlObrigado}</a>
              <span>Copiar</span>
            </div>
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-6">
            <label>
              Hottok de verificação{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">
                  Campo para inserção do Hottok da Hotmart
                </span>
              </i>
            </label>
            <input
              id="hottok"
              placeholder="hottok"
              type="text"
              value={webHook.hottok}
              onChange={(e) =>
                setWebHook((prevState) => ({
                  ...prevState,
                  hottok: e.target.value,
                }))
              }
              maxLength={100}
              className="input"
            />
          </div>
        </div>

        <Button onClick={atualizar}>{i18n.t("button.salvar")}</Button>
        {loading && <Loading title="container-loading-login" />}
        <h5 className="titulo-pages tr-mb-3">
          <hr />
        </h5>

        <Tabs>
          <span title="E-mails">
            <div className="table-container">
              <table>
                <TableHeadEmail />
                <TableBodyEmail />
              </table>
            </div>
          </span>
          <span title="Histórico">
            <div className="tr-row">
              <div className="tr-form-group tr-col-sm">
                <FormField
                  style={{ height: 56 }}
                  onChange={(e) =>
                    setFiltro((prevState) => ({
                      ...prevState,
                      usuario: e.target.value,
                    }))
                  }
                  name="tituloCurso"
                  label=""
                  placeholder="Usuario"
                  value={filtro.usuario}
                  maxLength="50"
                />
              </div>
              <div className="tr-form-group tr-col-sm">
                <FormField
                  style={{ height: 56 }}
                  onChange={(e) =>
                    setFiltro((prevState) => ({
                      ...prevState,
                      curso: e.target.value,
                    }))
                  }
                  name="tituloCurso"
                  placeholder="Curso"
                  value={filtro.curso}
                  maxLength="50"
                  label=""
                />
              </div>
              <div className="tr-form-group tr-col-sm">
                <input
                  style={{ height: 56 }}
                  onChange={(e) =>
                    setFiltro((prevState) => ({
                      ...prevState,
                      datainicial: e.target.value,
                    }))
                  }
                  name="tituloCurso"
                  value={filtro.datainicial}
                  type="date"
                />
              </div>
              <div className="tr-form-group tr-col-sm">
                <input
                  style={{ height: 56 }}
                  onChange={(e) =>
                    setFiltro((prevState) => ({
                      ...prevState,
                      datafinal: e.target.value,
                    }))
                  }
                  name="tituloCurso"
                  value={filtro.datafinal}
                  type="date"
                />
              </div>
              <div className="tr-form-group tr-col-sm">
                <AutoCompleteLabel
                  id="filtro"
                  options={options}
                  value={filtro.evento}
                  onChangeText={(e) =>
                    setFiltro((prevState) => ({
                      ...prevState,
                      evento: e,
                    }))
                  }
                  label={i18n.t("formulario.status")}
                  textFieldId="labelFiltro"
                  clearable
                />
              </div>
              <div className="tr-form-group tr-col-sm tr-display-flex tr-flex-end">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={listarHistorico}
                />
              </div>
            </div>
            <div className="table-container">
              <table>
                <TableHeadHistorico />
                <TableBodyHistorico />
              </table>
            </div>
          </span>
        </Tabs>
      </div>

      <EmailForm
        data={email}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        Config={() => console.log("")}
        updateList={listarEmails}
      />
      <ConfirmeDialog
        title="Reprocessar E-mail"
        description="Deseja realmente reprocessar o envio do email?"
        open={dialogConfirm}
        confirm={reprocessarEmail}
        onClose={() => setDialogConfirm(false)}
      />
    </PageDefault>
  );
}

export const returnEvento = (item, convert) => {
  if (convert) {
    switch (item) {
      case "PURCHASE_APPROVED":
        return "Compra aprovada";
      case "PURCHASE_CANCELED":
        return "Compra cancelada";
      case "PURCHASE_REFUNDED":
        return "Compra reembolsada";
    }
  } else {
    switch (item) {
      case "Compra aprovada":
        return "PURCHASE_APPROVED";
      case "Compra cancelada":
        return "PURCHASE_CANCELED";
      case "Compra reembolsada":
        return "PURCHASE_REFUNDED";
    }
  }
};
