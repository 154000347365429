import React from "react";
import DefaultVideo from "../../template/PaginaVideo/DefaultVideo";
import Treinamento from "../../components/TreinamentoPanel";
import AulaProvider from "../../contexts/aula.context";

function Sala() {
  return (
    <DefaultVideo>
      <AulaProvider>
        <Treinamento />
      </AulaProvider>
    </DefaultVideo>
  );
}

export default Sala;
