import React from "react";
import "../styles";
import Footer from "../../components/Footer";

export default function DefaultVideo({ children }) {
  return (
    <>
      <div className="background-interno template-video">
        {children}
        <Footer className="footer-video" />
      </div>
    </>
  );
}
