import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import LogoGa from "../../components/img/logo/not.png";
import DefaultPage from "../../template/DefaultPage";
import { LinearProgress } from "@material-ui/core";
import Loading from "../../components/Loading";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import service from "../../services";
import {
  StyledCursoHeader,
  StyledCursolistButtons,
  StyledContentModulo,
} from "./styles";

export default function CursoDetalhe() {
  const { cursoid, tenant } = useParams();
  const [curso, setCurso] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    busca();
  }, []);

  async function busca() {
    await service
      .get(`/cursos/${cursoid}/embedmodulos`)
      .then((data) => {
        setCurso(data);
        setModulos(data.modulos);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        history.goBack();
        toast.error(e.message);
      });
  }

  function handleContinuar() {
    const { modulo, aula } = curso.aulaAtual;

    const moduloAtual = modulos.find((e) => {
      return e.id === modulo;
    });

    if (moduloAtual !== undefined) {
      history.push(
        `/${tenant}/modulos/${moduloAtual.id}/${moduloAtual.titulo}/${aula}`
      );
    } else {
      history.push(
        `/${tenant}/modulos/${modulos[0].id}/${modulos[0].titulo}/0`
      );
    }
  }

  async function handleInscricao() {
    await service
      .post(`/inscricao/curso/${cursoid}`)
      .then(() => {
        setCurso((prevState) => ({
          ...prevState,
          inscricao: true,
        }));
        toast.success("Inscrição realizada com sucesso!");
      })
      .catch((e) => toast.error(e.message));
  }

  async function handleCertificado() {
    await service
      .post(`/inscricao/curso/${curso.id}/concluir`)
      .then((data) => {
        if (data.hashCertificado) {
          history.push(`/${tenant}/certificado/${data.hashCertificado}`);
        } else {
          toast.warn(
            "Atenção: Necessário concluir todas as atividades dos módulos!"
          );
        }
      })
      .catch((e) => toast.error(e.message));
  }

  return (
    <DefaultPage>
      <StyledCursoHeader color={curso.cor || "#c4c4c4"}>
        <div className="header-info-topo">
          <div className="header-info-curso">
            <div className="box-img">
              <div>
                <img alt="logo" title="logo" src={curso.imagem || LogoGa} />
              </div>
            </div>
            <div className="curso-desc">
              <h4>{curso.titulo}</h4>
              <p>{curso.descricao}</p>
            </div>
          </div>
          <div className="header-info-user">
            <div className="iconprog">
              <div>
                <i
                  className="mdi mdi-book-open-blank-variant"
                  alt="Livro"
                  title="Livro"
                />
              </div>
            </div>
            <div className="progress-box">
              <label>Progresso</label>
              <div className="progressbar">
                <div className="progressbar-box">
                  <LinearProgress
                    variant="determinate"
                    value={curso.progresso}
                  />
                </div>
                {curso.progresso + "%"}
              </div>
            </div>
            <div className="workload-box">
              <label>Carga Horária</label>
              <p>
                <i className="mdi mdi-clock" />
                <span>{curso.cargaHoraria}</span>
              </p>
            </div>
            <div className="countSub-box">
              <label>Alunos(As)</label>
              <p>
                <i className="mdi mdi-account" />
                <span>{curso.inscritos}</span>
              </p>
            </div>
          </div>
        </div>
      </StyledCursoHeader>
      <StyledCursolistButtons>
        <ul>
          <li className="list-btn-primary">
            {curso.inscricao ? (
              <a onClick={handleContinuar}>
                <i className="mdi mdi-play"></i>
                Continuar curso
              </a>
            ) : (
              <>
                {curso.status !== "EXCLUSIVO" ? (
                  <a onClick={handleInscricao}>Inscrever-se no curso</a>
                ) : (
                  <a href={curso.pagamentoURL} target="_blank">
                    Comprar Agora
                  </a>
                )}
              </>
            )}
          </li>
          {curso.inscricao === true ? (
            <li className="list-btn-secondary">
              <a onClick={handleCertificado}>Certificado</a>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </StyledCursolistButtons>
      <StyledContentModulo>
        <ul className="main-content">
          {modulos.map((item, index) => (
            <li
              key={index}
              className="li-modulo"
              onClick={() => {
                if (curso.inscricao) {
                  if (item.bloqueado) {
                    return toast.warning(i18n.t("toastWarning.modBlock"));
                  } else {
                    return history.push(
                      `/${tenant}/modulos/${item.id}/${item.titulo}/0`
                    );
                  }
                } else {
                  return toast.warn(
                    "Atenção: Necessário se inscrever no módulo antes de iniciar"
                  );
                }
              }}
            >
              <div className="modulo-icon">
                {item.bloqueado ? (
                  <i
                    className="mdi mdi-close-circle-outline"
                    title="Este modulo está bloqueado pois não atende os requisitos"
                  />
                ) : (
                  <>
                    {item.concluido ? (
                      <i className="mdi mdi-check-circle-outline" />
                    ) : (
                      <>
                        {item.progresso > 0 ? (
                          <i className="mdi mdi-pencil-circle-outline" />
                        ) : (
                          <i className="mdi mdi-play-circle" />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="modulo-text">
                <div className="modulo-box-top">
                  <div className="modulo-title">
                    <span>{item.titulo}</span>
                  </div>
                  <div className="modulo-percent">
                    <span>{item.progresso}%</span>
                    <span>|</span>
                    <span>{item.minutos}min</span>
                  </div>
                </div>
                <div className="modulo-box-desc">{item.descricao}</div>
              </div>
            </li>
          ))}
          {modulos.length < 1 && (
            <label>{i18n.t("tabela.zeroRegistros")}</label>
          )}
        </ul>
      </StyledContentModulo>
      {loading && <Loading title="container-loading-login" />}
    </DefaultPage>
  );
}
