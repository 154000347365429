import React, { useEffect, useState } from "react";

import FormArquivo from "../Lista/CadastroAula/formArquivo";
import { useParams, useHistory } from "react-router-dom";
import Avaliacao from "../Lista/CadastroAula/avaliacao";
import getMapping from "../../repositories/getMapping";
import { Tabs } from "../../components/TabSelect/Tab";
import PageDefault from "../../template/DefaultPage";
import Loading from "../../components/Loading";
import ListaAula from "../Lista/CadastroAula";
import "../../components/css/styleguide.scss";

export default function ConteudoModulo() {
  const { cursotitulo, modulotitulo, moduloid, avaliacao } = useParams();
  const [aulas, setAulas] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [prova, setProva] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function consulta() {
      setLoading(true);
      await listarAulas();
      await listarArquivos();
      if (avaliacao === "true") {
        await listarProva();
      }
      setLoading(false);
    }
    consulta();
  }, []);

  async function listarAulas() {
    await getMapping
      .get(`/videos/modulo/${moduloid}`)
      .then((data) => {
        setAulas(data);
      })
      .catch((err) => {
        return;
      });
  }

  async function listarArquivos() {
    await getMapping.get(`/modulo/arquivo/modulos/${moduloid}`).then((data) => {
      setArquivos(data);
    });
  }

  async function listarProva() {
    await getMapping
      .get(`/prova/modulos/${moduloid}`)
      .then((data) => {
        if (data) {
          setProva(data);
        } else {
          setProva(null);
        }
      })
      .catch((err) => {
        return;
      });
  }

  return (
    <PageDefault menuLateral>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <span>Conteúdo módulo</span>
        </h5>
        <div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <div className="sequence-page">
                <a onClick={() => history.goBack()}>Cadastro módulo</a>
                <i className="mdi mdi-chevron-right" />
                <span>
                  Contúedo do módulo:
                  {modulotitulo}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          {loading && <Loading title="container-loading-login" />}
          <>
            {avaliacao === "true" ? (
              <Tabs>
                <span title="Aulas">
                  <ListaAula
                    listar={listarAulas}
                    aulas={aulas}
                    moduloId={moduloid}
                    path={moduloid}
                    cursotitulo={cursotitulo}
                    modulotitulo={modulotitulo}
                  />
                </span>
                <span title="Anexos">
                  <FormArquivo
                    arquivos={arquivos}
                    moduloId={moduloid}
                    listarArquivos={listarArquivos}
                  />
                </span>

                <span title="Avaliação">
                  <Avaliacao
                    prova={prova}
                    moduloId={moduloid}
                    listarProva={listarProva}
                  />
                </span>
              </Tabs>
            ) : (
              <Tabs>
                <span title="Aulas">
                  <ListaAula
                    listar={listarAulas}
                    aulas={aulas}
                    moduloId={moduloid}
                    path={moduloid}
                    cursotitulo={cursotitulo}
                    modulotitulo={modulotitulo}
                  />
                </span>
                <span title="Anexos">
                  <FormArquivo
                    arquivos={arquivos}
                    moduloId={moduloid}
                    listarArquivos={listarArquivos}
                  />
                </span>
              </Tabs>
            )}
          </>
        </div>
      </div>
    </PageDefault>
  );
}
