import React from "react";
import "./styles";

import MenuDash from "../components/Menu/MenuDash";
import Footer from "../components/Footer";

export default function DefaultPage({ children }) {
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));

  let styles = {
    background: dash.background && `url(${dash.background})`,
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
  };

  return (
    <>
      <div className="background-dashboard" style={styles}>
        <MenuDash />
        {children}
        <Footer className="footer-dash" />
      </div>
    </>
  );
}
