import React, { useState, useEffect } from "react";
import getMapping from "../../repositories/getMapping";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Relatorio, { Titulo } from "../../components/Relatorio";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import NunitoLight from "../../components/fonts/Nunito-Light.ttf"
import NunitoBold from "../../components/fonts/Nunito-Bold.ttf"
import NunitoRegular from "../../components/fonts/Nunito-Regular.ttf"
import { StyledBoxButton } from "./styles";
import ButtonIcon from "../ButtonIcon";
import AutoCompleteLabel from "../AutoComplete";

export default function ResultadoProvaModulo() {
    const [cursoModulo, setCursoModulo] = useState([]);
    const [resultados, setResultados] = useState([]);
    const [openExport, setOpenExport] = useState(false);
    const [filtro, setFiltro] = useState()
   
    Font.register({
        family: "Nunito", fonts: [
            {
                src: NunitoRegular
            },
            {
                src: NunitoBold,
                fontWeight: 'bold'
            },
            {
                src: NunitoLight,
                fontWeight: 'light'
            }

        ]
    });

    useEffect(() => {
        listarCursoModulo();
        Titulo(i18n.t("relatorio.relatorio2"))
    }, []);

    function listarCursoModulo() {
        getMapping.get(`/modulos/curso/1`)
            .then((data) => {
                setCursoModulo(data)
            })
            .catch(() => { return; });
    }

    function listarResultados() {
        const chosenModulo = cursoModulo.find((modulo) => {
            return modulo.cursoTitulo + " / " + modulo.titulo === filtro;
        });

        if (chosenModulo !== undefined) {
            getMapping.get(`/concluidos/modulo/${chosenModulo.id}/resultado`)
                .then((data) => {
                    if (data.length > 0) {
                        setResultados(data);
                        setOpenExport(true)
                    } else {
                        toast.warning(i18n.t("toastWarning.zeroConsulta"))
                        setOpenExport(false)
                    }
                })
        }
    }

    function DialogExport() {

        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={openExport}
                fullWidth={true}
                scroll={"paper"}
            >
                <div className="header-dialog">
                    <div className="header-dialog-title">{i18n.t("consulta.relatorio")}</div>
                    <div className="header-dialog-close" onClick={() => setOpenExport(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <Relatorio >
                    <View style={styles.container}>

                        <View style={styles.areaTexto}>
                            <Text style={styles.texto}>{i18n.t("relatorio.cursoModulo")}:  {filtro}</Text>
                        </View>

                        <View style={styles.areaTable}>
                            <View style={styles.headerTable}>
                                <View style={[styles.columnUsuario, { alignItems: 'center' }]}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.usuario")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.totalTentativa")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.notaAprovacao")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.data")}</Text>
                                </View>
                                <View style={styles.column}>
                                    <Text style={styles.textoHeader}>{i18n.t("relatorio.media")}</Text>
                                </View>

                            </View>
                            {resultados.map((item, index) => (
                                <View key={index} style={styles.bodyTable}>
                                    {[item.usuarioEmail].map((texto) => (
                                        <View style={[styles.columnUsuario, { alignItems: 'flex-start' }]}>
                                            <Text style={styles.textoBody}>{item.usuarioNome} : {texto}</Text>
                                        </View>
                                    ))}
                                    {[item.tentativas].map((texto) => (
                                        <View style={styles.column}>
                                            <Text style={styles.textoBody}>{texto === 0 ? "-" : texto}</Text>
                                        </View>
                                    ))}
                                    {[item.notaAprovacao].map((texto) => (
                                        <View style={styles.column}>
                                            <Text style={styles.textoBody}>{item.tentativas === 0 ? "-" : texto}</Text>
                                        </View>
                                    ))}
                                    {[item.data].map((texto) => (
                                        <View style={styles.column}>
                                            <Text style={styles.textoBody}>{texto}</Text>
                                        </View>
                                    ))}
                                    {[item.media].map((texto) => (
                                        <View style={styles.column}>
                                            <Text style={styles.textoBody}>{item.tentativas === 0 ? "-" : parseFloat(texto).toFixed(2)}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                </Relatorio>
            </Dialog>
        );
    }

    return (
        <div>
            <div className="tr-row">
                <div className="tr-form-group tr-col-sm-4">
                    <br />
                    <AutoCompleteLabel
                        id="curso-modulo"
                        options={cursoModulo.map(({ titulo, cursoTitulo }) =>
                        cursoTitulo.concat(" / " + titulo)
                    )}
                        value={filtro}
                        onChangeText={setFiltro}
                        label={i18n.t("consulta.selecioneCurs") + "/módulo"}
                        textFieldId="labelSelectCurso"
                    // labelavel
                    />
                   
                </div>
                <div className="tr-form-group tr-col-sm-2">
                    <StyledBoxButton>
                        <ButtonIcon
                            value={i18n.t("button.gerar")}
                            icon="mdi mdi-text-search icon-mr-12"
                            onClick={() => listarResultados()}
                        />
                    </StyledBoxButton>
                </div>
            </div>
            <Divider />

            <DialogExport />
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 25,
    },
    areaTexto: {
        paddingVertical: 2
    },
    texto: {
        fontSize: 12,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
        fontWeight: 'bold'

    },
    areaTable: {
        marginTop: 10,
        flexDirection: 'column',
        paddingTop: 25,
        justifyContent: 'center'
    },
    headerTable: {
        backgroundColor: '#2d71cc',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    textoHeader: {
        fontSize: 12,
        color: '#fff',
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        alignItems: 'flex-start'

    },
    textoBody: {
        fontSize: 11,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
        paddingLeft: 4
    },
    bodyTable: {
        flexDirection: 'row',
        paddingTop: 10,
        borderWidth: 0.2,
        borderColor: '#4D4D4D',
        backgroundColor: '#E0F4FD'

    },
    column: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    column2: {
        flex: 3,
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnUsuario: {
        flex: 5,
        justifyContent: 'center',
    }

})