import React, { useState } from "react";

import SchoolTwoToneIcon from "@material-ui/icons/SchoolTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import ListaQuestoes from "../ListaQuestoes";
import { i18n } from "../../translate/i18n";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import MsgProva from "../MsgProva";
import "./index.css";

export default function Prova(props) {
  const [open, setOpen] = useState(false);
  let shuffle = require("shuffle-array"),
    collection = ["order1", "order2", "order3", "order4"];
  shuffle(collection);

  async function handleClick() {
    let total = await props.totalVideos;
    let elementos = await document.getElementsByClassName("video-ready").length;
    if (props.concluido === false) {
      if (elementos >= total) {
        setOpen(true);
        localStorage.clear();
        sessionStorage.removeItem("nota");
      } else {
        toast.warning(i18n.t("toastWarning.blockProva"));
      }
    } else {
      toast.info(i18n.t("toastInfo.aprovadoMod"));
    }

    return total;
  }

  return (
    <div>
      <Button
        onClick={() => {
          setTimeout(function () {
            handleClick();
          }, 50);
        }}
      >
        <SchoolTwoToneIcon />
        <label>{i18n.t("treinamentoPainel.prova")}</label>
      </Button>

      <Dialog fullScreen="true" open={open} onClose={() => setOpen(false)}>
        <div className="header-dialog">
          <div className="header-dialog-title">{props.tituloMod}</div>
          <div className="header-dialog-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
        </div>
        <MsgProva />
        <div className="container-dialog-prova">
          <h5 className="titulo-avaliação">
            <hr />
            <span className="barra">/</span>Avaliação <span>de</span>
            <strong>Módulo</strong>
            <span className="barra">/</span>
            <hr />
          </h5>
          <ListaQuestoes
            onClose={() => setOpen(false)}
            collection={collection}
          />
        </div>
      </Dialog>
    </div>
  );
}
