import React, { useState, useEffect } from "react";

import IconeEmailSend from "../../components/img/DrawFigure/email-send.svg";
import LogoNotFound from "../../components/img/logo/not.png";
import { useHistory, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import service from "../../services";
import "./index.scss";

export default function PaginaObrigado() {
  const { tenant } = useParams();
  const history = useHistory();
  const [empresa, setEmpresa] = useState({ nomeEmpresa: "", logo: "" });
  useEffect(() => {
    buscarLogo();
  }, []);
  async function buscarLogo() {
    await service
      .getSemToken(`/empresa/logos/${tenant}/login`)
      .then((data) => {
        setEmpresa(data);
      })
      .catch((e) => {
        history.push("/");
      });
  }
  return (
    <div className="pag-obrigado-container">
      <div className="area-logo">
        <div className="imagem">
          <img
            className="logo-ga"
            alt="Logo "
            title="Logo "
            width="80px"
            src={empresa.logo || LogoNotFound}
          />
        </div>
      </div>

      <div className="container-body">
        <div className="area-texto-obrigado">
          <div className="area-icone">
            <img
              className="logo-email-send"
              width="100px"
              src={IconeEmailSend}
            />
          </div>
          <h1>Obrigado pela compra!</h1>
          <span>
            Seja bem-vindo(a)! A{" "}
            <span className="empresa">{empresa.nomeEmpresa}</span> agradece pela
            compra. Verifique seu e-mail e comece a aprender agora mesmo.
          </span>
        </div>
      </div>

      <Footer />
    </div>
  );
}
