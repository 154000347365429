import React, { useState, useEffect } from "react";

import AutoCompleteLabel from "../../../../components/AutoComplete";
import { CardMenu } from "../../../../components/MenuCardOptions";
import postMapping from "../../../../repositories/postMapping";
import getMapping from "../../../../repositories/getMapping";
import ButtonIcon from "../../../../components/ButtonIcon";
import Loading from "../../../../components/Loading";
import Dialog from "../../../../components/Dialog";
import { i18n } from "../../../../translate/i18n";
import { removeSpace } from "../../../../js";
import { toast } from "react-toastify";

// import { i18n } from "../../../translate/i18n";

export default function UsuarioCurso({ curso, open, onClose }) {
  const [grupos, setGrupos] = useState([]);
  const [filtro, setFiltro] = useState({
    usuario: "",
    grupo: "Todos os grupos",
    status: "Todas",
  });
  const [resultadoInscritos, setResultadoInscritos] = useState([]);
  const [maisOpcoes, setMaisOpcoes] = useState({ a: false, b: null });
  const [checkboxGeral, setCheckboxGeral] = useState(false);
  const [loading, setLoading] = useState(false);

  const listaGrupos = grupos.map(({ nome }) => nome);
  listaGrupos.push("Todos os grupos");
  useEffect(() => {
    listarGrupo();
    setResultadoInscritos([]);
  }, [open]);

  async function listarSalvos() {
    setLoading(true);
    let grupoid = null;

    const chosenGrupo = grupos.find((e) => {
      return e.nome === filtro.grupo;
    });
    if (chosenGrupo !== undefined) {
      grupoid = chosenGrupo.id;
    }

    const url = `/inscricao/curso/lista/${curso.id}?
    ${filtro.usuario.trim() && `email=${filtro.usuario}`}
    ${grupoid != null ? `&grupoid=${grupoid}` : ""}`;

    await getMapping.get(removeSpace(url)).then((data) => {
      setResultadoInscritos(
        data.map((item) => {
          return { ...item, checked: false };
        })
      );
    });
    setLoading(false);
  }

  function listarGrupo() {
    getMapping.get(`/grupo/all`).then((data) => {
      setGrupos(data);
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      listarSalvos();
    }
  };

  async function atualizarInscricoes(array) {
    await postMapping
      .post(array, "/inscricao/curso/lote")
      .then(() => {
        listarSalvos();
        toast.success("Atualizado com sucesso");
      })
      .catch((e) => toast.error(e.message));
  }

  function handleList() {
    listarSalvos();
  }

  function handleCheckboxGeral() {
    setResultadoInscritos(
      resultadoInscritos.map((item) => {
        return { ...item, checked: !checkboxGeral };
      })
    );
    setCheckboxGeral(!checkboxGeral);
  }

  function handleMaisOpcoes(value) {
    const listaAtualizar = [];
    resultadoInscritos.forEach((item) => {
      if (item.checked) {
        listaAtualizar.push({
          usuarioId: item.usuarioId,
          cursoId: item.cursoId,
          ativo: value,
        });
      }
    });

    atualizarInscricoes(listaAtualizar);
    setCheckboxGeral(false);
  }

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th className="text-center">
            <input
              type="checkbox"
              name="checkboxGeral"
              defaultChecked={checkboxGeral}
              onChange={handleCheckboxGeral}
            />
          </th>
          <th>Usuário</th>
          <th className="text-center">Origem</th>
          <th className="text-center">Inscrição</th>
          <th className="text-center">Opções</th>
        </tr>
      </thead>
    );
  };
  const TableBody = () => {
    return (
      <tbody>
        {resultadoInscritos.map((item, index) => (
          <tr key={index}>
            <td data-title="Usuário" className="text-center ">
              <input
                type="checkbox"
                name="checkbox"
                defaultChecked={item.checked}
                onChange={() => {
                  let dataInicial = resultadoInscritos;
                  dataInicial[index].checked = !item.checked;
                  setResultadoInscritos(dataInicial);
                }}
              />
            </td>
            <td data-title="Usuário">
              <span> {item.usuarioNome}</span>
            </td>

            <td data-title="Data inscrição" className="text-center ">
              <span>INTERNO</span>
            </td>

            <td data-title="Status Inscrição" className="text-center ">
              {item.ativo ? (
                <span style={{ color: "blue" }}>ATIVA</span>
              ) : (
                <span style={{ color: "red" }}>INATIVA</span>
              )}

              <br />
            </td>
            <td data-title="Status Inscrição" className="text-center">
              <div>
                <a style={{ cursor: "pointer" }}>
                  <i
                    style={{ fontSize: "1.5em" }}
                    className="mdi mdi-chevron-down"
                    onClick={() =>
                      setMaisOpcoes((prevState) => ({
                        ...prevState,
                        b: index,
                      }))
                    }
                  />
                </a>
                <CardMenu
                  open={maisOpcoes.b === index}
                  direction="right"
                  width="150px"
                >
                  {!item.ativo ? (
                    <a
                      onClick={() => {
                        atualizarInscricoes([
                          {
                            usuarioId: item.usuarioId,
                            cursoId: item.cursoId,
                            ativo: true,
                          },
                        ]);
                      }}
                    >
                      <i className="mdi mdi-check-circle" />
                      Ativar inscrição
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        atualizarInscricoes([
                          {
                            usuarioId: item.usuarioId,
                            cursoId: item.cursoId,
                            ativo: false,
                          },
                        ]);
                      }}
                    >
                      <i className="mdi mdi-close-circle" />
                      Inativar inscrição
                    </a>
                  )}
                </CardMenu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={`Controle de inscritos: ${curso.titulo}`}
      style={{
        height: "70vh",
      }}
    >
      <div
        onClick={() => {
          if (maisOpcoes.a) {
            setMaisOpcoes((prevState) => ({
              ...prevState,
              a: false,
            }));
          }
          if (maisOpcoes.b !== null) {
            setMaisOpcoes((prevState) => ({
              ...prevState,
              b: null,
            }));
          }
        }}
      >
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm">
            <input
              name="usuarioNome"
              style={{ height: 56 }}
              value={filtro.usuario}
              placeholder="Email Usuário"
              onKeyPress={handleKeyPress}
              onChange={(e) =>
                setFiltro((prevState) => ({
                  ...prevState,
                  usuario: e.target.value,
                }))
              }
            />
          </div>
          <div className="tr-form-group tr-col-sm">
            <AutoCompleteLabel
              id="grupo"
              options={listaGrupos}
              value={filtro.grupo}
              onChangeText={(e) =>
                setFiltro((prevState) => ({
                  ...prevState,
                  grupo: e,
                }))
              }
              label="Grupo"
              textFieldId="labelSelectGrupo"
              // labelavel
            />
          </div>

          <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
            <div className="tr-mr-4">
              <ButtonIcon
                value={i18n.t("button.listar")}
                icon="mdi mdi-text-search icon-mr-12"
                onClick={handleList}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="tr-form-group tr-col-sm-12">
            <div style={{ marginBottom: 10, alignContent: "center" }}>
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  setMaisOpcoes((prevState) => ({
                    ...prevState,
                    a: true,
                  }))
                }
              >
                <label>Mais Opções</label>
                <i
                  style={{ fontSize: "1.8em" }}
                  className="mdi mdi-chevron-down"
                />
              </a>
              <div style={{ marginLeft: 10 }}>
                <CardMenu open={maisOpcoes.a} direction="left" width="150px">
                  <a onClick={() => handleMaisOpcoes(true)}>
                    <i className="mdi mdi-check-circle" />
                    Ativar inscrições selecionadas
                  </a>
                  <a onClick={() => handleMaisOpcoes(false)}>
                    <i className="mdi mdi-close-circle" />
                    Inativar inscrições selecionadas
                  </a>
                </CardMenu>
              </div>
            </div>
            <div
            // style={{
            //   height: "calc(70vh - 170px)",
            //   width: "100%",
            //   display: "block",
            //   overflowY: "scroll",
            // }}
            >
              <div className="table-container">
                {loading && <Loading title="container-loading-login" />}
                <table>
                  <TableHead />
                  <TableBody />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
