import React from "react";
import "./styles";
import Footer from "../components/Footer";

export default function DefaultPage({ children }) {
  return (
    <div className="background-login">
      {children}
      <Footer className="footer-login" />
    </div>
  );
}
