import React from "react";
import PropTypes from "prop-types";
import { FormFieldWrapper, Div, LabelText, Input } from "./styled";

import "../css/styleguide.scss";

function FormField({
  onKeyPress,
  accept,
  label,
  type,
  name,
  value,
  placeholder,
  onChange,
  suggestions,
  maxLength,
  disabled,
  onFocus,
  onBlur,
  style,
}) {
  const isTypeTextArea = type === "textarea";
  const tag = isTypeTextArea ? "textarea" : "input";

  const fieldId = `id_${name}`;
  const hasSuggestions = Boolean(suggestions.length);

  return (
    <FormFieldWrapper>
      <Div htmlFor={fieldId}>
        <LabelText>{label}</LabelText>
        <Input
          onKeyPress={onKeyPress}
          as={tag}
          id={fieldId}
          type={type}
          value={value}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          accept={accept}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
          style={style}
          autoComplete={hasSuggestions ? "off" : "off"}
          list={hasSuggestions ? `suggestionFor_${fieldId}` : undefined}
        />
        {hasSuggestions && (
          <datalist id={`suggestionFor_${fieldId}`}>
            {suggestions.map((suggestion, index) => (
              <option value={suggestion} key={index}>
                {suggestion}
              </option>
            ))}
          </datalist>
        )}
      </Div>
    </FormFieldWrapper>
  );
}

FormField.defaultProps = {
  type: "text",
  value: "",
  onChange: () => {},
  suggestions: [],
};

FormField.propTypes = {
  onKeyPress: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
};

export default FormField;
