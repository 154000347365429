import React, { useState, useEffect, useContext } from "react";
import LogoNotFound from "../../components/img/logo/not.png";
import { AuthContext } from "../../contexts/auth.context";
import { useHistory, useParams } from "react-router-dom";
import DefaultLogin from "../../template/DefaultLogin";
import FormField from "../../components/FormField";
import Form from "../../components/Form/FormLogin";
import Loading from "../../components/Loading";
import Button from "../../components/Button/";
import { i18n } from "../../translate/i18n";
import Alert from "@material-ui/lab/Alert";
import useForm from "../../hooks/useForm";
import service from "../../services";
import "../../components/FormField";
import "../Login/login.scss";

export default function Login() {
  const { tenant } = useParams();
  const { signIn, loading, emailAtual } = useContext(AuthContext);
  const history = useHistory();
  const initialData = { email: emailAtual, senha: "" };
  const { handleChange, values } = useForm(initialData);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    buscarLogo();
  }, []);

  async function buscarLogo() {
    await service
      .getSemToken(`/empresa/logos/${tenant}/login`)
      .then((data) => {
        if (data === 0) {
          return;
        }
        setLogo(data.logo);
        return;
      })
      .catch((e) => {
        history.push("/");
      });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let errorList = [];
    if (values.email === "") {
      errorList.push("Campo E-mail em branco!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.senha === "") {
      errorList.push("Campo senha em branco!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (errorList.length < 1) {
      const formLoagin = {
        email: values.email,
        senha: values.senha,
        tenant: "/" + tenant + "/login",
      };
      signIn(formLoagin).then((data) => {
        if (data) {
          return history.push(data);
        }
      });
    }
  }

  return (
    <DefaultLogin>
      <Form className="form-login" onSubmit={handleSubmit}>
        {loading && <Loading title="container-loading-login" />}
        <div>
          <img
            style={{ maxWidth: 100, maxHeight: 100 }}
            src={logo || LogoNotFound}
            alt="logo"
            title="GerencieAqui"
            className="logo-ga"
          />
        </div>
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <FormField
              label={i18n.t("login.email")}
              type="text"
              name="email"
              maxLength={255}
              value={values.email}
              onChange={handleChange}
            ></FormField>
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <label>{i18n.t("login.senha")}</label>
            <div className="inputPassword">
              <input
                type={passwordVisible ? "text" : "password"}
                name="senha"
                maxLength={255}
                value={values.senha}
                onChange={handleChange}
              />
              <span onClick={() => setPasswordVisible(!passwordVisible)}>
                <i
                  className={
                    passwordVisible
                      ? "mdi mdi-eye icon-size"
                      : "mdi mdi-eye-off icon-size"
                  }
                />
              </span>
            </div>
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-6">
            <span
              className="esqueciSenha"
              onClick={() => history.push(`/${tenant}/redefinirsenha`)}
            >
              Esqueci minha senha
            </span>
          </div>
          <div className="tr-form-group tr-col-sm-6">
            <Button className="botao-login" type="submit">
              {i18n.t("login.entrar")}
            </Button>
          </div>
        </div>
      </Form>
    </DefaultLogin>
  );
}
