import React, { useContext, useState } from "react";

import LogoNotFound from "../../components/img/logo/not.png";
import { AuthContext } from "../../contexts/auth.context";
import { useHistory, useParams } from "react-router-dom";
import Button2 from "../ButtonLink/ButtonLink2";
import { i18n } from "../../translate/i18n";
import Menu from "@material-ui/core/Menu";
import "../css/Dashboard/dashboard.scss";
import { Link } from "react-router-dom";
import "../css/Dashboard/modulos.scss";
import "../css/Dashboard/cursos.scss";
import Button from "../ButtonLink";

export default function MenuDash() {
  const { checkPermission } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tenant } = useParams();
  const usuario = JSON.parse(sessionStorage.getItem("user"));
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));
  const [visibleContul, setVisibleContul] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    sessionStorage.clear();
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBusca();
    }
  };

  function handleBusca() {
    if (visibleContul && valueInput.trim()) {
      history.push(`/${tenant}/maiscursos/${valueInput}`);
    }
  }
  return (
    <nav className="nav-menu menu-dash">
      <div className="nav-menu-body largura-padrao">
        <div className="holder">
          <div className="imagem">
            <Link to={`/${tenant}/dashboard`}>
              <img
                className="logo-ga"
                alt="Logo empresa"
                title="Logo empresa"
                width="150px"
                src={dash.logo || LogoNotFound}
              />
            </Link>
          </div>
        </div>

        <div className="menu-options">
          <div className="search">
            <div
              className="box"
              onClick={() => {
                if (!visibleContul) {
                  setVisibleContul(true);
                }
              }}
            >
              <input
                type="text"
                className="input"
                name="valueInput"
                readOnly={!visibleContul}
                placeholder={visibleContul ? "Digite aqui sua busca" : ""}
                value={visibleContul ? valueInput : ""}
                onKeyPress={handleKeyPress}
                onChange={(e) => setValueInput(e.target.value)}
                style={
                  visibleContul
                    ? {
                        width: "300px",
                        background: "#fff",
                        borderRadius: "10px",
                      }
                    : { width: 70, border: "none" }
                }
              />

              <i
                className="mdi mdi-magnify"
                style={{
                  fontSize: 24,

                  color: visibleContul ? "#000" : dash.botaoMenuCor,
                }}
                onClick={handleBusca}
              />
            </div>
          </div>
          <div className="opcoes">
            <Button
              id="ButtonName"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <p style={{ color: dash.botaoMenuCor || "#4D4D4D" }}>
                {i18n.t("menu.ola")}, {usuario.nome}{" "}
                <i className="mdi mdi-chevron-down" />
              </p>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Button2 as={Link} to={`/${tenant}/perfil/usuario`}>
                <i className="mdi mdi-account-details" />
                {i18n.t("menu.perfil")}
              </Button2>

              <Button2 as={Link} to={`/${tenant}/maiscursos/buscar`}>
                <i className="mdi mdi-notebook-check" />
                Mais Cursos
              </Button2>

              {checkPermission() && (
                <Button2 as={Link} to={`/${tenant}/cadastro/curso`}>
                  <i className="mdi mdi-view-dashboard" />
                  Portal Administrador
                </Button2>
              )}

              <Button2 as={Link} to={`/${tenant}/login`} onClick={logout}>
                <i className="mdi mdi-logout" />
                {i18n.t("menu.sair")}
              </Button2>
            </Menu>
          </div>
        </div>
      </div>
    </nav>
  );
}
