const messages = {
  es: {
    translations: {
      menu: {
        perfil: "Editar Perfil",
        configEmpresa: "Configuración de la Empresa",
        cadCurso: "Registro del Curso ",
        cadModulo: "Registro del Módulo",
        cadInstrutor: "Registro de Instructor",
        cadProva: "Prueba de Registro",
        cadUsuario: "Registro del Grupo",
        cadGrupo: "Group Registration",
        usuarios: "Usuarios",
        sair: "Cerrar Sesión",
        ola: "Hola",
        perfilUsuario: "Perfil del Usuario",
        cadPergunta: "Registro de pregunta",
        cadConteudo: "Registro de contenido",
        usuario: "Usuario",
        consultas: "Consultas",
        meuscursos: "Mis cursos",
      },
      dashboard: {
        botaoDestaque: "VER CURSOS",
        cursosLivres: "Cursos libres",
        cursosExclusivos: "Cursos Exclusivos",
      },
      button: {
        salvar: "Guardar",
        novo: "Nuevo",
        listar: "Lista",
        sim: "Sí",
        nao: "No",
        imprimir: "Imprimir",
        gerar: "Generar",
        consultar: "Consultar",
        proximaAtividade: "Siguiente actividad",
      },
      login: {
        email: "E-mail",
        senha: "Contraseña",
        entrar: "Iniciar sesión",
      },
      formulario: {
        titulo: "Título",
        descricao: "Descripción",
        cor: "Color",
        corTitulo: "Color del titulo",
        corDescricao: "Color del descripción",
        corBotao: "Color del boton",
        corTextoBotao: "Color del texto botón",
        corBotaoMenu: "Color del botón menú",
        corIlustracao: "color de ilustración",
        status: "Estado",
        thumbnail: "Thumbnail",
        icone: "Icono",
        curso: "Curso",
        instrutor: "Instructor",
        preRequisito: "Requisito previo (Opcional)",
        resetMod: "Restablecer módulo para usuario",
        nome: "Nombre",
        novaSenha: "Nueva contraseña",
        confirmarSenha: "Confirmar nueva contraseña",
        usuario: "Usuario",
        senha: "Contraseña",
        tituloVideo: "Titulo del Vídeo",
        urlVideo: "URL del Vídeo",
        tamanhoLogo: "125X75 píxeles. menos de 300kb",
        tamanhoImg300: "Menos de 300kb",
        tamanhoBackground: "1920X990 píxeles. menos de 300kb",
        tamanhoIconeCurso: "50X50 píxeles. menos de 300kb",
        tamanhothumbMod: "300x160 píxeles. menos de 300kb",
        tamanhoBgCertificado: "1754x186 píxeles. menos de 300kb",
        pergunta: "Pregunta",
        alternativa: "Alternativa",
        exclusivo: "EXCLUSIVO",
        gratuito: "LIBRE",
        encerrado: "CERRADO",
        certificado: "Backgorund Certificado",
        layoutCertificado: "Diseño certificado (min)",
        tempoEstimado: "Duración estimada",
        ativo: "Activo",
        selecione: "Seleccione",
        cursoModulo: "Curso/Módulo (sin enlace de prueba)",
        mediaaprovacao: "Medios de aprobación",
        selecioneCorreta: "Seleccione la alternativa correcta",
        configUsuario: "Configurar Usuario",
        modulosCompletado: "Módulos completados",
        selecionarImg: "Seleccionar imagen",
        selecionarArq: "Seleccione Archivo",
        zeroImg: "Ninguna imagen seleccionada",
        zeroArq: "Ningún archivo seleccionado",
        grupo: "Grupo",
        videoAula: "Vídeo aula",
        arquivos: "Archivos",
        filtro: "Filtrar",
        todosGrupos: "Todos los grupos",
      },
      consulta: {
        tituloCurso: "Título del curso",
        cursosDisp: "Cursos publicos",
        cursosExcl: "Cursos Exclusivos",
        cursosEnc: "Cursos Cerrados",
        todosCurs: "Todos los cursos",
        selecioneCurs: "Seleccione el curso",
        nomeInst: "Nombre del instructor",
        nomeUsuario: "Nombre de usuario",
        nomeGrupo: "Nombre del grupo",
        ativo: "Activo",
        bloqueado: "Inactivo",
        relatorio: "Reporte",
      },
      tabela: {
        codigo: "Código",
        inscritos: "Suscriptores",
        opcoes: "Opciones",
        tituloModulo: "Título Módulo",
        conteudo: "Contenido",
        modulo: "Módulo",
        concluidos: "Terminado",
        data: "Fecha",
        notaAprovação: "Aprobado con nota",
        tentativas: "Intentos",
        zeroRegistros: "No se encontraron registros.",
        tentativa: "Intento",
        cursoModulo: "Curso / Módulo",
        nomeEmail: "Nombre / Email",
        perguntaProva: "Preguntas de prueba",
      },
      toastSuccess: {
        dashboardAtt: "Actualizado Dashboard.",
        certificadoAtt: "Certificado actualizado.",
        cursoAtt: "Curso Actualizado.",
        cursoCad: "Curso Registrado.",
        cursoDel: "Curso Eliminado",
        moduloCad: "Módulo Registrado.",
        moduloAtt: "Módulo Actualizado.",
        moduloDel: "Módulo Eliminado.",
        aulaCad: "Clase registrada.",
        aulaAtt: "Clase actualizada.",
        aulaDel: "Clase excluida.",
        provaCad: "Prueba Registrada.",
        provaAtt: "Prueba Actualizada.",
        provaDel: "Prueba Eliminada",
        perguntaCad: "Pregunta Registrada.",
        perguntaAtt: "Pregunta Actualizada.",
        perguntaDel: "Pregunta Eliminada.",
        usuarioCad: "Usuario Registrado.",
        usuarioAtt: "Usuario Actualizado.",
        usuarioDel: "Usuario Eliminado.",
        instrutorCad: "Instructor Registrado.",
        instrutorAtt: "Instructor Actualizado.",
        instrutorDel: "Instructor Eliminado.",
        grupoCad: "Grupo Registrado",
        grupoAtt: "Grupo Actualizado",
        grupoDel: "Grupo Eliminado",
        reLogar: "Usuario actualizado, inicie sesión de nuevo.",
        certificadoCad: "Certificado registrado con éxito",
      },
      toastInfo: {
        aprovadoMod: "Ya has pasado el módulo.",
      },
      toastWarning: {
        cursoVazio: "ATENCIÓN: No hay módulos registrados en el curso.",
        modVazio: "ATENCIÓN: módulo sin video registrado.",
        modBlock: "ATENCIÓN: Módulo bloqueado.",
        blockProva:
          "ATENCIÓN: Debe completar todas las clases antes de continuar.",
        zeroCurso: "ATENCIÓN: No se encontraron cursos en la consulta.",
        zeroModulo: "ATENCIÓN: No se encontraron módulos en la consulta.",
        zeroUsuario: "ATENCIÓN: No se encontró ningún usuario en la consulta.",
        zeroInstrutor:
          "ATENCIÓN: No se encontró ningún instructor en la consulta.",
        zeroProva: "ATENCIÓN: No se encontró prueba en la consulta.",
        zeroGrupo: "ATENCIÓN: No se encontraron grupos en la consulta.",
        provaIndisp: "ATENCIÓN: Prueba no disponible.",
        zeroConsulta: "ATENCIÓN: No se encontraron registros..",
        limiteAssinaturas: "Atención: límite de suscripción alcanzado.",
      },
      toastError: {
        catactereConsul: "¡Se requieren al menos 3 caracteres en la consulta.",
        semResp: "¡Hay preguntas sin respuesta.",
        preenchimentoErro: "Relleno no válido.",
      },
      treinamentoPainel: {
        iniciarAula: "¡Comenzar la clase!",
        textoIniciar: "¡Haz clic en iniciar y buenos estudios!",
        iniciar: "Comienzo",
        descVideo: "Descripción video",
        prova: "Prueba de evaluación",
        historico: "Historia",
        notas: "Historial de notas",
        nota: "Nota",
        emitirCert: "Emitir Certificado",
        arquivosAnexo: "Archivos adjuntos",
        acertos: "Golpes",
        descricaoAula: "Descripción de la clase",
      },
      footer: {
        direitos: "Todos los derechos reservados",
      },
      idioma: {
        idioma: "Idioma",
        portugues: "Portugués",
        ingles: "Inglés",
        espanhol: "Español",
      },
      relatorio: {
        relatorio1: "Suscrito al módulo",
        relatorio2: "Resultados de la prueba por módulo",
        relatorio3: "Prueba de estudiante",
        relatorio4: "Historial del estudiante en el módulo",
        relatorio5: "Historia general del estudiante",
        relatorio6: "Historia general del grupo",
        cursoModulo: "Curso / Módulo",
        totalInscritos: "Suscriptores totales",
        usuario: "Usuario",
        dataInscricao: "Fecha de Registro",
        progresso: "Progreso",
        totalTentativa: "Intentos totales",
        notaAprovacao: "Nota de aprobación",
        media: "Promedio",
        dataRealizacao: "Fecha de realización",
        nota: "Nota",
        aprovado: "Aprobado",
        reprovado: "Desaprobado",
        mediaAprovacao: "Promedio de aprobación",
        tentativa: "Intentos",
        acerto: "aciertos",
        data: "Fecha",
        alternativaCorreta: "Alternativa correcta",
        respostaAluno: "Respuesta del estudiante",
        grupo: "Grupo",
        modulo: "Módulo",
        curso: "Curso",
      },
      certificado: {
        editor: "Editor de Certificados",
        edicao: "Edición Certificada",
        frente: "Frente",
        verso: "Verso",
        digiteNome: "Ingresa un nombre",
        header: "Encabezamiento",
        tipo: "Tipo",
        alinharIcon: "Icono alineado",
        alinharTexto: "Texto alineado",
        titulo: "Título",
        fonte: "Fuente",
        tamanhoFonte: "Tamaño de fuente",
        conteudo: "Contenido",
        descricaoCertificado: "Descripción (sugerencias de variables @)",
        endereco: "Dirección",
        assinatura: "Firma",
        backgroundColor: "Color de fondo",
        textColor: "Color de texto",
        texto: "Texto",
        esquerda: "Izquierda",
        direita: "Derecha",
        centro: "Centro",
      },
      iconsTitle: {
        editarCurso: "Editar curso",
        excluir: "Eliminar",
        excluirCurso: "Eliminar curso",
        resetarMod: "Restablecer módulo",
        editarMod: "Editar módulo",
        excluirMod: "Eliminar módulo",
      },
    },
  },
};

export { messages };
