import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import usuarioRepository from "../../../../repositories/Usuario";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../../translate/i18n";
import { Tabs } from "../../../../components/TabSelect/Tab";
import getMapping from "../../../../repositories/getMapping";
import ButtonIcon from "../../../../components/ButtonIcon";
import Dialog from "../../../../components/Dialog";
import { usuarioInicial } from "../../../../pages/Lista/Usuario";
import { TextSize } from "../../../../js/index";
import AutoCompleteLabel from "../../../../components/AutoComplete";

export default function Usuario({ data, onClose, open, listar }) {
  const { tenant } = useParams();
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [usuario, setUsuario] = useState(data);
  const [selectStatus, setSelectStatus] = useState(
    changeStatus(usuario.status, false)
  );

  const options = [i18n.t("consulta.bloqueado"), i18n.t("consulta.ativo")];

  function changeStatus(e, type) {
    if (type) {
      switch (e) {
        case i18n.t("consulta.bloqueado"):
          return "BLOQUEADO";

        case i18n.t("consulta.ativo"):
          return "ATIVO";
        default:
          return "ATIVO";
      }
    } else {
      switch (e) {
        case "BLOQUEADO":
          return i18n.t("consulta.bloqueado");
        case "ATIVO":
          return i18n.t("consulta.ativo");
        default:
          return i18n.t("consulta.ativo");
      }
    }
  }

  useEffect(() => {
    listarGrupo(data);
  }, [open]);

  useEffect(() => {
    setUsuario(data);
  }, [open]);

  //validações atualizar
  async function handleSubmit() {
    let errorList = [];
    let grupoSelect;

    let status = changeStatus(selectStatus, true);

    const chosenGrupo = grupos.find((e) => {
      return e.nome === usuario.grupoNome;
    });

    if (chosenGrupo !== undefined) {
      grupoSelect = chosenGrupo.id;
    } else {
      grupoSelect = usuario.grupoId;
    }

    if (!usuario.nome.trim()) {
      errorList.push("Insira um nome válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!usuario.email.trim()) {
      errorList.push("Insira um email válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!usuario.grupoNome.trim()) {
      errorList.push("Insira um grupo válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!usuario.senha.trim() && usuario.id === null) {
      errorList.push("Insira uma senha válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (usuario.senha.trim() !== usuario.senhaConfirma.trim()) {
      errorList.push("Sua nova senha não coincide com a confirmação!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (usuario.senha.length > 0 && usuario.senha.length < 5) {
      errorList.push("Insira uma senha de 5 a 30 caracteres!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      if (!usuario.id) {
        await usuarioRepository
          .cadastrarUsuario({
            nome: usuario.nome,
            email: usuario.email,
            senha: usuario.senha,
            status: status,
            grupoId: grupoSelect,
          })
          .then(() => {
            setIserror(false);
            setUsuario(usuarioInicial);
            toast.success(i18n.t("toastSuccess.usuarioCad"));
          })
          .catch((e) => toast.error(e.message));
      } else {
        await usuarioRepository
          .atualizarUsuario(
            {
              id: usuario.id,
              nome: usuario.nome,
              email: usuario.email,
              senha: usuario.senha,
              status: status,
              grupoId: grupoSelect,
            },
            usuario.id
          )
          .then(() => {
            setIserror(false);
            if (data.my === true) {
              toast.success(i18n.t("toastSuccess.reLogar"));
              sessionStorage.clear();
              history.push(`/${tenant}/login`);
            } else {
              toast.success(i18n.t("toastSuccess.usuarioAtt"));
              listar();
              onClose();
            }
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  async function listarGrupo() {
    await getMapping
      .get(`/grupo/all`)
      .then((res) => {
        setGrupos(res);
      })
      .catch(() => {
        return;
      });
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      listar={listar}
      title={i18n.t("menu.cadUsuario")}
    >
      <div>
        <div className="form-cadastros">
          <br />
          <div>
            {iserror && (
              <Alert severity="error">
                {errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          <Tabs>
            <span title={i18n.t("menu.usuario")}>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-6">
                  <label>{i18n.t("formulario.nome")}</label>
                  <input
                    type="text"
                    name="nome"
                    maxLength="50"
                    value={usuario.nome}
                    onChange={(e) =>
                      setUsuario((prevState) => ({
                        ...prevState,
                        nome: e.target.value,
                      }))
                    }
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(usuario.nome)}/50
                  </p>
                </div>
                <div className="tr-form-group tr-col-sm-6">
                  <label>E-mail</label>
                  <input
                    type="text"
                    name="email"
                    maxLength="100"
                    value={usuario.email}
                    onChange={(e) =>
                      setUsuario((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                  />
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(usuario.email)}/100
                  </p>
                </div>
              </div>
            </span>
            <span title={i18n.t("formulario.senha")}>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-6">
                  <label>{i18n.t("formulario.novaSenha")}</label>
                  <div className="inputPassword">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="confirmaSenha"
                      maxLength={30}
                      value={usuario.senha}
                      onChange={(e) =>
                        setUsuario((prevState) => ({
                          ...prevState,
                          senha: e.target.value,
                        }))
                      }
                    />
                    <span onClick={() => setPasswordVisible(!passwordVisible)}>
                      <i
                        className={
                          passwordVisible
                            ? "mdi mdi-eye icon-size"
                            : "mdi mdi-eye-off icon-size"
                        }
                      />
                    </span>
                  </div>

                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(usuario.senha)}/30
                  </p>
                </div>
                <div className="tr-col-sm-6">
                  <label>{i18n.t("formulario.confirmarSenha")}</label>
                  <div className="inputPassword">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="confirmaSenha"
                      value={usuario.senhaConfirma}
                      maxLength={30}
                      onChange={(e) =>
                        setUsuario((prevState) => ({
                          ...prevState,
                          senhaConfirma: e.target.value,
                        }))
                      }
                    />
                    <span onClick={() => setPasswordVisible(!passwordVisible)}>
                      <i
                        className={
                          passwordVisible
                            ? "mdi mdi-eye icon-size"
                            : "mdi mdi-eye-off icon-size"
                        }
                      />
                    </span>
                  </div>
                  <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                    {TextSize(usuario.senhaConfirma)}/30
                  </p>
                </div>
              </div>
            </span>
          </Tabs>
          {!data.perfil && (
            <div className="tr-row tr-mt-4">
              <div className="tr-form-group tr-col-sm-6">
                <AutoCompleteLabel
                  id="status"
                  options={options}
                  value={selectStatus}
                  onChangeText={setSelectStatus}
                  label={i18n.t("formulario.status")}
                  textFieldId="labelFiltro"
                  clearable
                  disabled={data.my}
                />
              </div>
              <div className="tr-form-group tr-col-sm-6">
                <AutoCompleteLabel
                  id="grupo"
                  options={grupos.map(({ nome }) => nome)}
                  value={usuario.grupoNome}
                  onChangeText={(e) =>
                    setUsuario((prevState) => ({
                      ...prevState,
                      grupoNome: e,
                    }))
                  }
                  label={i18n.t("formulario.grupo")}
                  textFieldId="labelFiltro"
                  clearable
                />
              </div>
            </div>
          )}
          <br />
          <ButtonIcon value={i18n.t("button.salvar")} onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  );
}
