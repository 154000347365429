import React from "react";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AuthProvider from "./contexts/auth.context";
import CacheProvider from "./contexts/cache.context";

export default function App() {
  return (
    <>
      <ToastContainer />
      <AuthProvider>
        <CacheProvider>
          <Routes />
        </CacheProvider>
      </AuthProvider>
    </>
  );
}
