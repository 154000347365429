import React, { useEffect, useState } from "react";
import { Certificado, ContainerCertificado, Button } from "./styles";
import services from "../../services";
import { useParams, useHistory } from "react-router-dom";
import { CertificadoPdf } from "../../components/CertificadoLayout/ExportCertificatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { HeaderBackground } from "../../components/CertificadoLayout/HeaderBackground";
import { HeaderLogo } from "../../components/CertificadoLayout/HeaderLogo";
import { CardTitle } from "../../components/CertificadoLayout/CardTitle";
import { ContentCard } from "../../components/CertificadoLayout/ContentCard";
import { SignatureCard } from "../../components/CertificadoLayout/SignatureCard";
import { FooterBackground } from "../../components/CertificadoLayout/FooterBackground";
import { FooterText } from "../../components/CertificadoLayout/FooterText";
import { DetailsContent } from "../../components/CertificadoLayout/DetailsContent";
import { toast } from "react-toastify";

export default function ConsultaCertificado() {
  const { tenant, hashcertificado } = useParams();
  const [certificado, setCertificado] = useState(null);
  const [verso, setVerso] = useState(false);
  const history = useHistory();

  useEffect(() => {
    services
      .getSemToken(`/buscacertificado/${tenant}/${hashcertificado}`)
      .then((data) => {
        if (data) {
          const { headerType, logo, background, headerStyle } = data.header;
          const {
            bodyTitle,
            bodyTitleStyle,
            bodyDescription,
            bodyAddress,
            bodyColor,
            bodyDescriptionStyle,
            bodyAddressStyle,
            bodyCodigo,
            bodyCodigoStyle,
          } = data.body;
          const {
            footerType,
            footerText,
            footerBackground,
            footerBackgroudColor,
            footerTextStyle,
          } = data.footer;
          const {
            verseTitle,
            verseDetails,
            verseTitleStyle,
            verseDetailsStyle,
            verseType,
            verseImg,
          } = data.verse;

          setCertificado({
            header: {
              headerType,
              logo,
              background,
              headerStyle: JSON.parse(headerStyle),
            },
            body: {
              bodyTitle,
              bodyTitleStyle: JSON.parse(bodyTitleStyle),
              bodyDescription,
              bodyAddress,
              bodyColor,
              bodyDescriptionStyle: JSON.parse(bodyDescriptionStyle),
              bodyAddressStyle: JSON.parse(bodyAddressStyle),
              bodyCodigo,
              bodyCodigoStyle: JSON.parse(bodyCodigoStyle),
            },
            footer: {
              footerType,
              footerText,
              footerBackground,
              footerBackgroudColor,
              footerTextStyle: JSON.parse(footerTextStyle),
            },
            verse: {
              verseTitle,
              verseDetails,
              verseTitleStyle: JSON.parse(verseTitleStyle),
              verseDetailsStyle: JSON.parse(verseDetailsStyle),
              verseType,
              verseImg,
            },
            signatures: data.signatures,
            url: `https://4learn.softensistemas.com.br/${tenant}/${hashcertificado}`,
          });
        }
      })
      .catch((e) => {
        toast.error(e.message);
        history.push(`/${tenant}/404`);
      });
  }, []);

  return (
    <>
      <ContainerCertificado>
        <Certificado>
          {certificado && (
            <>
              {!verso ? (
                <>
                  <ul>
                    <li className="certificado-topo">
                      {certificado.header.headerType === "Background" ? (
                        <HeaderBackground
                          background={certificado.header.background}
                        />
                      ) : (
                        <HeaderLogo
                          headerLogo={{
                            logo: certificado.header.logo,
                            style: certificado.header.headerStyle,
                          }}
                        />
                      )}
                    </li>

                    <li className="certificado-content">
                      <div>
                        <CardTitle
                          contentTitle={{
                            title: certificado.body.bodyTitle,
                            titleStyle: certificado.body.bodyTitleStyle,
                          }}
                        />
                        <ContentCard
                          contentDescription={{
                            description: certificado.body.bodyDescription,
                            descriptionStyle:
                              certificado.body.bodyDescriptionStyle,
                            address: certificado.body.bodyAddress,
                            addressStyle: certificado.body.bodyAddressStyle,
                            color: certificado.body.bodyColor,
                          }}
                        />
                      </div>
                      <div className="containerAssignatures">
                        {certificado.signatures.map((index, indice) => (
                          <SignatureCard signature={index} key={indice} />
                        ))}
                      </div>
                    </li>

                    <li className="certificado-footer">
                      {certificado.footer.footerType === "Background" ? (
                        <FooterBackground
                          background={certificado.footer.footerBackground}
                        />
                      ) : (
                        <FooterText
                          footerText={{
                            text: certificado.footer.footerText,
                            backgroudColor:
                              certificado.footer.footerBackgroudColor,
                            textStyle: certificado.footer.footerTextStyle,
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <ul>
                    <li className="certificado-verso">
                      <DetailsContent verse={certificado.verse} />
                      <a className="url">{certificado.url || ""}</a>
                    </li>
                  </ul>
                </>
              )}
            </>
          )}
        </Certificado>

        <div className="areaButton">
          <div></div>
          {certificado && (
            <PDFDownloadLink
              document={<CertificadoPdf certificate={certificado} />}
              fileName="certificado.pdf"
            >
              {({ loading }) =>
                loading ? "Carregando..." : <Button>Baixar Certificado</Button>
              }
            </PDFDownloadLink>
          )}
          <a onClick={() => setVerso(!verso)}>
            <i
              className={verso ? "mdi mdi-arrow-left" : "mdi mdi-arrow-right"}
            />
          </a>
        </div>
      </ContainerCertificado>
    </>
  );
}
