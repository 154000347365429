import URL_BACKEND from "../../config/back-end";

const URL_USUARIO = `${URL_BACKEND}/usuarios`;
const URL_USUARIO_VIDEO = `${URL_BACKEND}/usuariovideo`;
const id = sessionStorage.getItem("id");

function listarUsuarioPorStatus(id) {
  return fetch(`${URL_USUARIO}/${id}`, {
    method: "GET",

    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
    },
  })
    .then(async (response) => {
      const res = await response.json();
      if (response.ok) {
        return res;
      } else if (response.status === 403) {
        sessionStorage.clear();
        window.location.reload();
      }

      throw new Error(res.codigo + " : " + res.mensagem);
    })
    .catch((err) => {
      return err;
    });
}
function cadastrarUsuario(object) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_USUARIO}`, {
      method: "POST",
      body: JSON.stringify(object),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
      },
    })
      .then(async(response) => {
        const res =  await response.json();
        if (response.ok) {
          resolver(true);
        } else if (response.status === 403) {
          sessionStorage.clear();
          window.location.reload();
        } else {
          reject(new Error(res.codigo + " : " + res.mensagem));
        }
      })
      .catch((err) => reject(err));
  });
}

function buscaPerfilUsuario() {
  return fetch(`${URL_USUARIO}/perfil`, {
    method: "GET",

    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
    },
  })
    .then(async (response) => {
      if (response.ok) {
        const res = await response.json();
        return res;
      } else if (response.status === 403) {
        sessionStorage.clear();
        window.location.reload();
      }

      throw new Error("Error Unknow");
    })
    .catch(() => {
      return;
    });
}


function atualizarUsuario(object, id) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_USUARIO}/${id}`, {
      method: "PUT",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
      },
      body: JSON.stringify(object),
    })
      .then((response) => {
        if (response.ok) {
          resolver(true);
        } else if (response.status === 403) {
          sessionStorage.clear();
          window.location.reload();
        } else {
          reject(new Error("Erro ao atualizar registro"));
        }
      })
      .catch((err) => reject(err));
  });
}

function deletarUsuario(id) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_USUARIO}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
      },
    })
      .then((response) => {
        if (response.ok) {
          resolver(true);
        } else if (response.status === 403) {
          sessionStorage.clear();
          window.location.reload();
        } else {
          reject(new Error("Não é possivel excluir esse registro"));
        }
      })
      .catch((err) => reject(err));
  });
}

function salvarVideoAssistido(object) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_USUARIO_VIDEO}`, {
      method: "POST",
      body: JSON.stringify(object),
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
      },
    })
      .then((response) => {
        if (response.ok) {
          resolver(true);
        } else if (response.status === 403) {
          sessionStorage.clear();
          window.location.reload();
        } else {
          reject(new Error("Erro"));
        }
      })
      .catch((err) => reject(err));
  });
}
function listarVideosAssistidos() {
  sessionStorage.removeItem("check");
  fetch(`${URL_USUARIO_VIDEO}/` + id, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
    },
  })
    .then(async (response) => {
      if (response.ok) {
        const res = await response.json();
        if (res) {
          sessionStorage.setItem("check", JSON.stringify(res));
        }
        return res;
      } else if (response.status === 403) {
        sessionStorage.clear();
        window.location.reload();
      }

      throw new Error("Error Unknow on register");
    })

    .catch(() => {

      return;
    });
}

export default {
  cadastrarUsuario,
  listarUsuarioPorStatus,
  deletarUsuario,
  salvarVideoAssistido,
  listarVideosAssistidos,
  buscaPerfilUsuario,
  atualizarUsuario,
};
