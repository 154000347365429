import React, { useState, useEffect } from "react";
import getMapping from "../../repositories/getMapping";
import logo from "../../components/img/logo/image.png";
import Footer from "../../components/Footer";
import {
  Box,
  AreaLogo,
  TituloBox,
  AreaTexto,
  Botao,
  AreaBotao,
  Icon,
  AreaBox,
  BotaoLink,
  AreaLink,
  TituloLink,
  SmallText,
} from "./styles";
import { toast } from "react-toastify";
import AutoCompleteLabel from "../../components/AutoComplete";

let url4Learn = "https://4learn.softensistemas.com.br";
// let url4Learn = "http://localhost:3000"
export default function Principal() {
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState("");

  useEffect(() => {
    listarEmpresas();
  }, []);

  function listarEmpresas() {
    getMapping
      .getFree("/empresa")
      .then((data) => {
        setEmpresas(data);
      })
      .catch((e) => console.log(e.message));
  }
  const chosenEmpresas = empresas
    ? empresas.find((empresas) => {
        return empresas.nomeEmpresa === empresa;
      })
    : "";

  function irEmpresa() {
    if (chosenEmpresas !== undefined) {
      window.open(`${url4Learn}${chosenEmpresas.tenantClientId}`);
    } else {
      toast.error("Empresa não encontrada!");
    }
  }

  return (
    <div>
      <AreaLogo>
        <div className="imagem">
          <img
            className="logo-ga"
            alt="Logo "
            title="Logo "
            width="80px"
            src={logo}
          />
        </div>
        <span>Plataforma 4Learn</span>
      </AreaLogo>

      <AreaBox>
        <h1>Login para Empresas</h1>
        <Box>
          <TituloBox>Pesquise a sua empresa</TituloBox>
          <AreaTexto>
            <div>
              <AutoCompleteLabel
                id="empresa"
                options={empresas.map(({ nomeEmpresa }) => nomeEmpresa)}
                value={empresa}
                onChangeText={setEmpresa}
                label="Nome da empresa"
                textFieldId="Nome da empresa"
                // labelavel
              />

              <SmallText
                cor={chosenEmpresas !== undefined ? "#1b4473" : "#606060"}
              >
                {chosenEmpresas !== undefined
                  ? `https://4learn.softensistemas.com.br` +
                    chosenEmpresas.tenantClientId
                  : `https://4learn.softensistemas.com.br`}
              </SmallText>
            </div>
          </AreaTexto>

          <AreaBotao>
            <Botao onClick={irEmpresa}>
              Continuar
              <Icon className="mdi mdi-arrow-right" />
            </Botao>
          </AreaBotao>
        </Box>

        <AreaLink>
          <TituloLink>Ainda não tem a 4Learn na sua empresa ?</TituloLink>

          <BotaoLink
            onClick={() => {
              window.open("https://www.softensistemas.com.br/soften4learn");
            }}
          >
            Conheça nosso produto
          </BotaoLink>
        </AreaLink>
      </AreaBox>

      <Footer style={{ position: "absolute" }} />
    </div>
  );
}
