import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth.context";
import DefaultLogin from "../../template/DefaultLogin";
import FormField from "../../components/FormField";
import Loading from "../../components/Loading";
import Button from "../../components/Button/";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import service from "../../services";
import "../../components/FormField";
import "../Login/login.scss";

export default function RedefinirSenha() {
  const { tenant, code } = useParams();
  const { emailAtual, setEmailAtual, signIn } = useContext(AuthContext);
  let initialFormulario = {
    usuarioId: "",
    email: emailAtual,
    codigo: "",
    senha: "",
    confirmaSenha: "",
    nome: "",
  };
  const history = useHistory();
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [stage, setStage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formulario, setFormulario] = useState(initialFormulario);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);

  useEffect(() => {
    if (code) {
      verificarPrimeiroAcesso();
      setPrimeiroAcesso(true);
    }
  }, []);

  async function verificarPrimeiroAcesso() {
    setLoading(true);
    await service
      .postAuth(
        {
          codigo: code,
        },
        `/recuperarsenha/confirmar/${tenant}`
      )
      .then((data) => {
        setLoading(false);
        setFormulario((prevState) => ({
          ...prevState,
          usuarioId: data.usuarioId,
          codigo: data.codigo,
        }));
        setStage(2);
      })
      .catch(() => {
        setLoading(false);
        history.push(`/${tenant}/login`);
      });
  }

  async function recuperarsenha() {
    let errorList = [];
    if (!formulario.email.trim()) {
      errorList.push("Insira um e-mail válido");
      setErrorMessages(errorList);
      setIserror(true);
      return;
    }

    if (errorList.length < 1) {
      setLoading(true);
      await service
        .postAuth(
          {
            email: formulario.email,
          },
          `/recuperarsenha/${tenant}`
        )
        .then(() => {
          setLoading(false);
          setStage(1);
          setEmailAtual(formulario.email);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    }
  }

  async function confirmarCodigo() {
    let errorList = [];
    if (!formulario.codigo.trim() && formulario.codigo.length < 5) {
      errorList.push("Insira um código válido");
      setErrorMessages(errorList);
      setIserror(true);
      return;
    }

    if (errorList.length < 1) {
      setLoading(true);
      await service
        .postAuth(
          {
            codigo: formulario.codigo,
            email: formulario.email,
          },
          `/recuperarsenha/confirmar/${tenant}`
        )
        .then((data) => {
          setLoading(false);
          setFormulario((prevState) => ({
            ...prevState,
            usuarioId: data.usuarioId,
            codigo: data.codigo,
          }));
          setStage(2);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    }
  }

  async function redefinirSenha() {
    let errorList = [];

    if (primeiroAcesso && !formulario.nome.trim()) {
      errorList.push("Insira um nome válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (formulario.senha !== formulario.confirmaSenha) {
      errorList.push("Senhas não se coincidem");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (formulario.senha.length < 5) {
      errorList.push("Insira uma senha de 5 a 30 caracteres!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      setLoading(true);
      await service
        .postAuth(
          {
            codigo: formulario.codigo,
            usuarioId: formulario.usuarioId,
            novaSenha: formulario.senha,
            nome: formulario.nome,
          },
          `/recuperarsenha/redefinir/${tenant}`
        )
        .then((data) => {
          setLoading(false);
          toast.success("Senha alterada com sucesso");
          setFormulario(initialFormulario);
          setEmailAtual(data.email);
          setStage(0);
          signIn({
            email: data.email,
            senha: formulario.senha,
            tenant: "/" + tenant + "/login",
          }).then((data) => {
            if (data) {
              return history.push(data);
            }
          });
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
      setIserror(false);
    }
  }

  return (
    <DefaultLogin>
      <div className="form-login">
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <h1>
              {primeiroAcesso ? "Confirmar seus dados" : "Redefinir senha"}{" "}
            </h1>
          </div>
        </div>
        {loading && <Loading title="container-loading-login" />}
        <div style={{ paddingBottom: 5 }}>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        {primeiroAcesso ? (
          <>
            <>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <FormField
                    label="Nome"
                    type="text"
                    name="nome"
                    maxLength={255}
                    value={formulario.nome}
                    onChange={(e) =>
                      setFormulario((prevState) => ({
                        ...prevState,
                        nome: e.target.value,
                      }))
                    }
                  ></FormField>
                </div>
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <label>Senha</label>
                  <div className="inputPassword">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="senha"
                      maxLength={30}
                      value={formulario.senha}
                      onChange={(e) =>
                        setFormulario((prevState) => ({
                          ...prevState,
                          senha: e.target.value,
                        }))
                      }
                    />
                    <span onClick={() => setPasswordVisible(!passwordVisible)}>
                      <i
                        className={
                          passwordVisible
                            ? "mdi mdi-eye icon-size"
                            : "mdi mdi-eye-off icon-size"
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <label>Confirmar nova senha</label>
                  <div className="inputPassword">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="confirmaSenha"
                      value={formulario.confirmaSenha}
                      maxLength={30}
                      onChange={(e) =>
                        setFormulario((prevState) => ({
                          ...prevState,
                          confirmaSenha: e.target.value,
                        }))
                      }
                    />
                    <span onClick={() => setPasswordVisible(!passwordVisible)}>
                      <i
                        className={
                          passwordVisible
                            ? "mdi mdi-eye icon-size"
                            : "mdi mdi-eye-off icon-size"
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="tr-row">
                <div className="tr-form-group tr-col-sm-12">
                  <Button className="botao-login" onClick={redefinirSenha}>
                    Confirmar
                  </Button>
                </div>
              </div>
            </>
          </>
        ) : (
          <>
            {stage === 0 && (
              <>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <FormField
                      label="Confirme seu e-mail"
                      type="text"
                      name="email"
                      maxLength={255}
                      value={formulario.email}
                      onChange={(e) =>
                        setFormulario((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                    ></FormField>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-6">
                    <span
                      className="esqueciSenha"
                      onClick={() => history.goBack()}
                    >
                      Ir para o login
                    </span>
                  </div>
                  <div className="tr-form-group tr-col-sm-6">
                    <Button className="botao-login" onClick={recuperarsenha}>
                      Confirmar
                    </Button>
                  </div>
                </div>
              </>
            )}
            {stage === 1 && (
              <>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <span>Código enviado para {formulario.email}</span>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <FormField
                      label="Insira o código de verificação"
                      type="text"
                      name="codigo"
                      maxLength={6}
                      value={formulario.codigo}
                      onChange={(e) =>
                        setFormulario((prevState) => ({
                          ...prevState,
                          codigo: e.target.value,
                        }))
                      }
                    ></FormField>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-6">
                    <span
                      className="esqueciSenha"
                      onClick={() => history.goBack()}
                    >
                      Ir para o login
                    </span>
                  </div>
                  <div className="tr-form-group tr-col-sm-6">
                    <Button className="botao-login" onClick={confirmarCodigo}>
                      Confirmar
                    </Button>
                  </div>
                </div>
              </>
            )}
            {stage === 2 && (
              <>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>Nova Senha</label>
                    <div className="inputPassword">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="senha"
                        maxLength={30}
                        value={formulario.senha}
                        onChange={(e) =>
                          setFormulario((prevState) => ({
                            ...prevState,
                            senha: e.target.value,
                          }))
                        }
                      />
                      <span
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <i
                          className={
                            passwordVisible
                              ? "mdi mdi-eye icon-size"
                              : "mdi mdi-eye-off icon-size"
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-12">
                    <label>Confirmar nova senha</label>
                    <div className="inputPassword">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="confirmaSenha"
                        value={formulario.confirmaSenha}
                        maxLength={30}
                        onChange={(e) =>
                          setFormulario((prevState) => ({
                            ...prevState,
                            confirmaSenha: e.target.value,
                          }))
                        }
                      />
                      <span
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        <i
                          className={
                            passwordVisible
                              ? "mdi mdi-eye icon-size"
                              : "mdi mdi-eye-off icon-size"
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tr-row">
                  <div className="tr-form-group tr-col-sm-6">
                    <span
                      className="esqueciSenha"
                      onClick={() => history.goBack()}
                    >
                      Ir para o login
                    </span>
                  </div>
                  <div className="tr-form-group tr-col-sm-6">
                    <Button className="botao-login" onClick={redefinirSenha}>
                      Confirmar
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </DefaultLogin>
  );
}
