import React, { useState } from "react";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import useForm from "../../../hooks/useForm";
import { MODULO_ARQUIVO } from "../../../config/endpoint";
import uploadArquivos from "../../../repositories/uploadArquivos";
import deleteMapping from "../../../repositories/deleteMapping";
import downlaodArquivos from "../../../js/downloadArquivos";
import { toast } from "react-toastify";
import { i18n } from "../../../translate/i18n";

export default function FormArquivo(props) {
  const token = sessionStorage.getItem("app-token");
  const [file, setFile] = useState([]);
  let initialData = {
    arquivo: "",
  };
  const { values } = useForm(initialData);
  const uploadImage = (e) => {
    if (e.target.files[0].size <= 10485760) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Aviso: Upload com limite de 10MB!");
    }
  };

  function deletar(e) {
    deleteMapping
      .deletar(`${MODULO_ARQUIVO}/${e}`)
      .then(() => {
        toast.success("Deletado com sucesso");
        props.listarArquivos();
      })
      .catch((e) => toast.error(e.message));
  }

  function uploadArquivo() {
    if (file.size > 1) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      let formdata = new FormData();
      formdata.append("file", file, file.name);
      formdata.append("moduloId", props.moduloId);
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      uploadArquivos
        .upload(requestOptions, MODULO_ARQUIVO)
        .then((data) => {
          setFile("");
          props.listarArquivos();
          toast.success("Cadastrado com sucesso");
        })
        .catch((e) => toast.error(e.message));
    }
  }

  return (
    <div className="form-cadastros largura-padrao">
      <h2>Upload {i18n.t("formulario.arquivos")}</h2>
      <div className="tr-row">
        <div className="tr-form-group file-content tr-col-sm-5">
          <FormField
            label={
              i18n.t("formulario.arquivos") +
              " (.pdf /.txt /.doc /.xls /.jpeg /.png /.svg /.jpg / .pptx) 10MB"
            }
            accept=".jpeg, .png, .jpg, .svg, .pdf, .txt, .doc, .xls, .pptx"
            type="file"
            name="arquivo"
            value={values.arquivo}
            onChange={(e) => {
              uploadImage(e);
            }}
          />
          <div className="label-files pointer-cursor">
            <label className="primary-button-file" htmlFor="id_arquivo">
              {i18n.t("formulario.selecionarArq")}
            </label>
            <label>
              {file.name ? file.name : i18n.t("formulario.zeroArq")}
            </label>
          </div>
        </div>
        <div className="tr-form-group tr-col-sm-2"></div>

        <div className="tr-form-group tr-col-sm-5">
          <label>{i18n.t("treinamentoPainel.arquivosAnexo")}</label>
          {props.arquivos.map((item, index) => (
            <div key={index} className="tr-row">
              {[item.nome].map((texto, index) => (
                <span
                  key={index}
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  <i
                    className="mdi mdi-file-multiple icon-size"
                    onClick={() =>
                      downlaodArquivos.DownlaodArquivos(
                        `${MODULO_ARQUIVO}/download/${item.id}`
                      )
                    }
                  ></i>
                  {texto}
                  <i
                    className="mdi mdi-trash-can-outline icon-size delete-table"
                    onClick={() => deletar(item.id)}
                  ></i>
                </span>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="tr-row">
        <div className="tr-form-group tr-col-sm-5">
          <Button onClick={() => uploadArquivo()}>
            {i18n.t("button.salvar")}
          </Button>
        </div>
      </div>
    </div>
  );
}
