const messages = {
  en: {
    translations: {
      menu: {
        perfil: "Edit Profile",
        configEmpresa: "Company Settings",
        cadCurso: "Course Registration",
        cadModulo: "Module Registration",
        cadInstrutor: "Instructor Registration",
        cadProva: "Test Registration",
        cadUsuario: "User Registration",
        cadGrupo: "Group Registration",
        usuarios: "Users",
        sair: "Logout",
        ola: "Hello",
        perfilUsuario: "User Profile",
        cadPergunta: "Registration questions",
        cadConteudo: "Content registration",
        usuario: "User",
        consultas: "Search",
        meuscursos: "My courses",
      },
      dashboard: {
        botaoDestaque: "SEE COURSES",
        cursosLivres: "Free Courses",
        cursosExclusivos: "Exclusive courses",
      },
      button: {
        salvar: "Save",
        novo: "New",
        listar: "List",
        sim: "Yes",
        nao: "No",
        imprimir: "Print out",
        gerar: "Generate",
        consultar: "Consult",
        proximaAtividade: "Next activity",
      },
      login: {
        email: "E-mail",
        senha: "Password",
        entrar: "Enter",
      },
      formulario: {
        titulo: "Title",
        descricao: "Description",
        cor: "Color",
        corTitulo: "Title color",
        corDescricao: "Description color",
        corBotao: "Cor botão",
        corTextoBotao: "Button text color",
        corBotaoMenu: "Menu button color",
        corIlustracao: "Color illustration",
        status: "Status",
        thumbnail: "Thumbnail",
        icone: "Icon",
        curso: "Course",
        instrutor: "Instructor",
        preRequisito: "Prerequisite (Optional)",
        resetMod: "Reset module for user",
        nome: "Name",
        novaSenha: "New password",
        confirmarSenha: "Confirm new password",
        usuario: "User",
        senha: "Password",
        tituloVideo: "Video Title",
        urlVideo: "Url Video",
        tamanhoLogo: "125X75 pixels. less than 300kb",
        tamanhoImg300: "Less than 300kb",
        tamanhoBackground: "1920X990 pixels. less than 300kb",
        tamanhoIconeCurso: "50X50 pixels. less than 300kb",
        tamanhothumbMod: "300x160 pixels. less than 300kb",
        tamanhoBgCertificado: "1754x186 pixels. less than 300kb",
        pergunta: "Question",
        alternativa: "Alternative",
        exclusivo: "EXCLUSIVE",
        gratuito: "FREE",
        encerrado: "CLOSED",
        certificado: "Backgorund Certified",
        layoutCertificado: "Certified Layout",
        tempoEstimado: "Estimated duration (min)",
        ativo: "Active",
        selecione: "Select",
        cursoModulo: "Course/Module (no linked test)",
        mediaaprovacao: "Approval media",
        selecioneCorreta: "Select correct alternative",
        configUsuario: "Configure User",
        modulosCompletado: "Completed modules",
        selecionarImg: "Select image",
        selecionarArq: "Select file",
        zeroImg: "No image selected",
        zeroArq: "No file selected",
        grupo: "Group",
        videoAula: "Video lessons",
        arquivos: "Files",
        filtro: "Filter",
        todosGrupos: "All groups",
      },
      consulta: {
        tituloCurso: "Course Title",
        cursosDisp: "Public Courses",
        cursosExcl: "Exclusives Courses",
        cursosEnc: "Closed Courses",
        todosCurs: "All Courses",
        selecioneCurs: "Select the course",
        nomeInst: "Instructor Name",
        nomeUsuario: "Username",
        nomeGrupo: "Group Name",
        ativo: "Active",
        bloqueado: "Inactive",
        relatorio: "Report",
      },
      tabela: {
        codigo: "Code",
        inscritos: "Subscribers",
        opcoes: "Options",
        tituloModulo: "Modulo Title",
        conteudo: "Content",
        modulo: "Module",
        concluidos: "completed",
        data: "Date",
        notaAprovação: "Approval note",
        tentativas: "Attempts",
        zeroRegistros: "No record found.",
        tentativa: "Attempt",
        cursoModulo: "Course / Module",
        nomeEmail: "Name / Email",
        perguntaProva: "Questions for test",
      },
      toastSuccess: {
        dashboardAtt: "Updated Dashboard.",
        certificadoAtt: "Updated Certificate.",
        cursoAtt: "Updated Course.",
        cursoCad: "Registered Course.",
        cursoDel: "Deleted Course.",
        moduloCad: "Registered Module.",
        moduloAtt: "Updated Module.",
        modulodel: "Deleted Module",
        aulaCad: "Registered class.",
        aulaAtt: "Updated class.",
        aulaDel: "Class excluded",
        provaCad: "Registered Test.",
        provaAtt: "Updated Test.",
        provaDel: "Deleted Test",
        perguntaCad: "Registered Question.",
        perguntaAtt: "Updated Question.",
        perguntaDel: "Deleted Question.",
        usuarioCad: "Registered User.",
        usuarioAtt: "Updated User.",
        usuarioDel: "Deleted User",
        instrutorCad: "Registered Instructor.",
        instrutorAtt: "Updated Instructor.",
        instrutorDel: "Deleted Instructor.",
        grupoCad: "Registered Group",
        grupoAtt: "Updated Group",
        grupoDel: "Deleted Group",
        reLogar: "Updated user, log in again.",
        certificadoCad: "Certificate registered successfully!",
      },
      toastInfo: {
        aprovadoMod: "You have already passed the Module.",
      },
      toastWarning: {
        cursoVazio: "WARNING: No modules registered in the course.",
        modVazio: "WARNING: Module without registered video.",
        modBlock: "WARNING: Blocked module.",
        blockProva: "WARNING: You must complete all classes before proceeding.",
        zeroCurso: "WARNING: No courses found in consultation.",
        zeroModulo: "WARNING: No modules found in the query.",
        zeroUsuario: "WARNING: No users found in the query.",
        zeroInstrutor: "WARNING: No instructors found in consultation.",
        zeroProva: "WARNING: No tests found in query.",
        zeroGrupo: "WARNING: No groups found in the query.",
        provaIndisp: "WARNING: Test not available.",
        zeroConsulta: "WARNING: No record found.",
        limiteAssinaturas: "Attention: Signatures limit reached.",
      },
      toastError: {
        catactereConsul: "At least 3 characters are required in the query.",
        semResp: "There are unanswered questions.",
        preenchimentoErro: "invalid fill.",
      },
      treinamentoPainel: {
        iniciarAula: "Start class!",
        textoIniciar: "Click on start and good studies!",
        iniciar: "Start",
        descVideo: "Video description",
        prova: "Evaluation test",
        historico: "History",
        notas: "Note History",
        nota: "Note",
        emitirCert: "Issue Certificate",
        arquivosAnexo: "Attached files",
        acertos: "Hits",
        descricaoAula: "Class description",
      },
      footer: {
        direitos: "All rights reserved",
      },
      idioma: {
        idioma: "Language",
        portugues: "Portuguese",
        ingles: "English",
        espanhol: "Spanish",
      },
      relatorio: {
        relatorio1: "Subscribed to the module",
        relatorio2: "Test results by module",
        relatorio3: "Student test",
        relatorio4: "Student history in module",
        relatorio5: "General student history",
        relatorio6: "General history of the group",
        cursoModulo: "Course / Module",
        totalInscritos: "Total subscribers",
        usuario: "Users",
        dataInscricao: "Registration date",
        progresso: "Progress",
        totalTentativa: "Total attempts",
        notaAprovacao: "Approval note",
        media: "Average",
        dataRealizacao: "Date of realization",
        nota: "Note",
        aprovado: "Approved",
        reprovado: "Disapproved",
        mediaAprovacao: "Approval average",
        tentativa: "Attempt",
        acerto: "Hit",
        data: "Date",
        alternativaCorreta: "Correct alternative",
        respostaAluno: "Student response",
        grupo: "Group",
        modulo: "Module",
        curso: "Course",
      },
      certificado: {
        editor: "Certificate Editor",
        edicao: "Certified Edition",
        frente: "Front",
        verso: "Verse",
        digiteNome: "Enter a name",
        header: "Header",
        tipo: "Type",
        alinharIcon: "Align Icon",
        alinharTexto: "Align Text",
        titulo: "Title",
        fonte: "Font",
        tamanhoFonte: "Font size",
        conteudo: "Contents",
        descricaoCertificado: "Description (Variable suggestions @)",
        endereco: "Address",
        assinatura: "Signature",
        backgroundColor: "Background Color",
        textColor: "Text Color",
        texto: "Text",
        esquerda: "Left",
        direita: "Right",
        centro: "Center",
      },
      iconsTitle: {
        editarCurso: "Edit Course",
        excluir: "Delete",
        excluirCurso: "Delete Course",
        resetarMod: "Reset Module",
        editarMod: "Edit Module",
        excluirMod: "Delete Module",
      },
    },
  },
};

export { messages };
