import React, { useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import logo from "../img/logo/not.png";
import MenuDefault from "./MenuDefault";
import "./index.scss";

export default function MenuLateral({ children }) {
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));
  const { tenant } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [subMenu, setSubMenu] = useState({ cadastros: false });

  function toggleMenu() {
    const templateVideo = document.getElementsByClassName("template-video")[0];
    if (templateVideo.classList.contains("menu-fechado")) {
      templateVideo.classList.remove("menu-fechado");
    } else {
      templateVideo.classList.add("menu-fechado");
    }
  }
  return (
    <div>
      <MenuDefault menuLateral />
      <div className="lista">
        <ul className="collection-menu-lateral">
          <li className="container-logo-ga">
            <img src={dash.logo || logo} alt="logo" className="logo-ga" />
            <span className="container-logo-ga-hamburguer" onClick={toggleMenu}>
              <i className="mdi mdi-menu" />
            </span>
          </li>
          <li
            className="collection-menu-lateral-item"
            onClick={() => {
              setSubMenu((prevState) => ({
                ...prevState,
                cadastros: !subMenu.cadastros,
              }));
            }}
          >
            <div
              className={
                location.pathname.includes("cadastro")
                  ? "list-item item-active"
                  : "list-item"
              }
            >
              <div>
                <i className="mdi mdi-plus-box-multiple" />
                <a>Cadastros</a>
              </div>
              <span>
                <i
                  className={
                    subMenu.cadastros
                      ? "mdi mdi-chevron-down"
                      : "mdi mdi-chevron-right"
                  }
                />
              </span>
            </div>
          </li>
          {subMenu.cadastros && (
            <div className="list-sub-menu">
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/curso`)}
              >
                <div className="list-item">
                  <div>
                    <a>Curso</a>
                  </div>
                </div>
              </li>
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/modulo`)}
              >
                <div className="list-item">
                  <a>Módulo</a>
                </div>
              </li>
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/certificado`)}
              >
                <div className="list-item">
                  <a>Certificado</a>
                </div>
              </li>
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/usuario`)}
              >
                <div className="list-item">
                  <a>Usuário</a>
                </div>
              </li>
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/instrutor`)}
              >
                <div className="list-item">
                  <a>Instrutor</a>
                </div>
              </li>
              <li
                className="collection-menu-lateral-item"
                onClick={() => history.push(`/${tenant}/cadastro/grupo`)}
              >
                <div className="list-item">
                  <a>Grupo</a>
                </div>
              </li>
            </div>
          )}
          <li
            className="collection-menu-lateral-item"
            onClick={() => history.push(`/${tenant}/integracoes`)}
          >
            <div
              className={
                location.pathname === `/${tenant}/integracoes` &&
                subMenu.cadastros === false
                  ? "list-item item-active"
                  : "list-item"
              }
            >
              <div>
                <i className="mdi mdi-web" />
                <a>Integrações</a>
              </div>
            </div>
          </li>
          <li
            className="collection-menu-lateral-item"
            onClick={() => history.push(`/${tenant}/consultas`)}
          >
            <div
              className={
                location.pathname === `/${tenant}/consultas` &&
                subMenu.cadastros === false
                  ? "list-item item-active"
                  : "list-item"
              }
            >
              <div>
                <i className="mdi mdi-file-search-outline" />
                <a>Relatorios</a>
              </div>
            </div>
          </li>
          <li
            className="collection-menu-lateral-item"
            onClick={() => history.push(`/${tenant}/config/empresa`)}
          >
            <div
              className={
                location.pathname === `/${tenant}/config/empresa`
                  ? "list-item item-active"
                  : "list-item"
              }
            >
              <div>
                <i className="mdi mdi-office-building" />
                <a>Configurações</a>
              </div>

              {/* <span>
                <i className="mdi mdi-chevron-down" />
              </span> */}
            </div>
          </li>
        </ul>
        <div className="container-video-global">{children}</div>
      </div>
    </div>
  );
}
