import React, { createContext, useState } from "react";

import { toast } from "react-toastify";
import service from "../services";

export const CacheContext = createContext({});

export default function CacheProvider({ children }) {
  const [cursosPublicados, setCursosPublicados] = useState([]);
  const [meusCursos, setMeusCursos] = useState([]);
  const [consulta, setConsulta] = useState(false);
  const [bitmc, setBitmc] = useState(false);

  async function listarCursosPublicados() {
    return await service
      .get("/cursos/publicados")
      .then((data) => {
        setBitmc(true);
        setCursosPublicados(data);
        return data;
      })
      .catch((e) => {
        setBitmc(false);
        toast.error(e.message);
        return [];
      });
  }

  async function listarMeusCursos() {
    return await service
      .get(`/cursos/inscrito`)
      .then((data) => {
        setConsulta(true);
        setMeusCursos(data);
        return data;
      })
      .catch((e) => {
        setConsulta(false);
        toast.error(e.message);
        return [];
      });
  }

  return (
    <CacheContext.Provider
      value={{
        consulta,
        cursosPublicados,
        listarCursosPublicados,
        listarMeusCursos,
        meusCursos,
        bitmc,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
}
