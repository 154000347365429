import { styled } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export const StyledAutocomplete = styled(Autocomplete)({
  "& .custom-autocomplete-label": {
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& .custom-autocomplete-input": {
    border: "1px solid #d2d2d2",
    fontFamily: "Nunito",
    background: "#fff",
    color: "#606060",
    borderRadius: "4px",
    fontSize: "1.6em",
    width: "100%",
    outline: "0",
    "& .MuiAutocomplete-input:first-child": {
      padding: "10px 0 10px 10px",
      height: "24px",
    },
    "& .MuiInput-underline:before": {
      border: "none !important",
    },
    "& .MuiInput-underline:after": {
      border: "none !important",
    },
  },

  "& .custom-autocomplete-inputWLabel": {
    background: "#fff",
    "& .MuiFormLabel-root.Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d2d2d2",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d2d2d2",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d2d2d2",
    },
  },
});
