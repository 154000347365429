export const TextSize = (e) => {
  return e.length;
};

export function copyClipboard(id) {
  let copyText = document.getElementById(id);
  navigator.clipboard.writeText(copyText.children[0].text);
}

export function removeSpace(e) {
  return e.replace(/\s/g, "");
}

export function formatPrice(e) {
  if (e) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(e);
  }
}
