import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ConsultaCertificado from "./pages/ConsultaCertificado";
import CadastroCertificado from "./pages/CadastroCertificado";
import IntegracaoHotmart from "./pages/IntegracaoHotmart";
import { PrivateRoute, Authorithy } from "./PrivateRoute";
import CertificadoLista from "./pages/Lista/Certificado";
import CadastroEmpresa from "./pages/CadastroEmpresa";
import InstrutorLista from "./pages/Lista/Instrutor";
import ConteudoModulo from "./pages/ConteudoModulo";
import PaginaObrigado from "./pages/PaginaObrigado";
import RedefinirSenha from "./pages/RedefinirSenha";
import ConfigEmpresa from "./pages/ConfigEmpresa";
import PerfilUsuario from "./pages/PerfilUsuario";
import UsuarioLista from "./pages/Lista/Usuario";
import CursoDetalhe from "./pages/CursoDetalhe";
import ModuloLista from "./pages/Lista/Modulo";
import Integracoes from "./pages/Integracoes";
import CursoLista from "./pages/Lista/Curso";
import GrupoLista from "./pages/Lista/Grupo";
import MaisCursos from "./pages/MaisCursos";
import Consultas from "./pages/Consultas";
import Principal from "./pages/Principal";
import NotFound from "./NotFound.js";
import Login from "./pages/Login";
import Home from "./pages/Home";
import sala from "./pages/Sala";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Authorithy path="/:tenant/config/empresa" component={ConfigEmpresa} />
        <Authorithy path="/:tenant/cadastro/curso" component={CursoLista} />
        <Authorithy path="/:tenant/cadastro/modulo" component={ModuloLista} />
        <Authorithy
          path="/:tenant/cadastro/instrutor"
          component={InstrutorLista}
        />
        <Authorithy path="/:tenant/cadastro/grupo" component={GrupoLista} />
        <Authorithy path="/:tenant/cadastro/usuario" component={UsuarioLista} />
        <Authorithy
          path="/:tenant/cadastro/certificado"
          component={CertificadoLista}
        />
        <Authorithy
          path="/:tenant/editar/certificado/:certificadoid"
          component={CadastroCertificado}
        />
        <Authorithy path="/:tenant/consultas" component={Consultas} />
        <Authorithy
          path="/:tenant/:cursotitulo/:moduloid/conteudo/:avaliacao/:modulotitulo"
          component={ConteudoModulo}
        />
        <Authorithy path="/:tenant/integracoes" component={Integracoes} />
        <Authorithy
          path="/:tenant/integracao/hotmart"
          component={IntegracaoHotmart}
        />

        <PrivateRoute path="/:tenant" component={Home} exact />
        <PrivateRoute path="/:tenant/dashboard" component={Home} />
        <PrivateRoute
          path="/:tenant/maiscursos/:pesquisaCurso"
          component={MaisCursos}
        />
        <PrivateRoute
          path="/:tenant/curso/:cursoid/:cursotitulo"
          component={CursoDetalhe}
        />
        <PrivateRoute
          path="/:tenant/perfil/usuario"
          component={PerfilUsuario}
        />
        <PrivateRoute
          path="/:tenant/modulos/:moduloid/:modulotitulo/:aula"
          component={sala}
        />

        <Route path="/" component={Principal} exact />
        <Route path="/:tenant/404" component={NotFound} />
        <Route path="/:tenant/login/:proximaRota" component={Login} />
        <Route path="/:tenant/login" component={Login} exact />
        <Route path="/cadastro/empresa" component={CadastroEmpresa} />
        <Route
          path="/:tenant/certificado/:hashcertificado"
          component={ConsultaCertificado}
        />
        <Route path="/:tenant/code=:code" component={RedefinirSenha} />
        <Route path="/:tenant/obrigado" component={PaginaObrigado} />
        <Route
          path="/:tenant/redefinirsenha"
          component={RedefinirSenha}
          exact
        />
        <Redirect from="/:tenant/404" to="/:tenant/404" />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
