import React, { useState, useEffect } from "react";

import AutoCompleteLabel from "../../../components/AutoComplete";
import ConfirmeDialog from "../../../components/ConfirmeDialog";
import { CardMenu } from "../../../components/MenuCardOptions";
import usuarioRepository from "../../../repositories/Usuario";
import getMapping from "../../../repositories/getMapping";
import PageDefault from "../../../template/DefaultPage";
import ButtonIcon from "../../../components/ButtonIcon";
import Loading from "../../../components/Loading";
import "../../../components/css/styleguide.scss";
import { i18n } from "../../../translate/i18n";
import DialogRegistro from "./Dialog/";
import { toast } from "react-toastify";

export const usuarioInicial = {
  id: null,
  nome: "",
  email: "",
  senha: "",
  senhaConfirma: "",
  status: "",
  grupoId: null,
  grupoNome: "",
  my: false,
};
export default function UsuarioLista() {
  const { id } = JSON.parse(sessionStorage.getItem("user"));
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState(usuarioInicial);
  const [openDialogRegistro, setOpenDialogRegistro] = useState(false);
  const [loading, setLoading] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [grupo, setGrupo] = useState(i18n.t("formulario.todosGrupos"));
  const [nomeBusca, setNomeBusca] = useState("");
  const [status, setStatus] = useState(changeStatus("0", false));
  const [opcoes, setOpcoes] = useState(null);

  const [dialogConfirm, setDialogConfirm] = useState(false);

  function changeStatus(e, type) {
    if (type) {
      switch (e) {
        case i18n.t("consulta.ativo"):
          return "0";
        case i18n.t("consulta.bloqueado"):
          return "1";
        default:
          return "0";
      }
    } else {
      switch (e) {
        case "0":
          return i18n.t("consulta.ativo");
        case "1":
          return i18n.t("consulta.bloqueado");
        default:
          return i18n.t("consulta.ativo");
      }
    }
  }

  const options = [i18n.t("consulta.ativo"), i18n.t("consulta.bloqueado")];

  const handleBusca = () => {
    listar();
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBusca();
    }
  };
  useEffect(() => {
    listarGrupo();
  }, []);

  async function listarGrupo() {
    await getMapping.get(`/grupo/all`).then((data) => {
      setGrupos([
        {
          id: 0,
          nome: i18n.t("formulario.todosGrupos"),
        },
        ...data,
      ]);
    });
  }

  async function listar() {
    let usuarioStatus = changeStatus(status, true);

    const chosenGrupo = grupos.find((grup) => {
      return grup.nome === grupo;
    });

    if (nomeBusca.trim()) {
      if (nomeBusca.length >= 3) {
        setLoading(true);
        await usuarioRepository
          .listarUsuarioPorStatus(
            `${usuarioStatus}/${nomeBusca
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}/${chosenGrupo.id}`
          )
          .then((data) => {
            if (data.length < 1) {
              toast.warning(i18n.t("toastWarning.zeroUsuario"));
            }
            setUsuarios(data);
            setLoading(false);
          })
          .catch((e) => toast.error(e.message));
      } else {
        toast.error(i18n.t("toastError.catactereConsul"));
      }
    } else {
      setLoading(true);
      await usuarioRepository
        .listarUsuarioPorStatus(`${usuarioStatus}/all/${chosenGrupo.id}`)
        .then((data) => {
          setUsuarios(data);
          setLoading(false);
        })
        .catch((e) => toast.error(e.message));
    }
  }

  const handleDelete = () => {
    usuarioRepository
      .deletarUsuario(usuario.id)
      .then(() => {
        setDialogConfirm(false);
        listar();
        toast.success(i18n.t("toastSuccess.usuarioDel"));
      })
      .catch((e) => toast.error(e.message));
  };

  const handleClick = () => {
    setOpenDialogRegistro(true);
    setUsuario(usuarioInicial);
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th className="text-center">{i18n.t("tabela.codigo")}</th>
          <th>{i18n.t("formulario.nome")}</th>
          <th>E-mail</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    return (
      <tbody>
        {usuarios.map((item, index) => (
          <tr key={item.id}>
            <td data-title="Código" className="text-center">
              {[item.id].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>

            <td data-title="Nome">
              {[item.nome].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>
            <td data-title="Email">
              {[item.email].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
            </td>

            <td data-title="Ações" className="text-center">
              <div>
                <a style={{ cursor: "pointer" }}>
                  <i
                    style={{ fontSize: "1.8em" }}
                    className="mdi mdi-chevron-down"
                    onClick={() => setOpcoes(index)}
                  />
                </a>
                <CardMenu open={opcoes === index} width="150px">
                  <a
                    onClick={() => {
                      setUsuario({
                        id: item.id,
                        nome: item.nome,
                        email: item.email,
                        senha: "",
                        senhaConfirma: "",
                        status: item.status,
                        grupoId: item.grupoId,
                        grupoNome: item.grupoNome,
                        my: id === item.id,
                      });
                      setOpenDialogRegistro(true);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"></i>
                    Editar
                  </a>
                  {!(id === item.id) && (
                    <a
                      onClick={() => {
                        setDialogConfirm(true);
                        setUsuario((prevState) => ({
                          ...prevState,
                          id: item.id,
                        }));
                      }}
                    >
                      <i className="mdi mdi-trash-can-outline icon-size delete-table"></i>
                      Excluir
                    </a>
                  )}
                </CardMenu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <PageDefault menuLateral>
      <div
        className="largura-padrao"
        onClick={() => {
          if (opcoes !== null) {
            setOpcoes(null);
          }
        }}
      >
        <h5 className="titulo-pages tr-mb-5">
          <span> Cadastro de Usuário</span>
        </h5>

        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-2">
              <input
                style={{ height: 56 }}
                name="nomeUsuario"
                onKeyPress={handleKeyPress}
                placeholder={i18n.t("consulta.nomeUsuario")}
                onChange={(e) => setNomeBusca(e.target.value)}
                value={nomeBusca}
                maxLength="30"
                autoComplete="off"
              />
            </div>
            <div className="tr-form-group tr-col-sm-2">
              <AutoCompleteLabel
                id="curso"
                options={options}
                value={status}
                onChangeText={setStatus}
                label={i18n.t("formulario.status")}
                textFieldId="labelFiltro"
                clearable
              />
            </div>
            <div className="tr-form-group tr-col-sm-2">
              <AutoCompleteLabel
                id="curso"
                options={grupos.map(({ nome }) => nome)}
                value={grupo}
                onChangeText={setGrupo}
                label={i18n.t("formulario.grupo")}
                textFieldId="labelFiltro"
                clearable
              />
            </div>
            <div className="tr-col"></div>
            <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
              <div className="tr-mr-4">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={handleBusca}
                />
              </div>
              <div>
                <ButtonIcon
                  value={i18n.t("button.novo")}
                  icon="mdi mdi-file icon-mr-12"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          {loading && <Loading title="container-loading-login" />}
          <table>
            <TableHead />
            <TableBody />
          </table>
        </div>
        <DialogRegistro
          data={usuario}
          onClose={() => setOpenDialogRegistro(false)}
          open={openDialogRegistro}
          listar={listar}
        />
      </div>
      <ConfirmeDialog
        title="Excluir"
        description="Deseja realmente excluir esse item?"
        open={dialogConfirm}
        confirm={handleDelete}
        onClose={() => setDialogConfirm(false)}
      />
    </PageDefault>
  );
}
