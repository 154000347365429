import React from "react";

import { Document, Page, View, StyleSheet, Font } from "@react-pdf/renderer";

import { SignatureCardPdf } from "../SignatureCard";
import { HeaderBackgroundPdf } from "../HeaderBackground";
import { HeaderLogoPdf } from "../HeaderLogo";
import { CardTitlePdf } from "../CardTitle";
import { ContentCardPdf } from "../ContentCard";
import { FooterBackgroundPdf } from "../FooterBackground";
import { FooterTextPdf } from "../FooterText";
import { DetailsContentPdf } from "../DetailsContent";

import NunitoRegular from "../../fonts/Nunito-Regular.ttf";
import NunitoBold from "../../fonts/Nunito-Bold.ttf";
import Diploma from "../../fonts/diploma.ttf";
import GarAMondTall from "../../fonts/Gar-A-MondTall.ttf";
import LearningCurveRegular from "../../fonts/learning_curve_regular_ot_tt.ttf";

export function CertificadoPdf({ certificate }) {
  const { header, body, signatures, footer, verse, url } = certificate;

  Font.register({
    family: "Nunito",
    fonts: [
      {
        src: NunitoRegular,
      },
      {
        src: NunitoBold,
        fontWeight: "bold",
      },
    ],
  });

  Font.register({
    family: "Diploma",
    fonts: [
      {
        src: Diploma,
      },
    ],
  });

  Font.register({
    family: "Gar-A-MondTall",
    fonts: [
      {
        src: GarAMondTall,
      },
    ],
  });

  Font.register({
    family: "Learning-Curve",
    fonts: [
      {
        src: LearningCurveRegular,
      },
    ],
  });

  return certificate !== null ? (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page} wrap>
        {/*header */}
        <View style={styles.certificateTop}>
          {header.headerType === "Background" ? (
            <HeaderBackgroundPdf background={header.background} />
          ) : (
            <HeaderLogoPdf
              headerLogo={{
                logo: header.logo,
                style: header.headerStyle,
              }}
            />
          )}
        </View>
        {/*content */}
        <View style={styles.certificateContent}>
          <View>
            {/* <Text style={{fontFamily: 'Roboto'}}>Paulim Pinho</Text> */}
            <CardTitlePdf
              contentTitle={{
                title: body.bodyTitle,
                titleStyle: body.bodyTitleStyle,
              }}
            />
            <ContentCardPdf
              contentDescription={{
                description: body.bodyDescription,
                descriptionStyle: body.bodyDescriptionStyle,
                address: body.bodyAddress,
                addressStyle: body.bodyAddressStyle,
                color: body.bodyColor,
              }}
            />
          </View>

          <View style={styles.containerSignature}>
            {signatures.map((index, indice) => (
              <SignatureCardPdf signature={index} key={indice} />
            ))}
          </View>
        </View>
        {/*footer */}
        <View style={styles.certificadoFooter}>
          {footer.footerType === "Background" ? (
            <FooterBackgroundPdf background={footer.footerBackground} />
          ) : (
            <FooterTextPdf
              footerText={{
                text: footer.footerText,
                backgroudColor: footer.footerBackgroudColor,
                textStyle: footer.footerTextStyle,
              }}
            />
          )}
        </View>
        {/*verso */}
        <DetailsContentPdf verse={verse} url={url} />
      </Page>
    </Document>
  ) : (
    <></>
  );
}
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  containerSignature: {
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  certificateTop: {
    height: "15%",
  },
  certificateContent: {
    height: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  certificadoFooter: {
    height: "15%",
  },
});
