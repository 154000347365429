import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Conditional from "../Operators/Conditional";
import getMapping from "../../repositories/getMapping";
import { NOTAS } from "../../config/endpoint";
import "./index.css";
import { i18n } from "../../translate/i18n";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";

let total;
let dataAprovado;
export default function TelaNota() {
  const { moduloid } = useParams();
  const [open, setOpen] = React.useState(false);
  const [notas, setNotas] = useState([]);

  const handleClickOpen = () => {
    buscaHistorico();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buscaHistorico = () => {
    getMapping
      .get(`${NOTAS}/modulos/${moduloid}/0`)
      .then((data) => {
        total = data.length;
        dataAprovado = data;
        setNotas(data);
      })
      .catch((err) => {
        return;
      });
  };

  useState.completo = false;
  for (var i = 0; i < total; i++) {
    if (dataAprovado[i].aprovado === true) {
      useState.completo = true;
    } else {
      useState.completo = false;
    }
  }

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <MenuBookIcon />
        <label>{i18n.t("treinamentoPainel.historico")}</label>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-nota"
        scroll={"body"}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">
            {i18n.t("treinamentoPainel.notas")}
          </div>
          <div className="header-dialog-close" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <Conditional
          test={notas.length > 0}
          onSuccess={
            <div className="nota">
              {notas.map((item, index) => (
                <div key={index}>
                  <div
                    style={{
                      backgroundColor: item.aprovado
                        ? "rgb(216 247 218)"
                        : "rgb(236 198 192)",
                    }}
                    className="notas"
                  >
                    {[item.nota].map((texto, index) => (
                      <p key={index}>
                        {i18n.t("treinamentoPainel.nota") +
                          ": " +
                          parseFloat(texto).toFixed(2)}
                      </p>
                    ))}
                    {[item.acertos].map((texto, index) => (
                      <p key={index}>
                        {i18n.t("treinamentoPainel.acertos")}:{" "}
                        {texto + "/" + notas[index].perguntas}
                      </p>
                    ))}
                    {[item.provaUsuarioId.data].map((texto, index) => (
                      <p key={index}>{i18n.t("tabela.data") + ": " + texto}</p>
                    ))}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          }
          onFailue={
            <div
              style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}
            >
              <label>{i18n.t("tabela.zeroRegistros")}</label>
            </div>
          }
        />
      </Dialog>
    </div>
  );
}
