import React, { useState, useEffect, useContext } from "react";

import usuarioRepository from "../../repositories/Usuario";
import ListItemText from "@material-ui/core/ListItemText";
import { AulaContext } from "../../contexts/aula.context";
import { useHistory, useParams } from "react-router-dom";
import ArquivosTreinamento from "../ArquivosTreinamento";
import getMapping from "../../repositories/getMapping";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import logo from "..//img/logo/logo-siem.svg";
import HistoricoNota from "../HistoricoNota";
import { i18n } from "../../translate/i18n";
import Menuvideo from "..//Menu/MenuVideo";
import List from "@material-ui/core/List";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import * as Showdown from "showdown";
import service from "../../services";
import Loading from "../Loading";
import Prova from "../Prova";
import Msg from "./Msg.js";
import "./index.scss";

function Treinamento() {
  const { moduloid, aula } = useParams();
  const { setTituloVideo } = useContext(AulaContext);
  const dash = JSON.parse(sessionStorage.getItem("dashboard"));
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState({ id: null });
  const [moduloId, setModuloId] = useState(null);
  const [tituloMod, setTituloMod] = useState("");
  const [concluido, setConcluido] = useState(null);
  const [avaliacao, setAvaliacao] = useState(false);
  const history = useHistory();
  const { tituloVideo } = useContext(AulaContext);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const isMobile = () => {
    const screenWidth = window.innerWidth;
    return screenWidth <= 767;
  };
  
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    busca();
  }, []);

  async function updateVisto(index) {
    let obj = await [...videos];
    obj[index].visto = true;
  }

  async function busca() {
    await getMapping
      .get(`/modulos/${moduloid}/lista`)
      .then((data) => {
        const moduloVideo = data.videos.find((e, index) => {
          if (e.id === Number(aula)) {
            setCounter(index);
          }
          return e.id === Number(aula);
        });
        if (moduloVideo !== undefined) {
          setTituloVideo(moduloVideo.titulo);
          setVideo(moduloVideo);
        } else {
          setTituloVideo(data.videos[0].titulo);
          setVideo(data.videos[0]);
          setCounter(0);
        }
        setConcluido(data.concluido);
        setTituloMod(data.titulo);
        setVideos(data.videos);
        setModuloId(data.id);
        setAvaliacao(data.avaliacao);
      })
      .catch(() => {
        toast.warning(i18n.t("toastWarning.modVazio"));
        history.goBack();
      });
  }

  async function concluirModulo() {
    if (concluido) {
      return toast.info(i18n.t("toastInfo.aprovadoMod"));
    }

    if (concluido === false && avaliacao === true) {
      return toast.warn(
        "É necessário realizar a avaliação para concluir o módulo"
      );
    }

    await service
      .post(`/inscricao/modulo/concluir/${moduloId}`)
      .then(() => {
        setConcluido(true);
        toast.success("Módulo concluído com sucesso!");
        return history.goBack();
      })
      .catch(() => {
        return toast.warning(i18n.t("toastWarning.blockProva"));
      });
  }

  async function videoVisto(x) {
    await usuarioRepository
      .salvarVideoAssistido({
        videoId: x,
      })
      .then(() => {
        updateVisto(counter);
      })
      .catch((e) => toast.error(e.message));
  }

  async function marcarVisto(id) {
    await videoVisto(id);
    let atualiza = document.getElementById(id);
    if (atualiza !== null) {
      atualiza.classList.add("video-ready");
    }
  }

  function proximaAula(e) {
    if (e < videos.length && e > 0) {
      if (videos[counter].tipo !== "VIDEO") {
        marcarVisto(video.id);
      } else {
        if (!video.visto) {
          return toast.warning("Atenção: Assista o vídeo até o final");
        }
      }
      setVideo(videos[e]);
      setTituloVideo(videos[e].titulo);
      setCounter(e);
    } else {
      if (videos[counter].tipo !== "VIDEO") {
        marcarVisto(video.id).then(() => {
          concluirModulo();
        });
      } else {
        if (video.visto) {
          concluirModulo();
        } else {
          return toast.warning("Atenção: Assista o vídeo até o final");
        }
      }
    }
  }

  function RenderVideo() {
    return (
      <div className="container-video-global">
        <div className="container-video">
          <>
            {video.tipo === "VIDEO" ? (
              <ReactPlayer
                url={video.url}
                id={video.id}
                className="react-player"
                controls={true}
                onEnded={() => {
                  marcarVisto(video.id);
                }}
              />
            ) : (
              <>
                {video.tipo === "MARKDOWN" ? (
                  <iframe
                    className="react-player"
                    srcdoc={converter.makeHtml(video.conteudo)}
                    frameborder="0"
                    width="960"
                    height="569"
                    allowfullscreen="true"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                  ></iframe>
                ) : (
                  <>
                    {video.tipo == "PDF" ? (
                      <>
                        {isMobile() ? (
                          <div className="mobile-pdf">
                            <a href={video.conteudo} download={tituloVideo}>
                              <i className="mdi mdi-file-download-outline"/>
                              Clique para fazer o download do Arquivo
                            </a>
                          </div>
                        ) : (
                          <iframe
                            className="react-player"
                            src={video.conteudo}
                            frameborder="0"
                            width="960"
                            height="569"
                            allowfullscreen="true"
                            mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                          ></iframe>
                        )}
                      </>
                    ) : (
                      <>
                        <iframe
                          className="react-player"
                          src={video.conteudo}
                          frameborder="0"
                          width="960"
                          height="569"
                          allowfullscreen="true"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        ></iframe>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
        <div className="container-description">
          <div style={{ marginTop: 32 }}>
            <button onClick={() => proximaAula(counter + 1)}>
              {counter + 1 === videos.length ? (
                <>
                  Concluir Módulo
                  <i className="mdi mdi-check" />
                </>
              ) : (
                <>
                  {i18n.t("button.proximaAtividade")}
                  <i className="mdi mdi-arrow-right" />
                </>
              )}
            </button>
          </div>
          <div className="container-description-title">
            <h4> {i18n.t("treinamentoPainel.descricaoAula")}</h4>
            <hr />
          </div>
          <div className="container-description-body">
            <p>{video.descricao}</p>
          </div>
        </div>
      </div>
    );
  }

  window.onload = function () {
    if (window.innerWidth <= 1024) {
      const templateVideo =
        document.getElementsByClassName("template-video")[0];
      templateVideo.classList.add("menu-fechado");
    }
  };

  function toggleMenu() {
    const templateVideo = document.getElementsByClassName("template-video")[0];
    if (templateVideo.classList.contains("menu-fechado")) {
      templateVideo.classList.remove("menu-fechado");
    } else {
      templateVideo.classList.add("menu-fechado");
    }
  }

  return (
    <div>
      <Menuvideo />
      {videos.length > 0 ? (
        <div className="lista">
          <ul className="collection">
            <li className="container-logo-ga">
              <i
                className="mdi mdi-arrow-left icon-size-md container-logo-ga-icon-back"
                onClick={() => history.goBack()}
              />
              <img src={dash.logo || logo} alt="logo" className="logo-ga" />
              <span
                className="container-logo-ga-hamburguer"
                onClick={toggleMenu}
              >
                <i className="mdi mdi-menu" />
              </span>
            </li>
            <Msg moduloId={moduloId} />
            {videos.map((item, index) => {
              return (
                <li
                  key={item.id}
                  className={
                    counter === index
                      ? "collection-item myitem"
                      : "collection-item"
                  }
                  onClick={() => {
                    setVideo(item);
                    setTituloVideo(item.titulo);
                    setCounter(index);
                  }}
                >
                  <div>
                    <List>
                      {[item.titulo].map((text) => (
                        <ListItem button key={text}>
                          {item.visto === true ? (
                            <i
                              className={
                                item.tipo === "VIDEO"
                                  ? " mdi mdi-television-play video-ready"
                                  : "mdi mdi-file video-ready"
                              }
                            />
                          ) : (
                            <i
                              id={item.id}
                              className={
                                item.tipo === "VIDEO"
                                  ? "mdi mdi-television-play"
                                  : "mdi mdi-file"
                              }
                            />
                          )}
                          <ListItemText
                            id={`list-video` + item.id}
                            className="list-video-item"
                            primary={text}
                            title={text}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </li>
              );
            })}

            <List className="list-test">
              <Divider />
              <div className="buttonProva">
                <ArquivosTreinamento />

                <HistoricoNota moduloId={video.id} />
                <Divider />
                {avaliacao && (
                  <>
                    <Prova
                      concluido={concluido}
                      totalVideos={videos.length}
                      tituloMod={tituloMod}
                    />
                    <Divider />
                  </>
                )}
              </div>
            </List>
          </ul>
          <RenderVideo />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default Treinamento;
