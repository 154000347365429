import styled from 'styled-components'

export const StyledBoxSearch = styled.div`
  background-color: white;
  border: 1px solid #D2D2D2;
  display: flex;
  border-radius: 4px;

  input {
    border: none;
  }

  div {
    margin: auto;
    padding-right: 10px;
    
    i {
      font-size: 24px !important;
    }
  }

`