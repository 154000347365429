import { InputLabel, TextField } from "@material-ui/core";
import React from "react";
import { StyledAutocomplete } from "./styles.js";

function AutoCompleteLabel({
  id,
  options,
  value,
  onChangeText,
  label,
  textFieldId,
  clearable,
  labelavel,
  disabled,
  onBlur
}) {
  return (
    <StyledAutocomplete
      id={id}
      options={options}
      value={value}
      disabled={disabled}
      disableClearable={clearable}
      onChange={(event, newValue) => {
        onChangeText(newValue);
      }}
      onBlur={onBlur}
      name={label}
      renderInput={(params) => (
        <div>
          {labelavel && (
            <InputLabel
              className="custom-autocomplete-label"
              htmlFor={textFieldId}
            >
              {label}
            </InputLabel>
          )}
          <TextField
            className={labelavel ? 'custom-autocomplete-input' : 'custom-autocomplete-inputWLabel'}
            label={labelavel ? '' : label}
            id={textFieldId}
            variant={labelavel ? 'standard' : 'outlined'}
            {...params}
          />
        </div>
      )}
    />
  );
}

export default AutoCompleteLabel;
