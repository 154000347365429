import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Form from "../../../components/Form";
import useForm from "../../../hooks/useForm";
import CloseIcon from "@material-ui/icons/Close";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { GRUPO } from "../../../config/endpoint";
import postMapping from "../../../repositories/postMapping";
import putMapping from "../../../repositories/putMapping";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../translate/i18n";

export default function GrupoDialog(props) {
  const initialData = {
    id: props.id,
    nome: props.nome,
  };
  const { handleChange, values, clearForm } = useForm(initialData);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  useState.sizeNome = values.nome.length;

  async function handleSubmit(e) {
    e.preventDefault();
    let errorList = [];

    //verificações

    if (values.nome.length < 1) {
      errorList.push("Insira um nome válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (errorList.length < 1) {
      if (values.id === "") {
        await postMapping
          .post(
            {
              nome: values.nome,
            },
            GRUPO
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.grupoCad"));
            clearForm();
            setIserror(false);
            props.onClose();
          })
          .catch((e) => toast.error(e.message), clearForm());
      } else {
        await putMapping
          .put(
            {
              id: values.id,
              nome: values.nome,
            },
            `${GRUPO}/${values.id}`
          )
          .then(() => {
            toast.success(i18n.t("toastSuccess.grupoAtt"));
            setIserror(false);
            props.listar();
            props.onClose();
          })
          .catch((e) => toast.error(e.message), clearForm());
      }
    }
  }

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        onClose={() => props.onClose()}
        onEnter={() => clearForm()}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">
            <span>{i18n.t("menu.cadGrupo")}</span>
          </div>
          <div className="header-dialog-close" onClick={() => props.onClose()}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-dialog">
          <div
            className="header-dialog-close"
            onClick={() => props.onClose()}
          ></div>
          <Form className="form-cadastros" onSubmit={handleSubmit}>
            <div>
              {iserror && (
                <Alert severity="error">
                  {errorMessages.map((msg, i) => {
                    return <div key={i}>{msg}</div>;
                  })}
                </Alert>
              )}
            </div>
            <div className="tr-row">
              <div className="tr-form-group tr-col-sm-12">
                <FormField
                  label={i18n.t("formulario.nome")}
                  type="text"
                  name="nome"
                  maxLength="30"
                  value={values.nome}
                  onChange={handleChange}
                />
                <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                  {useState.sizeNome}/30
                </p>
              </div>
            </div>
            <Button type="submit">{i18n.t("button.salvar")}</Button>
          </Form>
        </div>
      </Dialog>
    </div>
  );
}
