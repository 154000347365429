import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import getMapping from "../../../../repositories/getMapping";
import { toast } from "react-toastify";
import AutoCompleteLabel from "../../../../components/AutoComplete";
import deleteMapping from "../../../../repositories/deleteMapping";
import Dialog from "../../../../components/Dialog";

export default function ResetModulo({ data, open, onClose }) {
  const [usuarios, setUsuario] = useState([]);
  const [userReset, setUserReset] = useState();

  useEffect(() => {
    listarUsuario();
  }, [data]);

  function listarUsuario() {
    getMapping
      .get(`/usuarios/listar`)
      .then((res) => {
        setUsuario(res);
      })
      .catch(() => {
        return;
      });
  }

  function resetarModuloParaUsuario() {
    const chosenUsuario = usuarios.find((usuario) => {
      return usuario.nome + " - " + usuario.email === userReset;
    });

    if (chosenUsuario !== undefined) {
      deleteMapping
        .deletar(`/usuarios/${chosenUsuario.id}/modulo/${data.id}`)
        .then(() => {
          toast.success("Hístorico resetado!");
          onClose();
        })
        .catch((e) => toast.error(e.message));
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      title={data.titulo}
    >
      <div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <AutoCompleteLabel
              id="id_userReset"
              options={usuarios.map(({ nome, email }) =>
                nome.concat(" - " + email)
              )}
              value={userReset}
              onChangeText={setUserReset}
              label="Usuário"
              textFieldId="labelResetUser"
              clearable
            />
          </div>
        </div>
        <Button onClick={resetarModuloParaUsuario}>Resetar</Button>
      </div>
    </Dialog>
  );
}
