import React, { useState, useEffect } from "react";
import "../../components/FormField";
import Dialog from "./Dialog";
import usuarioRepository from "../../repositories/Usuario";
import DefaultPage from "../../template/DefaultPage";
import "./index.css";
import { i18n } from "../../translate/i18n";
import services from "../../services";
import AutoCompleteLabel from "../../components/AutoComplete";

let idioma = "i18nextLng";
let lang = localStorage.getItem("i18nextLng");

export default function PerfilUsuario() {
  function changeLang() {
    switch (lang) {
      case "pt":
        return i18n.t("idioma.portugues");

      case "en":
        return i18n.t("idioma.ingles");

      case "es":
        return i18n.t("idioma.espanhol");

      default:
        return i18n.t("idioma.ingles");
    }
  }

  const [newLang, setNewLang] = useState(changeLang());
  const [usuario, setUsuario] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [setTotalConcluido, setTotalConcluidos] = useState(0);
  const selectChange = (event) => {
    switch (event) {
      case i18n.t("idioma.portugues"):
        localStorage.setItem(idioma, "pt");
        break;
      case i18n.t("idioma.ingles"):
        localStorage.setItem(idioma, "en");
        break;
      case i18n.t("idioma.espanhol"):
        localStorage.setItem(idioma, "es");
        break;
      default:
        localStorage.setItem(idioma, "en");
        break;
    }
    window.location.reload();
  };

  const options = [
    i18n.t("idioma.portugues"),
    i18n.t("idioma.ingles"),
    i18n.t("idioma.espanhol"),
  ];

  useEffect(() => {
    setNewLang(changeLang());

    async function getUser() {
      await usuarioRepository
        .buscaPerfilUsuario()
        .then((data) => {
          setUsuario({
            id: data.id,
            nome: data.nome,
            email: data.email,
            senha: "",
            senhaConfirma: "",
            status: data.status,
            grupoId: data.grupoId.id || "",
            grupoNome: data.grupoId.nome || "",
            my: true,
          });
        })
        .catch((err) => {
          return;
        });
    }

    async function requisitos() {
      await services
        .get("/requisito/listar")
        .then((data) => {
          setTotalConcluidos(data.length);
        })
        .catch((e) => {
          return;
        });
    }
    getUser();
    requisitos();
  }, []);

  return (
    <DefaultPage>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <hr />
          <span className="barra">/</span>
          <span style={{ minWidth: "max-content" }}>
            {i18n.t("menu.perfilUsuario")}
          </span>
          <span className="barra">/</span>
          <hr />
        </h5>
      </div>
      <div className="box-profile largura-padrao">
        <div
          className="icon"
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <i
            className="mdi mdi-cog"
            style={{ fontSize: "2em", marginRight: "4px" }}
          />
          <div className="email">{i18n.t("formulario.configUsuario")}</div>
        </div>

        <div className="completed-course">
          <div className="completed-course-number">{setTotalConcluido}</div>
          <span className="completed-course-name">
            {i18n.t("formulario.modulosCompletado")}
          </span>
        </div>
        <div className="tr-form-group tr-col-sm-2">
          <AutoCompleteLabel
            id="id_idioma"
            options={options}
            value={newLang}
            onChangeText={selectChange}
            label={i18n.t("idioma.idioma")}
            textFieldId="labelIdioma"
            clearable
          />
        </div>
      </div>
      <Dialog
        data={usuario}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      />
    </DefaultPage>
  );
}
