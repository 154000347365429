import React, { useEffect, useState } from "react";

import { ContentState, convertToRaw } from "draft-js";
import postMapping from "../../../repositories/postMapping";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import { i18n } from "../../../translate/i18n";
import { Editor } from "react-draft-wysiwyg";
import Alert from "@material-ui/lab/Alert";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { returnEvento } from "../index";
import { toast } from "react-toastify";
import "./index.scss";

// import { ContainerEmail } from "./styles";

const initialEmail = {
  assunto: "",
  evento: "",
  conteudo: "",
};
export default function EmailForm({ data, onClose, open, updateList }) {
  // const [visible, setVisible] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [email, setEmail] = useState(data || initialEmail);
  const [editorState, setEditorState] = useState("");
  function createConteudo(e) {
    const blocksFromHtml = htmlToDraft(e || "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const raw = convertToRaw(contentState);
    setEditorState(raw);
  }

  useEffect(() => {
    createConteudo(data ? data.conteudo : "");
    setEmail(data || initialEmail);
  }, [open]);

  async function handleSubmit() {
    let conteudoEmail = email.conteudo;
    if (editorState.getCurrentContent) {
      conteudoEmail = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
    }

    let errorList = [];

    //verificações

    if (!email.assunto.trim()) {
      errorList.push("Insira um assunto válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      postMapping
        .post(
          {
            id: email.id,
            hotmartWebHookId: email.hotmartWebHookId,
            assunto: email.assunto,
            evento: email.evento,
            conteudo: conteudoEmail,
            ativo: email.ativo,
          },
          `/hotmartwebhook/email`
        )
        .then(() => {
          setIserror(false);
          toast.success("Sucesso");
          onClose();
          updateList();
        })
        .catch((e) => toast.error(e.message));
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      title="Cadastro de E-mail"
    >
      <div className="form-cadastros">
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-6">
            <label>
              Assunto{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">Texto assunto do e-mail</span>
              </i>
            </label>
            <input
              type="text"
              name="assunto"
              maxLength="50"
              value={email.assunto}
              onChange={(e) =>
                setEmail((prevState) => ({
                  ...prevState,
                  assunto: e.target.value,
                }))
              }
            />
          </div>
          <div className="tr-form-group tr-col-sm-6">
            <label>
              Evento{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">
                  Evento que o e-mail é disparado
                </span>
              </i>
            </label>
            <input
              type="text"
              id="id_evento"
              name="evento"
              value={returnEvento(email.evento, true)}
              readOnly="readOnly"
            />
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <label>
              Conteúdo{" "}
              <i className="mdi mdi-help-circle icon-information">
                <span className="tooltiptext">Conteúdo corpo do e-mail </span>
              </i>
            </label>
            <span className="warning-corpo-email">
              *E-mail do tipo 'Compra aprovada' ira automaticamente um botão com
              link do curso junto ao corpo.
            </span>
            <br />
            <span className="warning-corpo-email">
              *Pressione '@' para acessar váriaveis disponíveis.
            </span>
            <Editor
              defaultContentState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: [
                  {
                    text: "Usuário Nome",
                    value: "USUARIO_NOME",
                    url: "USUARIO_NOME",
                  },
                  {
                    text: "Usuário Email",
                    value: "USUARIO_EMAIL",
                    url: "USUARIO_EMAIL",
                  },
                  {
                    text: "Curso Nome",
                    value: "CURSO_NOME",
                    url: "CURSO_NOME",
                  },
                ],
              }}
              hashtag={{}}
            />
            {/* <label>Conteúdo </label>
            <span
              style={{
                cursor: "pointer",
                paddingRight: 10,
                fontSize: 14,
                opacity: visible ? 0.5 : 1,
              }}
              onClick={() => setVisible(!visible)}
            >
              Edição
            </span>
            <span
              style={{
                cursor: "pointer",
                fontSize: 14,
                opacity: !visible ? 0.5 : 1,
              }}
              onClick={() => setVisible(!visible)}
            >
              Visualização
            </span>

            {!visible ? (
              <Editor
                defaultContentState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: [
                    {
                      text: "Usuário Nome",
                      value: "USUARIO_NOME",
                      url: "USUARIO_NOME",
                    },
                    {
                      text: "Usuário Email",
                      value: "USUARIO_EMAIL",
                      url: "USUARIO_EMAIL",
                    },
                    {
                      text: "Curso Nome",
                      value: "CURSO_NOME",
                      url: "CURSO_NOME",
                    },
                  ],
                }}
                hashtag={{}}
              />
            ) : (
              <ContainerEmail>
                <PreviewEmail />
              </ContainerEmail>
            )} */}
          </div>
        </div>

        <Button onClick={handleSubmit} type="submit">
          {i18n.t("button.salvar")}
        </Button>
      </div>
    </Dialog>
  );
}
