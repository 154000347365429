import React, { useState, useEffect } from "react";

import getMapping from "../../../../repositories/getMapping";
import { INSCRITOS } from "../../../../config/endpoint";
import Loading from "../../../../components/Loading";
import Dialog from "../../../../components/Dialog";
import { i18n } from "../../../../translate/i18n";
import DialogListaIns from "./listaInscritos";
import DialogLista from "./listaConcluidos";

export default function Inscritos({ data, open, onClose }) {
  const [inscritos, setInscritos] = useState([]);
  const [dialogIns, setDialogIns] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [formados, setFormados] = useState(false);
  const [subTitulo, setSubTitulo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function listar() {
      await getMapping
        .get(`${INSCRITOS}/${data.id}`)
        .then((res) => {
          setInscritos(res);
          setTimeout(function () {
            setLoading(false);
          }, 300);
        })
        .catch((err) => {
          return;
        });
    }
    listar();
  }, [open]);

  const handleInscritos = () => {
    setDialogIns(true);
  };
  const handleConcluidos = () => {
    setDialog(true);
  };
  const handleClose = () => {
    setDialog(false);
    setDialogIns(false);
  };

  const [id, setId] = useState("");
  const [titulo, setTitulo] = useState("");

  const initialData = {
    id: id,
    titulo: titulo,
    subTitulo: subTitulo,
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th>{i18n.t("tabela.modulo")}</th>
          <th className="text-center">{i18n.t("tabela.inscritos")}</th>
          <th className="text-center">{i18n.t("tabela.concluidos")}</th>
        </tr>
      </thead>
    );
  };

  const TableBody = (props) => {
    return inscritos ? (
      <tbody>
        {inscritos.map((item) => (
          <tr key={item.id}>
            <td style={{ flex: "1" }} data-title="Titulo">
              {[item.titulo].map((texto, index) => (
                <span ley={index}>{texto}</span>
              ))}
            </td>
            <td data-title="Inscritos" className="text-center">
              {[item.totalInscritos].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
              <i
                className="mdi mdi-account-group-outline icon-size icon-green"
                onClick={() => {
                  handleInscritos();
                  setId(item.id);
                  setTitulo(item.titulo);
                  setSubTitulo(i18n.t("tabela.inscritos"));
                  setFormados(false);
                }}
              ></i>
            </td>
            <td data-title="Concluidos" className="text-center">
              {[item.totalConcluidos].map((texto, index) => (
                <span key={index}>{texto}</span>
              ))}
              <i
                className="mdi mdi-account-group-outline icon-size icon-green"
                onClick={() => {
                  handleConcluidos();
                  setId(item.id);
                  setTitulo(item.titulo);
                  setSubTitulo(i18n.t("tabela.concluidos"));
                  setFormados(true);
                }}
              ></i>
            </td>
          </tr>
        ))}
      </tbody>
    ) : (
      <tbody>
        <tr>
          <td
            data-title="Empty Table"
            colSpan="8"
            className="text-left"
            style={{ backgroundColor: "#eff1f9" }}
          >
            {i18n.t("tabela.zeroRegistros")}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      title={data.titulo}
    >
      <div>
        <table>
          {loading && <Loading title="container-loading-login" />}
          <TableHead />
          <TableBody />
        </table>
        <DialogListaIns
          initialData={initialData}
          onClose={handleClose}
          open={dialogIns}
          inscritos={inscritos}
          formados={formados}
        />
        <DialogLista
          initialData={initialData}
          onClose={handleClose}
          open={dialog}
          inscritos={inscritos}
          formados={formados}
        ></DialogLista>
      </div>
    </Dialog>
  );
}
