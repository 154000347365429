import React, { createContext, useState } from "react";

export const AulaContext = createContext({});

export default function AulaProvider({ children }) {
  const [tituloVideo, setTituloVideo] = useState("");

  return (
    <AulaContext.Provider value={{ tituloVideo, setTituloVideo }}>
      {children}
    </AulaContext.Provider>
  );
}
