import React, { useState, useEffect } from "react";
import getMapping from "../../repositories/getMapping";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Relatorio, { Titulo } from "../../components/Relatorio";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import Check from "../../components/img/check.png";
import X from "../../components/img/x.png";
import Divider from "@material-ui/core/Divider";
import { toast } from "react-toastify";
import NunitoBold from "../../components/fonts/Nunito-Bold.ttf";
import NunitoLight from "../../components/fonts/Nunito-Light.ttf";
import NunitoRegular from "../../components/fonts/Nunito-Regular.ttf";
import { StyledBoxButton } from "./styles";
import ButtonIcon from "../ButtonIcon";
import AutoCompleteLabel from "../AutoComplete";

export default function ProvaDoAluno() {
  Font.register({
    family: "Nunito",
    fonts: [
      {
        src: NunitoRegular,
      },
      {
        src: NunitoBold,
        fontWeight: "bold",
      },
      {
        src: NunitoLight,
        fontWeight: "light",
      },
    ],
  });
  const [cursoModulo, setCursoModulo] = useState([]);
  const [openExport, setOpenExport] = useState(false);
  const [usuarios, setUsuario] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [nota, setNota] = useState();
  const [data, setData] = useState();
  const [aprovado, setAprovado] = useState();
  const [filtro, setFiltro] = useState({
    usuario: "",
    modulo: "",
    data: "",
    indexData: "",
  });

  useEffect(() => {
    listarCursoModulo();
    listarUsuario();
    Titulo(i18n.t("relatorio.relatorio3"));
  }, []);

  function listarCursoModulo() {
    getMapping
      .get(`/modulos/curso/1`)
      .then((data) => {
        setCursoModulo(data);
      })
      .catch(() => {
        return;
      });
  }
  function listarUsuario() {
    getMapping
      .get(`/usuarios/listar`)
      .then((data) => {
        setUsuario(data);
      })
      .catch(() => {
        return;
      });
  }

  function listarHistorico() {
    const chosenModulo = cursoModulo.find((modulo) => {
      return modulo.cursoTitulo + " / " + modulo.titulo === filtro.modulo;
    });
    const chosenUsuario = usuarios.find((usuario) => {
      return usuario.nome + " - " + usuario.email === filtro.usuario;
    });
    if (chosenModulo !== undefined && chosenUsuario !== undefined) {
      getMapping
        .get(`/nota/modulos/${chosenModulo.id}/${chosenUsuario.id}`)
        .then((data) => {
          setHistorico(data);
          if (data.length < 1) {
            useState.condicional = false;
            toast.warning(i18n.t("toastWarning.zeroConsulta"));
          }
        });
    }
  }

  function provaUsuario() {
    const chosenData = historico.find((item) => {
      return item.provaUsuarioId.data + " : " + item.nota === filtro.data;
    });

    if (historico.length >= 1 && chosenData !== undefined) {
      getMapping
        .get(`/respostausuario/provausuario/${chosenData.provaUsuarioId.id}`)
        .then((data) => {
          if (data.length < 1) {
            useState.condicional = false;
            toast.warning(i18n.t("toastWarning.zeroConsulta"));
          } else {
            setPerguntas(data);
            setData(chosenData.provaUsuarioId.data);
            setNota(chosenData.nota);
            setAprovado(chosenData.aprovado);
            setOpenExport(true);
          }
        });
    }
  }
  const [perguntas, setPerguntas] = useState([]);

  function DialogExport() {
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openExport}
        fullWidth={true}
        scroll={"paper"}
      >
        <div className="header-dialog">
          <div className="header-dialog-title">
            {i18n.t("consulta.relatorio")}
          </div>
          <div
            className="header-dialog-close"
            onClick={() => setOpenExport(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <Relatorio>
          <View style={styles.container}>
            <View style={styles.area}>
              <View>
                <View style={[styles.areaTexto]}>
                  <Text style={styles.texto}>
                    {i18n.t("relatorio.usuario")}: {filtro.usuario}
                  </Text>
                </View>
                <View style={styles.areaTexto}>
                  <Text style={styles.texto}>
                    {i18n.t("relatorio.cursoModulo")}: {filtro.modulo}
                  </Text>
                </View>
                <View style={[styles.areaTexto]}>
                  <Text style={styles.texto}>
                    {i18n.t("relatorio.dataRealizacao")}: {data}
                  </Text>
                </View>
              </View>

              <View style={styles.areaResultado}>
                <View
                  style={{
                    borderWidth: 1,
                    padding: 5,
                    borderColor: aprovado ? "green" : "red",
                  }}
                >
                  {aprovado ? (
                    <Text style={styles.aprovado}>
                      {i18n.t("relatorio.aprovado")}
                    </Text>
                  ) : (
                    <Text style={styles.reprovado}>
                      {i18n.t("relatorio.reprovado")}
                    </Text>
                  )}
                </View>
                <Text style={styles.texto}>
                  {i18n.t("relatorio.nota")}: {nota}
                </Text>
              </View>
            </View>
            <View style={styles.areaProva}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <View
                  style={{
                    height: 10,
                    width: 10,
                    backgroundColor: "#e9e9e9",
                    paddingRight: 5,
                  }}
                />
                <Text style={styles.textoLegenda}>
                  {i18n.t("relatorio.respostaAluno")}
                </Text>
              </View>
              {perguntas.map((item, index) => (
                <View key={index}>
                  <View style={styles.areaPergunta}>
                    {item.correto === true ? (
                      <Image src={Check} style={styles.img} />
                    ) : (
                      <Image src={X} style={styles.img} />
                    )}
                    {[item.pergunta].map((texto) => (
                      <Text style={styles.pergunta}>
                        {index + 1}. {texto}
                      </Text>
                    ))}
                  </View>
                  <View style={styles.areaAlternativa}>
                    {[item.alt1].map((texto) => (
                      <Text
                        style={[
                          styles.alternativas,
                          {
                            backgroundColor:
                              item.respondido === 1 ? "#e9e9e9" : "",
                          },
                        ]}
                      >
                        1) {texto}
                      </Text>
                    ))}

                    {[item.alt2].map((texto) => (
                      <Text
                        style={[
                          styles.alternativas,
                          {
                            backgroundColor:
                              item.respondido === 2 ? "#e9e9e9" : "",
                          },
                        ]}
                      >
                        2) {texto}
                      </Text>
                    ))}

                    {[item.alt3].map((texto) => (
                      <Text
                        style={[
                          styles.alternativas,
                          {
                            backgroundColor:
                              item.respondido === 3 ? "#e9e9e9" : "",
                          },
                        ]}
                      >
                        3) {texto}
                      </Text>
                    ))}

                    {[item.alt4].map((texto) => (
                      <Text
                        style={[
                          styles.alternativas,
                          {
                            backgroundColor:
                              item.respondido === 4 ? "#e9e9e9" : "",
                          },
                        ]}
                      >
                        4) {texto}
                      </Text>
                    ))}

                    {[item.resposta].map((texto) => (
                      <Text style={[styles.correta]}>
                        {i18n.t("relatorio.alternativaCorreta")}: {texto}
                      </Text>
                    ))}
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Relatorio>
      </Dialog>
    );
  }

  return (
    <div>
      <div className="tr-row">
        <div className="tr-form-group tr-col-sm-3">
          <br />
          <AutoCompleteLabel
            id="usuario"
            options={usuarios.map(({ nome, email }) =>
              nome.concat(" - " + email)
            )}
            value={filtro.usuario}
            onChangeText={(e) =>
              setFiltro((prevState) => ({
                ...prevState,
                usuario: e,
              }))
            }
            label={i18n.t("tabela.nomeEmail")}
            textFieldId="labelSelectCurso"
            onBlur={listarHistorico}
            // labelavel
          />
        </div>
        <div className="tr-form-group tr-col-sm-4">
          <br />
          <AutoCompleteLabel
            id="modulo"
            options={cursoModulo.map(({ titulo, cursoTitulo }) =>
              cursoTitulo.concat(" / " + titulo)
            )}
            value={filtro.modulo}
            onChangeText={(e) =>
              setFiltro((prevState) => ({
                ...prevState,
                modulo: e,
              }))
            }
            label={i18n.t("tabela.cursoModulo")}
            textFieldId="labelSelectCurso"
            onBlur={listarHistorico}
            // labelavel
          />
        </div>
        <div className="tr-form-group tr-col-sm-3">
          <br />
          <AutoCompleteLabel
            id="data"
            options={historico.map(
              (item) => item.provaUsuarioId.data + " : " + item.nota
            )}
            value={filtro.data}
            onChangeText={(e) =>
              setFiltro((prevState) => ({
                ...prevState,
                data: e,
              }))
            }
            label={i18n.t("formulario.selecione")}
            textFieldId="labelSelectCurso"
            // labelavel
          />
        </div>
        <div className="tr-form-group tr-col-sm-2">
          <StyledBoxButton>
            <ButtonIcon
              value={i18n.t("button.gerar")}
              icon="mdi mdi-text-search icon-mr-12"
              onClick={() => provaUsuario()}
            />
          </StyledBoxButton>
        </div>
      </div>
      <Divider />
      <DialogExport />
    </div>
  );
}

const styles = StyleSheet.create({
  alternativas: {
    fontSize: 10,
    marginVertical: 10,
    color: "#4D4D4D",
    textAlign: "justify",
    fontFamily: "Nunito",
    padding: 2,
    borderRadius: 2,
  },
  img: {
    width: 15,
    height: 15,
  },
  containerImg: {
    alignItems: "flex-start",
    justifyContent: "center",
    marginRight: 40,
  },
  correta: {
    fontSize: 12,
    marginVertical: 5,
    fontFamily: "Nunito",
  },
  container: {
    flex: 1,
    margin: 20,
  },
  area: {
    marginBottom: 5,
    flexDirection: "row",
    marginLeft: 10,
    justifyContent: "space-between",
  },
  areaTexto: {
    marginVertical: 2,
    backgroundColor: "red",
  },
  texto: {
    fontSize: 12,
    color: "#4D4D4D",
    fontFamily: "Nunito",
    fontWeight: "bold",
    width: "85%",
  },
  areaAlternativa: {
    marginHorizontal: 15,
    marginBottom: 15,
  },
  areaProva: {
    marginTop: 20,
  },
  areaPergunta: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingBottom: 10,
    marginRight: 20,
  },
  pergunta: {
    fontSize: 12,
    fontFamily: "Nunito",
    color: "#000",
    textAlign: "justify",
    padding: 2,
  },
  areaResultado: {
    marginLeft: 4,
    alignItems: "center",
  },
  aprovado: {
    fontSize: 12,
    color: "green",
    fontFamily: "Nunito",
  },
  reprovado: {
    fontSize: 12,
    color: "red",
    fontFamily: "Nunito",
  },
  textoLegenda: {
    fontSize: 9,
    padding: 5,
    fontFamily: "Nunito",
    color: "#4D4D4D",
  },
});
