import React, { useState, useEffect } from "react";

import ConfirmeDialog from "../../../components/ConfirmeDialog";
import { CardMenu } from "../../../components/MenuCardOptions";
import { useHistory, useParams } from "react-router-dom";
import PageDefault from "../../../template/DefaultPage";
import ButtonIcon from "../../../components/ButtonIcon";
import FormField from "../../../components/FormField";
import Loading from "../../../components/Loading";
import { i18n } from "../../../translate/i18n";
import services from "../../../services";
import { toast } from "react-toastify";

let lista = [];
export default function CertificadoLista() {
  const [certificados, setCertificados] = useState([]);
  const [consulta, setConsulta] = useState([]);
  const [nome, setNome] = useState("");
  const [loading, setLoading] = useState(false);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [certificadoId, setCertificadoId] = useState(null);
  const [opcoes, setOpcoes] = useState(null);

  const history = useHistory();
  const { tenant } = useParams();

  async function listarLayouts(e) {
    setLoading(true);
    await services
      .get("/certificadolayout/lista")
      .then((data) => {
        if (e) {
          setConsulta(data);
        }
        setCertificados(data);
      })
      .catch((e) => toast.error(e.message));
    setLoading(false);
  }

  function handleBusca() {
    if (nome !== "") {
      lista = certificados.filter(
        (el) => el.nome.toLowerCase().indexOf(nome.toLocaleLowerCase()) > -1
      );
      if (lista.length < 1) {
        setConsulta([]);
        return toast.warning(i18n.t("tabela.zeroRegistros"));
      }
      setConsulta(lista);
    } else {
      setConsulta(certificados);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBusca();
    }
  };

  async function deleteLayout() {
    await services
      .deletar(`/certificadolayout/${certificadoId}`)
      .then(() => {
        listarLayouts(true);
        toast.success("Deletado com sucesso");
      })
      .catch((e) => toast.error(e.message));
    setDialogConfirm(false);
  }

  useEffect(() => {
    listarLayouts();
  }, []);

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th>{i18n.t("tabela.codigo")}</th>
          <th style={{ width: "100%" }}>{i18n.t("formulario.nome")}</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    return (
      <>
        {consulta.length > 0 ? (
          <tbody>
            {consulta.map((item, index) => (
              <tr key={index}>
                <td data-title="ID">
                  <span>{item.id}</span>
                </td>

                <td data-title="Nome">
                  <span>{item.nome}</span>
                </td>

                <td data-title="Ações" className="text-center">
                  <div>
                    <a style={{ cursor: "pointer" }}>
                      <i
                        style={{ fontSize: "1.8em" }}
                        className="mdi mdi-chevron-down"
                        onClick={() => setOpcoes(index)}
                      />
                    </a>
                    <CardMenu open={opcoes === index} width="150px">
                      <a
                        onClick={() => {
                          history.push(
                            `/${tenant}/editar/certificado/${item.id}`
                          );
                        }}
                      >
                        <i className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"></i>
                        Editar
                      </a>
                      <a
                        onClick={() => {
                          setCertificadoId(item.id);
                          setDialogConfirm(true);
                        }}
                      >
                        <i className="mdi mdi-trash-can-outline icon-size delete-table"></i>
                        Excluir
                      </a>
                    </CardMenu>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                data-title="Empty Table"
                colSpan="3"
                className="text-left"
                style={{ backgroundColor: "#eff1f9" }}
              ></td>
            </tr>
          </tbody>
        )}
      </>
    );
  };

  return (
    <PageDefault menuLateral>
      <div
        className="largura-padrao"
        onClick={() => {
          if (opcoes !== null) {
            setOpcoes(null);
          }
        }}
      >
        <h5 className="titulo-pages tr-mb-5">
          <span> Cadastro Certificado</span>
        </h5>

        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-5">
              <FormField
                onChange={(e) => setNome(e.target.value)}
                onKeyPress={handleKeyPress}
                name="nomecertificado"
                placeholder="Nome certificado"
                value={nome}
                maxLength="30"
                label=""
              />
            </div>
            <div className="tr-col"></div>
            <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
              <div className="tr-mr-4">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={handleBusca}
                />
              </div>
              <div>
                <ButtonIcon
                  value={i18n.t("button.novo")}
                  icon="mdi mdi-file icon-mr-12"
                  onClick={() =>
                    history.push(`/${tenant}/editar/certificado/novo`)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="table-container">
          {loading && <Loading title="container-loading-login" />}
          <table>
            <TableHead />
            <TableBody />
          </table>
        </div>
        <ConfirmeDialog
          title="Excluir"
          description="Deseja realmente excluir esse item?"
          open={dialogConfirm}
          confirm={deleteLayout}
          onClose={() => setDialogConfirm(false)}
        />
      </div>
    </PageDefault>
  );
}
