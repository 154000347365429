import React, { useState, useEffect } from "react";
import getMapping from "../../repositories/getMapping";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Relatorio, { Titulo } from ".";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import NunitoLight from "../../components/fonts/Nunito-Light.ttf";
import NunitoBold from "../../components/fonts/Nunito-Bold.ttf";
import NunitoRegular from "../../components/fonts/Nunito-Regular.ttf";
import { StyledBoxButton } from "./styles";
import ButtonIcon from "../ButtonIcon";
import AutoCompleteLabel from "../AutoComplete";

export default function HistoricoGeralAluno() {
    const [openExport, setOpenExport] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [historico, setHistorico] = useState([]);
    const [filtro, setFiltro] = useState({
        grupo: "",
        modulo: i18n.t("button.sim")
    });


    const options = [
        i18n.t("button.sim"),
        i18n.t("button.nao"),
    ]


    useEffect(() => {
        listarGrupos();
        Titulo(i18n.t("relatorio.relatorio6"))
    }, []);

    Font.register({
        family: "Nunito", fonts: [
            {
                src: NunitoRegular
            },
            {
                src: NunitoBold,
                fontWeight: 'bold'
            },
            {
                src: NunitoLight,
                fontWeight: 'light'
            }

        ]
    });

    function listarGrupos() {
        getMapping.get(`/grupo/all`)
            .then((data) => {
                setGrupos(data);
            })
            .catch(() => { return; });
    }

    async function listarHistorico() {

        const chosenGrupo = grupos.find((grupo) => {
            return grupo.nome === filtro.grupo;
        })

        if (chosenGrupo !== undefined) {

            await getMapping.get(`/relatorio/historico/grupo/${chosenGrupo.id}`)
                .then((data) => {
                    if (data.length < 1) {
                        toast.warning(i18n.t("toastWarning.zeroConsulta"))
                    } else {
                        setHistorico(data.listaProgressoUsuario != null ? data.listaProgressoUsuario : []);
                        setOpenExport(true)

                    }
                })

        }

    }

    function DialogExport() {

        return (
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={openExport}
                fullWidth={true}
                scroll={"paper"}
            >
                <div className="header-dialog">
                    <div className="header-dialog-title">{i18n.t("consulta.relatorio")}</div>
                    <div className="header-dialog-close" onClick={() => setOpenExport(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <Relatorio >
                    <View style={styles.container}>

                        <View style={styles.areaTexto}>
                            <Text style={styles.texto}>{i18n.t("relatorio.grupo")}: {filtro.grupo}</Text>
                        </View>

                        <View style={styles.areaTable}>

                            {historico.map((item, index) => (

                                <>
                                    {item.progressosCurso.length > 0 &&
                                        <>

                                            <View key={index} style={styles.linhaAluno}>
                                                <Text style={[styles.textoHeader, { marginLeft: 15 }]}>{i18n.t("relatorio.usuario")}: {item.usuarioNome} - {item.usuarioEmail}</Text>
                                            </View>

                                            {item.progressosCurso.map((dataCurso) => (
                                                <>
                                                    <View key={index} style={styles.linhaCurso}>

                                                        <Text style={[styles.textoModulo, { marginLeft: 15 }]}>{i18n.t("relatorio.curso")}: {dataCurso.cursoTitulo} </Text>

                                                        <View style={styles.coluna}>
                                                            <Text style={styles.textoModulo}> {i18n.t("relatorio.progresso")}: {dataCurso.progresso}%</Text>
                                                        </View>
                                                    </View>

                                                    {filtro.modulo === i18n.t("button.sim") &&
                                                        <>
                                                            {dataCurso.progressosModulos.map((dataModulo) => (
                                                                <View key={index} style={styles.linhaModulo}>

                                                                    <Text style={[styles.textoModulo, { marginLeft: 15 }]}>{i18n.t("relatorio.modulo")}: {dataModulo.moduloTitulo} </Text>
                                                                    <View style={styles.coluna}>
                                                                        <Text style={styles.textoModulo}> {i18n.t("relatorio.progresso")}: {dataModulo.progresso}%</Text>
                                                                    </View>
                                                                </View>

                                                            ))}

                                                        </>
                                                    }

                                                </>
                                            ))}
                                            <View style={{ marginBottom: 20 }} />
                                        </>
                                    }

                                </>
                            ))}
                        </View>

                    </View>
                </Relatorio>
            </Dialog>
        );

    }

    return (
        <div>
            <div className="tr-row">
                <div className="tr-form-group tr-col-sm-4">
                    <br />
                    <AutoCompleteLabel
                        id="grupo"
                        options={grupos.map(({ nome }) => nome
                        )}
                        value={filtro.grupo}
                        onChangeText={e => setFiltro(prevState => ({
                            ...prevState,
                            grupo: e
                        }))}
                        label={i18n.t("formulario.grupo")}
                        textFieldId="labelSelectCurso"
                    // labelavel
                    />
                    
                </div>
                <div className="tr-form-group tr-col-sm-2">
                    <br />
                    <AutoCompleteLabel
                        id="modulos"
                        options={options}
                        value={filtro.modulo}
                        onChangeText={e => setFiltro(prevState => ({
                            ...prevState,
                            modulo: e
                        }))}
                        label="Exibir Módulos"
                        textFieldId="labelSelectCurso"
                    // labelavel
                    />
                </div>
                <div className="tr-form-group tr-col-sm-2">
                    <StyledBoxButton>
                        <ButtonIcon
                            value={i18n.t("button.gerar")}
                            icon="mdi mdi-text-search icon-mr-12"
                            onClick={() => listarHistorico()}
                        />
                    </StyledBoxButton>
                </div>

            </div>
            <Divider />

            <DialogExport />
        </div>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 25,
    },
    areaTexto: {
        paddingVertical: 2
    },
    texto: {
        fontSize: 12,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
        fontWeight: 'bold'
    },
    areaTable: {
        paddingTop: 15,
        flexDirection: 'column',
    },
    linhaCurso: {
        backgroundColor: "#D7E3F2",
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 20,
        display: 'table'
    },
    linhaAluno: {
        backgroundColor: '#2d71cc',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 20,
        display: 'table',
    },
    linhaModulo: {
        backgroundColor: '#EBF0F6',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 20
    },

    textoHeader: {
        fontSize: 10,
        color: '#fff',
        fontFamily: 'Nunito',
        fontWeight: 'bold',

    },
    textoModulo: {
        fontSize: 10,
        color: '#4D4D4D',
        fontFamily: 'Nunito',
    },
    coluna: {
        width: 85
    }


})