export const MODULO_ARQUIVO = "/modulo/arquivo";

export const CERTIFICADO = "/certificado/layout";

export const INSTRUTOR = "/instrutor";

export const NOTAS = "/nota"

export const CONCLUIDOS_MOD = `/concluidos/modulo`;

export const INSCRITOS_MOD = `/inscricao/modulo`;

export const INSCRITOS_CURSO = "/inscricao/curso"

export const INSCRITOS = "/modulos/inscritos/curso"

export const LISTA_CURSO_MODULO = "/modulos/curso"

export const USUARIO_LISTA_SIMPLES = "/usuarios/listar";

export const HISTORICO_NOTAS = "/nota/modulos"

export const LISTAR_REQUISITO = "/requisito/listar"

export const REQUISITO = "/requisito"

export const VIDEOS = "/videos"

export const LISTAR_VIDEOS_MOD = "/videos/modulo"

export const MODULOS = "/modulos"

export const CURSOS = "/cursos"

export const CURSOS_AUTOCOMPLETE = "/cursos/autocomplete"

export const CURSOS_STATUS = "/cursos/status"

export const PROVA = "/prova"

export const PROVA_PERGUNTA = "/provapergunta"

export const PROVA_USUARIO = "/provausuario"

export const RESPOSTA_USUARIO = "/respostausuario"

export const USUARIO_CURSO = "/usuario/curso"

export const GRUPO = "/grupo"

export const  DASHBOARD= "/dashboard";

export const EMPRESA_LOGOS = "/empresa/logos"