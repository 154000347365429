import styled from "styled-components";

export const AreaLogo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  span {
    color: #606060;
    font-size: 16px;
  }
`;

export const AreaBox = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;

  h1 {
    font-size: 32px;
    color: 333333;
    text-align: center;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const Box = styled.div`
  background-color: #ffff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  width: 100%;
  padding: 40px;
`;

export const TituloBox = styled.span`
  color: #333333;
  font-size: 20px;
  text-align: center;
  display: block;
  margin-bottom: 20px;
`;

export const SmallText = styled.span`
  color: ${(props) => props.cor};
  font-size: 12px;
  margin-top: 3px;
  display: block;
  @media (max-width: 480px) {
    display: none;
  }
`;

export const AreaTexto = styled.div`
  input {
    width: 100%;
  }
`;

export const AreaBotao = styled.div`
  width: 100%;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  display: block;
`;

export const Botao = styled.button`
  margin: 0 auto;
`;

export const BotaoLink = styled.button`
  border: 1px solid #27add9;
  background-color: transparent;
  color: #27add9;
  margin: 0 auto;

  &:hover {
    color: #ffffff;
    background-color: #27add9;
  }
`;

export const Icon = styled.i`
  margin-left: 10px !important;
  text-align: center !important;
`;

export const AreaLink = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const TituloLink = styled.span`
  color: #606060;
  margin-bottom: 40px;
  font-size: 16px;
  text-align: center;
  display: block;
  justify-content: center;
`;
