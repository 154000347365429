import React, { useState, useEffect } from "react";
import AutoCompleteLabel from "../../../components/AutoComplete";
import ConfirmeDialog from "../../../components/ConfirmeDialog";
import deleteMapping from "../../../repositories/deleteMapping";
import { CardMenu } from "../../../components/MenuCardOptions";
import { useHistory, useParams } from "react-router-dom";
import getMapping from "../../../repositories/getMapping";
import ButtonIcon from "../../../components/ButtonIcon";
import PageDefault from "../../../template/DefaultPage";
import Loading from "../../../components/Loading";
import "../../../components/css/styleguide.scss";
import { i18n } from "../../../translate/i18n";
import DialogModulo from "./Dialog/novo";
import DialogReset from "./Dialog/reset";
import { toast } from "react-toastify";

export const moduloInicial = {
  id: "",
  titulo: "",
  descricao: "",
  cursoTitulo: "",
  cursoId: "",
  instrutorNome: "",
  instrutorId: "",
  requisitoModulo: null,
  nomeRequisito: "",
  cor: "",
  avaliacao: false,
  cancelado: false,
  minutos: 0,
};

export default function ModuloLista() {
  async function listarCurso() {
    await getMapping.get("/cursos/autocomplete").then((data) => {
      setCursos(data);
    });
  }
  useEffect(() => {
    listarCurso();
  }, []);

  const handleBusca = () => {
    listar();
  };
  const { tenant } = useParams();
  const [modulos, setModulos] = useState([]);
  const [modulo, setModulo] = useState(moduloInicial);
  const [openDialogModulo, setOpenDialogModulo] = useState(false);
  const [openDialogReset, setOpenDialogReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cursos, setCursos] = useState([]);
  const history = useHistory();
  const [selectCurso, setSelectCurso] = useState();
  const [opcoes, setOpcoes] = useState(null);

  const [dialogConfirm, setDialogConfirm] = useState(false);

  async function listar() {
    const chosenCurso = cursos.find((curso) => {
      return curso.titulo === selectCurso;
    });

    if (!chosenCurso) {
      return toast.warning("Selecione um curso");
    }
    setLoading(true);
    await getMapping
      .get(`/modulos/${chosenCurso.id}`)
      .then((data) => {
        if (data.length < 1) {
          toast.warning(i18n.t("toastWarning.zeroModulo"));
        }
        setModulos(data);
        setLoading(false);
      })
      .catch((e) => toast.error(e.message));
  }

  const handleDelete = () => {
    deleteMapping
      .deletar(`/modulos/${modulo.id}`)
      .then(() => {
        setDialogConfirm(false);
        listar();
        toast.success(i18n.t("toastSuccess.moduloDel"));
      })
      .catch((e) => toast.error(e.message));
  };
  const handleEdita = () => {
    setOpenDialogModulo(true);
  };
  const handleClick = () => {
    setOpenDialogModulo(true);
    setModulo(moduloInicial);
  };

  const TableHead = () => {
    return (
      <thead>
        <tr className="tHead">
          <th className="text-center">{i18n.t("tabela.codigo")}</th>
          <th>{i18n.t("tabela.tituloModulo")}</th>
          <th className="text-center">{i18n.t("tabela.conteudo")}</th>
          <th className="text-center">{i18n.t("tabela.opcoes")}</th>
        </tr>
      </thead>
    );
  };
  const TableBody = () => {
    return (
      <tbody>
        {modulos.map((item, index) => (
          <tr key={index}>
            <td data-title="Código" className="text-center">
              <span key={index}>{item.id}</span>
            </td>
            <td data-title="Título Módulo">
              <span key={index}>{item.titulo}</span>
            </td>
            <td data-title="Conteúdo" className="text-center">
              <i
                className="mdi mdi-television-play icon-size icon-green"
                onClick={() => {
                  history.push(
                    `/${tenant}/${item.cursoTitulo}/${item.id}/conteudo/${item.avaliacao}/${item.titulo}`
                  );
                }}
              ></i>
            </td>
            <td data-title="Ações" className="text-center">
              <div>
                <a style={{ cursor: "pointer" }}>
                  <i
                    style={{ fontSize: "1.8em" }}
                    className="mdi mdi-chevron-down"
                    onClick={() => setOpcoes(index)}
                  />
                </a>
                <CardMenu open={opcoes === index} width="150px">
                  <a
                    onClick={() => {
                      setModulo((prevState) => ({
                        ...prevState,
                        id: item.id,
                        titulo: item.titulo,
                      }));
                      setOpenDialogReset(true);
                    }}
                  >
                    <i
                      title={i18n.t("iconsTitle.resetarMod")}
                      className="mdi mdi-restart icon-size edit-table icon-mr-12"
                      style={{ color: "rgb(165, 153, 0)" }}
                    ></i>
                    Resetar progresso do usuário
                  </a>

                  <a
                    onClick={() => {
                      setModulo({
                        id: item.id,
                        titulo: item.titulo,
                        descricao: item.descricao,
                        cursoTitulo: item.cursoTitulo,
                        cursoId: item.cursoId,
                        instrutorNome: item.instrutorNome,
                        instrutorId: item.instrutorId,
                        requisitoModulo: item.requisito_modulo,
                        nomeRequisito: item.nomeRequisito,
                        cor: item.cor,
                        avaliacao: item.avaliacao,
                        cancelado: item.cancelado,
                        minutos: item.minutos,
                      });
                      handleEdita();
                    }}
                  >
                    <i
                      title={i18n.t("iconsTitle.editarMod")}
                      className="mdi mdi-pencil-outline icon-size edit-table icon-mr-12"
                    ></i>
                    Editar
                  </a>
                  <a
                    onClick={() => {
                      setDialogConfirm(true);
                      setModulo((prevState) => ({
                        ...prevState,
                        id: item.id,
                      }));
                    }}
                  >
                    <i
                      title={i18n.t("iconsTitle.excluirMod")}
                      className="mdi mdi-trash-can-outline icon-size delete-table"
                    ></i>
                    Excluir
                  </a>
                </CardMenu>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };
  return (
    <PageDefault menuLateral>
      <div
        className="largura-padrao"
        onClick={() => {
          if (opcoes !== null) {
            setOpcoes(null);
          }
        }}
      >
        <h5 className="titulo-pages tr-mb-5">
          <span> {i18n.t("menu.cadModulo")}</span>
        </h5>
        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-5">
              <AutoCompleteLabel
                id="id_selectCurso"
                options={cursos.map(({ titulo }) => titulo)}
                value={selectCurso}
                onChangeText={setSelectCurso}
                label={i18n.t("consulta.selecioneCurs")}
                textFieldId="labelSelectCurso"
                // labelavel
              />
            </div>
            <div className="tr-col"></div>
            <div className="tr-form-group tr-col-lg-4 tr-col-md-5 tr-col-sm-4 tr-display-flex tr-flex-end">
              <div className="tr-mr-4">
                <ButtonIcon
                  value={i18n.t("button.listar")}
                  icon="mdi mdi-text-search icon-mr-12"
                  onClick={handleBusca}
                />
              </div>
              <div>
                <ButtonIcon
                  value={i18n.t("button.novo")}
                  icon="mdi mdi-file icon-mr-12"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container">
          {loading && <Loading title="container-loading-login" />}
          <table>
            <TableHead />
            <TableBody />
          </table>
        </div>
        <DialogModulo
          data={modulo}
          onClose={() => setOpenDialogModulo(false)}
          open={openDialogModulo}
          listar={listar}
        />
        <DialogReset
          title="Excluir"
          description="Deseja realmente excluir esse item?"
          data={modulo}
          onClose={() => setOpenDialogReset(false)}
          open={openDialogReset}
          listar={listar}
        />
        <ConfirmeDialog
          title="Excluir"
          description="Deseja realmente excluir esse item?"
          open={dialogConfirm}
          confirm={handleDelete}
          onClose={() => setDialogConfirm(false)}
        />
      </div>
    </PageDefault>
  );
}
