import React from "react";
import { CardIntegracao } from "../../components/CardIntegracao";
import { useHistory, useParams } from "react-router-dom";
import PageDefault from "../../template/DefaultPage";

export default function Integracoes() {
  const { tenant } = useParams();
  const history = useHistory();

  function goHotmartIntegracao() {
    history.push(`/${tenant}/integracao/hotmart`);
  }

  return (
    <PageDefault menuLateral>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <span> Integrações</span>
        </h5>
        <div className="tr-row">
          <CardIntegracao title="Hotmart" action={goHotmartIntegracao} />
        </div>
      </div>
    </PageDefault>
  );
}
