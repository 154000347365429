import { toast } from "react-toastify";
//converter img base64
export const uploadImg = async (e) => {
  const file = e.target.files[0];
  if (file) {

    if (!null && !"" && !file.name.match(/\.(png|jpg|jpeg)$/) || file.size > 300000) {

      toast.error("Aviso: Upload com limite de 300kb!")
      return false;
    } else {
      const base64 = await convertBase64(file);
      return base64;
    }
  }

};

export const uploadBackground = async (e) => {
  const file = e.target.files[0];
  if (file) {

    if (!null && !"" && !file.name.match(/\.(png|jpg|jpeg|svg)$/) || file.size > 300000) {

      toast.error("Aviso: Upload com limite de 300kb!")
      return false;
    } else {
      const base64 = await convertBase64(file);
      return base64;
    }
  }

};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.oneerror = (error) => {
      reject(error);
    };
  });
};
