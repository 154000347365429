import React, { useState } from "react";

import "./tab.scss";

export const Tabs = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = props.children[activeTabIndex];

  const getClassName = (index) => {
    if (index === activeTabIndex) {
      return "tab-select tab-select-active";
    }

    return "tab-select";
  };

  return (
    <div className="tabs-container">
      <div className="tab-index-select">
        {props.children.map((tab, index) => (
          <label
            key={index}
            className={getClassName(index)}
            onClick={() => setActiveTabIndex(index)}
          >
            {tab.props.title}
          </label>
        ))}
      </div>
      <div className="tab-content">{activeTab.props.children}</div>
    </div>
  );
};
