import React, { useState, useEffect } from "react";

import AutoCompleteLabel from "../../../../components/AutoComplete";
import FormField from "../../../../components/FormField";
import { uploadImg } from "../../../../js/uploadImage";
import Dialog from "../../../../components/Dialog";
import Button from "../../../../components/Button";
import { i18n } from "../../../../translate/i18n";
import services from "../../../../services";
import Alert from "@material-ui/lab/Alert";
import { TextSize } from "../../../../js";
import "../../../../components/FormField";
import { toast } from "react-toastify";

let img = "";
let cursoInicial = {
  id: "",
  titulo: "",
  descricao: "",
  cor: "",
  status: "",
  certificadoNome: "",
  certificadoId: "",
  hotmartId: "",
  publicado: false,
  pagamentoURL: "",
  valor: "",
};
export default function DialogCurso({ data, onClose, open, listar }) {
  const [curso, setCurso] = useState(data);
  const [status, setStatus] = useState(
    data ? changeStatus(data.status, false) : changeStatus("GRATUITO", false)
  );
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [certificados, setCertificados] = useState([]);
  const statusItens = [
    i18n.t("formulario.gratuito"),
    i18n.t("formulario.exclusivo"),
  ];

  function changeStatus(e, type) {
    if (type) {
      switch (e) {
        case i18n.t("formulario.exclusivo"):
          return "EXCLUSIVO";
        case i18n.t("formulario.gratuito"):
          return "GRATUITO";
        default:
          return "GRATUITO";
      }
    } else {
      switch (e) {
        case "EXCLUSIVO":
          return i18n.t("formulario.exclusivo");
        case "GRATUITO":
          return i18n.t("formulario.gratuito");
        default:
          return i18n.t("formulario.gratuito");
      }
    }
  }

  useEffect(() => {
    setCurso(data);
    setStatus(
      data ? changeStatus(data.status, false) : changeStatus("GRATUITO", false)
    );
  }, [open]);

  async function handleSubmit() {
    const chosenCertificado = certificados.find((e) => {
      return e.nome === curso.certificadoNome;
    });

    let statusSave = changeStatus(status, true);
    let certificadoId;

    if (chosenCertificado !== undefined) {
      certificadoId = chosenCertificado.id;
    } else {
      certificadoId = null;
    }

    let errorList = [];
    ///validações
    if (!curso.titulo.trim()) {
      errorList.push("Insira um título válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (!curso.descricao.trim()) {
      errorList.push("Insira uma descrição válida!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    ///editar
    if (errorList.length < 1) {
      if (curso.id !== "") {
        await services
          .put(`/cursos/${curso.id}`, {
            id: curso.id,
            titulo: curso.titulo,
            descricao: curso.descricao,
            cor: curso.cor,
            imagem: img ? img : null,
            status: statusSave,
            certificadoId: certificadoId,
            hotmartId: curso.hotmartId,
            publicado: curso.publicado,
            pagamentoURL: curso.pagamentoURL,
            valor: curso.valor,
          })
          .then(() => {
            toast.success(i18n.t("toastSuccess.cursoAtt"));
            img = "";
            setIserror(false);
            listar();
            onClose();
            setCurso(cursoInicial);
          })
          .catch((e) => toast.error(e.message));
      } else {
        // salvar
        await services
          .post("/cursos", {
            titulo: curso.titulo,
            descricao: curso.descricao,
            cor: curso.cor,
            imagem: img,
            status: statusSave,
            certificadoId: certificadoId,
            hotmartId: curso.hotmartId,
            publicado: curso.publicado,
            pagamentoURL: curso.pagamentoURL,
            valor: curso.valor,
          })
          .then(() => {
            toast.success(i18n.t("toastSuccess.cursoCad"));
            setCurso(cursoInicial);
            img = "";
            setIserror(false);
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  useEffect(() => {
    listarLayouts();
  }, []);

  async function listarLayouts() {
    await services
      .get("/certificadolayout/lista")
      .then((data) => {
        setCertificados(data);
      })
      .catch((e) => toast.error(e.message));
  }

  async function upload(e) {
    await uploadImg(e)
      .then((data) => {
        img = data;
      })
      .catch((error) => console.log(error));
  }
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      listar={listar}
      title={i18n.t("menu.cadCurso")}
    >
      <div className="form-cadastros">
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="form-body">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-8">
              <label>{i18n.t("formulario.titulo")}</label>
              <input
                type="text"
                name="titulo"
                maxLength="100"
                value={curso.titulo}
                onChange={(e) =>
                  setCurso((prevState) => ({
                    ...prevState,
                    titulo: e.target.value,
                  }))
                }
              />
              <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                {TextSize(curso.titulo)}/100
              </p>
            </div>
            <div className="tr-form-group tr-col-sm-4 ">
              <label>
                Publicar na plataforma{" "}
                <i className="mdi mdi-help-circle icon-information">
                  <span className="tooltiptext">
                    Habilitado o curso sera exibido na plataforma 
                  </span>
                </i>
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  name="site_name"
                  defaultChecked={curso.publicado}
                  onChange={(e) =>
                    setCurso((prevState) => ({
                      ...prevState,
                      publicado: e.target.checked,
                    }))
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-12">
              <FormField
                label={i18n.t("formulario.descricao")}
                type="textarea"
                name="descricao"
                maxLength="500"
                value={curso.descricao}
                onChange={(e) =>
                  setCurso((prevState) => ({
                    ...prevState,
                    descricao: e.target.value,
                  }))
                }
              />
              <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                {TextSize(curso.descricao)}/500
              </p>
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group file-content tr-col-sm-6">
              <FormField
                id="image"
                label={i18n.t("formulario.icone") + "(.jpeg / .png / .jpg)"}
                accept=".jpeg, .png, .jpg"
                type="file"
                name="imagem"
                value={curso.imagem}
                onChange={(e) => {
                  setCurso((prevState) => ({
                    ...prevState,
                    imagem: e.target.value,
                  }));
                  upload(e);
                }}
              />
              <div className="label-files pointer-cursor">
                <label className="primary-button-file" htmlFor="id_imagem">
                  {i18n.t("formulario.selecionarImg")}
                </label>
                <label>
                  {curso.imagem ? curso.imagem : i18n.t("formulario.zeroImg")}
                </label>
              </div>
              <p style={{ fontSize: 11, textAlign: "start", padding: 3 }}>
                {i18n.t("formulario.tamanhoIconeCurso")}
              </p>
            </div>
            <div className="tr-form-group tr-col-sm tr-col-sm-6">
              <label>
                {i18n.t("formulario.cor")}{" "}
                <i className="mdi mdi-help-circle icon-information">
                  <span className="tooltiptext">
                    Cor predominante de background na tela de informações do
                    curso
                  </span>
                </i>
              </label>
              <input
                name="cor"
                type="color"
                value={curso.cor}
                onChange={(e) =>
                  setCurso((prevState) => ({
                    ...prevState,
                    cor: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="tr-row">
            <div className="tr-form-group tr-col-sm tr-col-sm-6">
              <AutoCompleteLabel
                id="certificado"
                options={certificados.map(({ nome }) => nome)}
                value={curso.certificadoNome}
                onChangeText={(e) =>
                  setCurso((prevState) => ({
                    ...prevState,
                    certificadoNome: e,
                  }))
                }
                label={i18n.t("formulario.layoutCertificado")}
                textFieldId="labelCertificado"
                labelavel
              />
            </div>

            <div className="tr-form-group tr-col-sm tr-col-sm-6">
              <label>
                Hotmart ID do curso{" "}
                <i className="mdi mdi-help-circle icon-information">
                  <span className="tooltiptext">
                    ID do curso cadastrado na hotmart
                  </span>
                </i>
              </label>
              <input
                type="text"
                name="hotmartId"
                maxLength="60"
                value={curso.hotmartId}
                onChange={(e) =>
                  setCurso((prevState) => ({
                    ...prevState,
                    hotmartId: e.target.value,
                  }))
                }
              />
              <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
                {TextSize(curso.hotmartId)}/60
              </p>
            </div>
          </div>
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm tr-col-sm-4">
              <AutoCompleteLabel
                style={{ marginBottom: 17 }}
                id="autocompleteStatus"
                options={statusItens}
                value={status}
                onChangeText={setStatus}
                label={i18n.t("certificado.tipo")}
                textFieldId="labelStatus"
                clearable
                labelavel
              />
            </div>
            {status === "EXCLUSIVO" && (
              <>
                <div className="tr-form-group tr-col-sm tr-col-sm-4">
                  <label>Valor do curso</label>
                  <input
                    type="number"
                    name="valor"
                    value={curso.valor}
                    onChange={(e) =>
                      setCurso((prevState) => ({
                        ...prevState,
                        valor: Number(e.target.value),
                      }))
                    }
                  />
                </div>
                <div className="tr-form-group tr-col-sm tr-col-sm-4">
                  <label>
                    URL de pagamento
                    <i className="mdi mdi-help-circle icon-information">
                      <span className="tooltiptext">
                        URL direcionada para compra do curso
                      </span>
                    </i>
                  </label>
                  <input
                    type="text"
                    name="pagamentoURL"
                    maxLength="60"
                    value={curso.pagamentoURL}
                    onChange={(e) =>
                      setCurso((prevState) => ({
                        ...prevState,
                        pagamentoURL: e.target.value,
                      }))
                    }
                  />
                </div>
              </>
            )}
          </div>
          <Button onClick={handleSubmit}>{i18n.t("button.salvar")}</Button>
        </div>
      </div>
    </Dialog>
  );
}
