import URL_BACKEND from "../config/back-end";

function upload(requestOptions, api) {
    return new Promise((resolver, reject) => {
        fetch((`${URL_BACKEND}${api}`), requestOptions
        )
            .then(async(response) => {
                if (response.ok) {
                    resolver(response.json());
                } else {
                    const res = await response.json();
                    reject(new Error(res.codigo + " : " + res.mensagem));
                }
            }).catch((err) => reject(err));
    });
}
export default {  upload };