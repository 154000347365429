import React from "react";
import { StyledButton } from "./styles.js";

function ButtonIcon({ value, icon, onClick, type }) {
  return (
    <StyledButton onClick={onClick} type={type}>
      <i className={icon} />
      {value}
    </StyledButton>
  );
}

export default ButtonIcon;
