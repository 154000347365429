import React from "react";
import DefaultDash from "../../template/DefaultDash";
import MainWrapper from "../../components/MainWrapper";

function Home() {
  return (
    <DefaultDash>
      <MainWrapper />
    </DefaultDash>
  );
}

export default Home;
