import React, { useState } from "react";
import PageDefault from "../../template/DefaultPage";
import "../../components/css/styleguide.scss";
import HistoricoAlunoModulo from "../../components/Relatorio/HistoricoAlunoModulo";
import { i18n } from "../../translate/i18n";
import ProvaDoAluno from "../../components/Relatorio/ProvaDoAluno";
import ModuloInscritos from "../../components/Relatorio/ModuloInscritos";
import ResultadoProvaModulo from "../../components/Relatorio/ResultadoProvaModulo";
import HistoricoGeralAluno from "../../components/Relatorio/HistoricoGeralAluno";
import HistoricoGeralGrupo from "../../components/Relatorio/HistoricoGeralGrupo";
import AutoCompleteLabel from "../../components/AutoComplete";

export default function CursoLista() {
  const [filtro, setFiltro] = useState(i18n.t("formulario.selecione"));

  const options = [
    i18n.t("formulario.selecione"),
    i18n.t("relatorio.relatorio1"),
    i18n.t("relatorio.relatorio2"),
    i18n.t("relatorio.relatorio3"),
    i18n.t("relatorio.relatorio4"),
    i18n.t("relatorio.relatorio5"),
    i18n.t("relatorio.relatorio6"),
  ];

  return (
    <PageDefault menuLateral>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <span> Relatórios</span>
        </h5>
        <div className="titulo-table">
          <div className="tr-row">
            <div className="tr-form-group tr-col-sm-4">
              <AutoCompleteLabel
                id="relatorio"
                options={options}
                value={filtro}
                onChangeText={setFiltro}
                label={i18n.t("consulta.relatorio")}
                textFieldId="labelFiltro"
                clearable
              />
            </div>
          </div>
        </div>
        <div>
          {filtro === i18n.t("relatorio.relatorio1") && <ModuloInscritos />}
          {filtro === i18n.t("relatorio.relatorio2") && (
            <ResultadoProvaModulo />
          )}
          {filtro === i18n.t("relatorio.relatorio3") && <ProvaDoAluno />}
          {filtro === i18n.t("relatorio.relatorio4") && (
            <HistoricoAlunoModulo />
          )}
          {filtro === i18n.t("relatorio.relatorio5") && <HistoricoGeralAluno />}
          {filtro === i18n.t("relatorio.relatorio6") && <HistoricoGeralGrupo />}
        </div>
      </div>
    </PageDefault>
  );
}
