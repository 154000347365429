import React from "react";
import IMAGE from "../../components/img/hotmart.png";
import "./index.scss";

export function CardIntegracao({ title, action }) {
  return (
    <div className="containerCard" onClick={action}>
      <img src={IMAGE} width={250} height={150} />
      <div className="containerTitle">
        <span className="title">{title}</span>
        <span>API | WebHook</span>
      </div>
    </div>
  );
}
