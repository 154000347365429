import React from "react";
import { StyledBoxSearch } from "./styles.js";

function InputSearch({
  name,
  value,
  keyPress,
  placeholder,
  onChange,
  icon,
  onClick,
}) {
  return (
    <StyledBoxSearch>
      <input
        type="text"
        name={name}
        value={value}
        onKeyPress={keyPress}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div onClick={onClick}>
        <i style={{ fontSize: 32, cursor: "pointer" }} className={icon} />
      </div>
    </StyledBoxSearch>
  );
}

export default InputSearch;
