import React from 'react';

export default function AnimacaoLoading () {
    return (
        <div>
            <svg
                alt="loading"
                title="loading"
                className="loading"
                xmlns="http://www.w3.org/2000/svg"
                width="200"
                height="200"
                viewBox="0 0 100 100"
                style={{ background: "transparent", margin: "auto" }}
            >
                <circle
                    cx="50"
                    cy="50"
                    r="32"
                    style={{
                        fill: "none",
                        strokeDasharray: "50",
                        strokeWidth: "8",
                        stroke: "#4385d2",
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                    />
                </circle>
                <circle
                    cx="50"
                    cy="50"
                    r="23"
                    style={{
                        fill: "none",
                        strokeDasharray: 40,
                        strokeDashoffset: 40,
                        strokeWidth: 8,
                        stroke: "#fbf400",
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;-360 50 50"
                    />
                </circle>
            </svg>
          
        </div>
    )
}