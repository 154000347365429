import React from "react";

import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";

import "./index.scss";

export function DetailsContentPdf({ verse, url }) {
  return (
    <View style={styles.detailsContentContainer} wrap={false}>
      <View>
        {verse.verseType === "Simples" ? (
          <Text style={verse.verseTitleStyle}>{verse.verseTitle}</Text>
        ) : (
          <View style={styles.titleImg}>
            <Text
              style={[verse.verseTitleStyle, { textAlign: "left !important" }]}
            >
              {verse.verseTitle}
            </Text>
            {verse.verseImg ? (
              <Image src={verse.verseImg} style={styles.img} />
            ) : (
              <View style={styles.img} />
            )}
          </View>
        )}
        <Text style={[styles.text, verse.verseDetailsStyle]}>
          {verse.verseDetails}
        </Text>
      </View>
      <Text style={[styles.url, { marginBottom: 10 }]}>{url}</Text>
    </View>
  );
}

export function DetailsContent({ verse }) {
  return (
    <div className="detailsContentContainer">
      {verse.verseType === "Simples" ? (
        <p style={verse.verseTitleStyle}>{verse.verseTitle}</p>
      ) : (
        <div className="titleImg">
          <p style={verse.verseTitleStyle}>{verse.verseTitle}</p>
          <img src={verse.verseImg} alt="rubrica" />
        </div>
      )}
      <p style={verse.verseDetailsStyle} className="url">
        {verse.verseDetails}
      </p>
    </div>
  );
}

const styles = StyleSheet.create({
  detailsContentContainer: {
    width: "100%",
    justifyContent: "space-between",
    height: "100%",
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  text: {
    marginTop: 10,
    lineHeight: 0,
  },
  url: {
    fontSize: 10,
    textAlign: "right",
  },
  titleImg: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    width: "auto",
    height: 60,
  },
});
