import React from "react";
import "./index.scss";

export function CardMenu({ open, children, direction, width }) {
  return (
    <div
      className="card-menu-container"
      style={{
        width: width,
        display: open ? "flex" : "none",
        right: direction === "right" ? 0 : null,
        left: direction === "left" ? 0 : null,
      }}
    >
      {children}
    </div>
  );
}
