import styled from 'styled-components';

export const FormFieldWrapper = styled.div``;

export const Div = styled.div``;

export const LabelText = styled.label``;

export const Input = styled.input``;

