import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { i18n } from "../../../../translate/i18n";
import postMapping from "../../../../repositories/postMapping";
import putMapping from "../../../../repositories/putMapping";
import Dialog from "../../../../components/Dialog";
import { TextSize } from "../../../../js";

let instrutorInitial = {
  id: "",
  nome: "",
};
export default function Instrutor({ data, onClose, open, listar }) {
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [instrutor, setInstrutor] = useState(data || instrutorInitial);

  useEffect(() => {
    setInstrutor(data || instrutorInitial);
  }, [open]);

  async function handleSubmit() {
    let errorList = [];

    //verificações

    if (instrutor.nome.length < 1) {
      errorList.push("Insira um nome válido!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (errorList.length < 1) {
      if (instrutor.id !== "") {
        await putMapping
          .put(
            {
              id: instrutor.id,
              nome: instrutor.nome,
            },
            `/instrutor/${instrutor.id}`
          )
          .then(() => {
            onClose();
            setInstrutor(instrutorInitial);
            toast.success(i18n.t("toastSuccess.instrutorAtt"));
            setIserror(false);
            listar();
          })
          .catch((e) => toast.error(e.message));
      }

      // cadastrar

      if (instrutor.id === "") {
        await postMapping
          .post(
            {
              nome: instrutor.nome,
            },
            "/instrutor"
          )
          .then(() => {
            setInstrutor(instrutorInitial);
            toast.success(i18n.t("toastSuccess.instrutorCad"));
            setIserror(false);
            onClose();
          })
          .catch((e) => toast.error(e.message));
      }
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      listar={listar}
      title={i18n.t("menu.cadInstrutor")}
    >
      <div className="form-cadastros">
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-12">
            <label>{i18n.t("formulario.nome")}</label>
            <input
              type="text"
              name="nome"
              maxLength="30"
              value={instrutor.nome}
              onChange={(e) =>
                setInstrutor((prevState) => ({
                  ...prevState,
                  nome: e.target.value,
                }))
              }
            />
            <p style={{ fontSize: 10, textAlign: "end", padding: 3 }}>
              {TextSize(instrutor.nome)}/30
            </p>
          </div>
        </div>
        <Button onClick={handleSubmit} type="submit">
          {i18n.t("button.salvar")}
        </Button>
      </div>
    </Dialog>
  );
}
