import URL_BACKEND from "../config/back-end";

function put(object, api) {
  return new Promise((resolver, reject) => {
    fetch(`${URL_BACKEND}${api}`, {
      method: "PUT",

      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ` + sessionStorage.getItem("app-token"),
      },
      body: JSON.stringify(object),
    })
      .then(async (response) => {
        const res = await response.json();
        if (response.ok) {
          resolver(true);
        } else if (response.status === 403) {
          sessionStorage.clear();
          window.location.reload();
        } else {
          reject(new Error(res.codigo + " : " + res.mensagem));
        }
      })
      .catch((err) => reject(err));
  });
}

export default { put };
