import React, { useContext } from "react";

import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./contexts/auth.context";

export const PrivateRoute = (props) => {
  const tenant = props.computedMatch.params.tenant;

  const isLogged = !!sessionStorage.getItem("app-token");
  return isLogged ? <Route {...props} /> : <Redirect to={`/${tenant}/login`} />;
};

export const Authorithy = (props) => {
  const tenant = props.computedMatch.params.tenant;
  const { checkPermission } = useContext(AuthContext);
  return checkPermission() ? (
    <Route {...props} />
  ) : (
    <Redirect to={`/${tenant}/login`} />
  );
};
