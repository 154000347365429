import styled from 'styled-components'

export const StyledButton = styled.button`
  background-color: #004473;
  font-family: "Nunito";
  transition: all 0.35s;
  border-radius: 4px;
  padding: 12px 32px;
  color: #FFF;
  font-size: 1.6em;
  cursor: pointer;
  display: block;
  border: none;

  &:hover {
    background-color: #1566a0;
  }

`