import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function TelaNota(props) {

  const handleClose = () => {
    props.onClose();
    window.location.reload();
  };

  const Msg = () => {
    if (props.resultado.aprovado === true) {
      return <h2 key='0'>Parabéns, você foi aprovado!</h2>;
    } else {
      return <h2 key='1'>Tente novamente!</h2>;
    }
  };

  return (

    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resultado</DialogTitle>
        <div className="nota" key="tela">
          <DialogContent>
            <DialogContentText id="alert-dialog-title">
              <Msg />
              Nota : {props.resultado ? parseFloat(props.resultado.nota).toFixed(2) : ""}
            </DialogContentText>
            <DialogContentText>
              Acertos: {props.resultado ? props.resultado.acertos + "/" + props.resultado.perguntas : ""}
            </DialogContentText>
          </DialogContent>
        </div>

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}
