import React, { useState } from "react";
import "../../components/FormField";
import FormField from "../../components/FormField";
import useForm from "../../hooks/useForm";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import DefaultPage from "../../template/DefaultPage";
import { useParams } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import uploadArquivos from "../../repositories/uploadArquivos";
import getMapping from "../../repositories/getMapping";
import { uploadBackground } from "../../js/uploadImage";
import ButtonIcon from "../../components/ButtonIcon";

import { TextSize } from "../../js/index";

let img = "";
export default function ConfigEmpresa() {
  const token = sessionStorage.getItem("app-token");
  let dash = JSON.parse(sessionStorage.getItem("dashboard"));
  const { tenant } = useParams();

  const codigo = 1;
  let initialData = {
    urlVideo: dash.urlVideo,
    texto01: dash.texto01,
    texto02: dash.texto02,
    logo: "",
    texto01Cor: dash.texto01Cor,
    texto02Cor: dash.texto02Cor,
    botaoCor: dash.botaoCor,
    botaoTextoCor: dash.botaoTextoCor,
    background: "",
    botaoMenuCor: dash.botaoMenuCor,
    imgIntoCor: dash.imgIntoCor,
  };
  const { handleChange, values } = useForm(initialData);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [file, setFile] = useState("");

  const uploadImage = (e) => {
    if (e.target.files[0].size <= 2457600) {
      setFile(e.target.files[0]);
    } else {
      toast.error("Aviso: Upload com limite de 300kb!");
    }
  };
  function upload(e) {
    uploadBackground(e)
      .then((data) => {
        img = data;
      })
      .catch((error) => console.log(error));
  }

  function updateDash() {
    var formdata = new FormData();
    if (file.size > 1) {
      formdata.append("file", file, file.name);
    }
    let errorList = [];

    if (values.texto01 === "") {
      errorList.push("Titulo em branco!");
      setErrorMessages(errorList);
      setIserror(true);
    }
    if (values.texto02 === "") {
      errorList.push("Descrição!");
      setErrorMessages(errorList);
      setIserror(true);
    }

    if (errorList.length < 1) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      formdata.append("id", codigo);
      formdata.append("urlVideo", values.urlVideo);
      formdata.append("texto01", values.texto01);
      formdata.append("texto02", values.texto02);
      formdata.append("texto01Cor", values.texto01Cor);
      formdata.append("texto02Cor", values.texto02Cor);
      formdata.append("botaoCor", values.botaoCor);
      formdata.append("botaoTextoCor", values.botaoTextoCor);
      formdata.append("botaoMenuCor", values.botaoMenuCor);
      formdata.append("imgIntoCor", values.imgIntoCor);

      if (img !== "") {
        formdata.append("background", img);
      }

      uploadArquivos
        .upload(requestOptions, `/dashboard/${codigo}/${tenant}`)
        .then(() => {
          recuperaDash();
          img = "";
          setFile("");
          toast.success(i18n.t("toastSuccess.dashboardAtt"));

          setIserror(false);
        })
        .catch((e) => toast.error(e.message));
    }
  }

  function recuperaDash() {
    getMapping.get(`/dashboard/1`).then((data) => {
      sessionStorage.removeItem("dashboard");
      sessionStorage.setItem("dashboard", JSON.stringify(data));
    });
  }

  return (
    <DefaultPage menuLateral>
      <div className="largura-padrao">
        <h5 className="titulo-pages tr-mb-5">
          <span> {i18n.t("menu.configEmpresa")}</span>
        </h5>
      </div>
      <div className="form-cadastros largura-padrao">
        <div>
          {iserror && (
            <Alert severity="error">
              {errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <h2>Layout Dashboard</h2>

        <div className="tr-row">
          <div className="tr-form-group tr-col-sm-6">
            <FormField
              label={i18n.t("formulario.titulo")}
              type="text"
              name="texto01"
              maxLength="80"
              value={values.texto01}
              onChange={handleChange}
            />
            <p style={{ fontSize: 11, textAlign: "end", padding: 3 }}>
              {TextSize(values.texto01)}/80
            </p>
          </div>
          <div className="tr-form-group tr-col-sm-6">
            <FormField
              label={i18n.t("formulario.descricao")}
              type="text"
              name="texto02"
              maxLength="120"
              value={values.texto02}
              onChange={handleChange}
            />
            <p style={{ fontSize: 11, textAlign: "end", padding: 3 }}>
              {TextSize(values.texto02)}/120
            </p>
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corTitulo")}
              type="color"
              name="texto01Cor"
              value={values.texto01Cor}
              onChange={handleChange}
            />
          </div>
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corDescricao")}
              name="texto02Cor"
              type="color"
              value={values.texto02Cor}
              onChange={handleChange}
            />
          </div>
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corBotao")}
              name="botaoCor"
              type="color"
              value={values.botaoCor}
              onChange={handleChange}
            />
          </div>
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corTextoBotao")}
              name="botaoTextoCor"
              type="color"
              value={values.botaoTextoCor}
              onChange={handleChange}
            />
          </div>

          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corBotaoMenu")}
              name="botaoMenuCor"
              type="color"
              value={values.botaoMenuCor}
              onChange={handleChange}
            />
          </div>
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.corIlustracao")}
              name="imgIntoCor"
              type="color"
              value={values.imgIntoCor}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="tr-row">
          <div className="tr-form-group tr-col-sm">
            <FormField
              label={i18n.t("formulario.urlVideo")}
              maxLength="200"
              type="text"
              name="urlVideo"
              value={values.urlVideo}
              onChange={handleChange}
            />
          </div>
          <div className="tr-form-group file-content tr-col-sm-4">
            <FormField
              id="image"
              label="Logo (.jpeg /.png /.jpg)"
              accept=".jpeg, .png, .jpg"
              type="file"
              name="logo"
              value={values.logo}
              onChange={(e) => {
                handleChange(e);
                uploadImage(e);
              }}
            />
            <div className="label-files pointer-cursor">
              <label className="primary-button-file" htmlFor="id_logo">
                {i18n.t("formulario.selecionarImg")}
              </label>
              <label>
                {values.logo ? values.logo : i18n.t("formulario.zeroImg")}
              </label>
            </div>
            <p style={{ fontSize: 11, textAlign: "start", padding: 3 }}>
              {i18n.t("formulario.tamanhoLogo")}
            </p>
          </div>

          <div className="tr-form-group file-content tr-col-sm-4">
            <FormField
              id="background"
              label={"Background (.jpeg/.png/.svg/.jpg)"}
              accept=".jpeg, .png, .jpg, .svg"
              type="file"
              name="background"
              value={values.background}
              onChange={(e) => {
                handleChange(e);
                upload(e);
              }}
            />
            <div className="label-files pointer-cursor">
              <label className="primary-button-file" htmlFor="id_background">
                {i18n.t("formulario.selecionarImg")}
              </label>
              <label>
                {values.background
                  ? values.background
                  : i18n.t("formulario.zeroImg")}
              </label>
            </div>
            <p style={{ fontSize: 11, textAlign: "start", padding: 3 }}>
              {i18n.t("formulario.tamanhoBackground")}
            </p>
          </div>
        </div>
        <ButtonIcon
          value={i18n.t("button.salvar")}
          onClick={() => updateDash()}
        />
      </div>
    </DefaultPage>
  );
}
